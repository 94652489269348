import { FieldProps } from "../../forms";
import { useAppSelector } from "../../app/hooks";
import { selectAllServices, selectServiceById } from "./servicesSlice";
import { AutocompleteField } from "../../components/forms/fields/AutocompleteField";

interface ServiceAutocompleteProps extends FieldProps { }

export function ServiceAutocomplete({
  label,
  name,
  registerOptions,
  helper,
}: ServiceAutocompleteProps) {
  const services = useAppSelector(selectAllServices)
  const suggestions = services.map((service) => ({
    value: service.id,
    text: service.name,
  }))

  return (
    <AutocompleteField
      label={label}
      name={name}
      traitGetter={selectServiceById} //add this to get trait behaviour
      suggestions={suggestions}
      registerOptions={registerOptions}
      helper={helper}
      placeholder="Pick the service for this ticket"
    />
  )
}
