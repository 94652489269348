import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { FocusView } from '../../layout/views/FocusView';
import { DetailHeading } from '../../components/DetailHeading';
import { KnownIcon } from '../../components/widgets/KnownIcon';
import { CustomButton } from '../../components/forms/CustomButton';
import { useForgotPasswordEmailSendMutation } from '../../features/profile/profileApi';
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text, VStack } from '@chakra-ui/react';

type Inputs = {
    email: string
}

export function ForgotPasswordPage() {
    const [forgotPasswordEmailSend, { isSuccess: isMailSent }] = useForgotPasswordEmailSendMutation()

    const defaultValues = { email: '' }
    const { handleSubmit, formState: { errors }, control, watch } = useForm<Inputs>({ defaultValues })

    const onSubmit = async (data: Inputs) => {
        try {
            await forgotPasswordEmailSend(data).unwrap()
        } catch (error) {
            console.log("failed to send email for forgot password reset :", error);
        }
    }

    const validateEmail = (value: string | undefined) => {
        if (value) {
            const isValiEmail = value && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
            if (isValiEmail) {
                return undefined
            } else {
                return "Invalid Email format."
            }
        }
    }
    const emailValue = watch('email');
    return (
        <FocusView>
            <VStack width="full" gap={6} textAlign={"center"}>
                {
                    !isMailSent ?
                        <>
                            <DetailHeading title='Reset Password' />
                            <Text color={"#455360"} fontSize={"14px"} fontWeight={500}>Fear not. We’ll email you instruction to reset your password.</Text>
                            <Box w="full">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <VStack align="start" width="full" gap={6}>
                                        <FormControl isInvalid={!!errors.email}>
                                            <FormLabel>E-mail</FormLabel>
                                            <Controller
                                                control={control}
                                                name="email"
                                                rules={{ required: 'This field is required', validate: validateEmail }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type="text"
                                                        variant="filled"
                                                        placeholder="Type your email"
                                                        onChange={(e) => field.onChange(e.target.value.trim().length > 0 ? e.target.value : '')} // Trim value on change
                                                    />
                                                )}
                                            />
                                            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                                        </FormControl>

                                        <Flex width="full" flexDirection={{ base: "column", md: "row" }} gap={"20px"} justifyContent={"center"} alignItems={"center"} >
                                            <CustomButton title='Reset password' type='submit' w={{ base: "full", md: "240px" }} />
                                            <Box>
                                                <Link to="/auth/login">
                                                    Return to login
                                                </Link>
                                            </Box>
                                        </Flex>
                                    </VStack>
                                </form>
                            </Box>
                        </> :
                        <>
                            <KnownIcon name='mailSent' />
                            <DetailHeading title='Check your email' />
                            <Text color={"#455360"} fontSize={"14px"} fontWeight={600} >We’ve sent instructions on how to reset your password to
                                <Text as={"span"} display={"block"} color={"#3455FF"}>{emailValue}</Text>
                            </Text>

                            <Box>
                                <Link to="/auth/login">
                                    Return to login
                                </Link>
                            </Box>
                        </>
                }
            </VStack>
        </FocusView >
    )
}

