import { formatDateOnly } from '../../app/utils';
import { useLocation, useParams } from 'react-router-dom';
import { useAppSelector, useConfigCheck } from '../../app/hooks';
import { Draft, selectDraft } from '../../features/draft/draftSlice';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { KnownIcon } from '../../components/widgets/KnownIcon';
import { EquipmentTrait } from '../../features/equipments/EquipmentTrait';
import { selectCustomerById } from '../../features/customers/customersSlice';
import { selectTerminalById, selectLocationById } from '../../features/terminals/terminalsSlice';
import { ProductResource, selectAllProducts, selectProductById } from '../../features/products/productsSlice';
import { Box, Button, Divider, Flex, HStack, Image, SimpleGrid, Spacer, Stack, StackDivider, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
// import { selectCompConfig } from '../../features/compConfig/compConfigSlice';
import { useGetCompConfigQuery } from '../../features/compConfig/compConfigApi';

interface PrintFieldProps {
    label?: string
    children: any
}

interface PrintContentProps {
    draft: Draft
    isWithCharge?: boolean
    onLogoLoad?: () => void
}

function PrintField({ label, children }: PrintFieldProps) {
    return (
        <VStack fontSize={"10px"} alignItems={"stretch"} gap={0}>
            <Text fontWeight={400} color={"#666E82"}  >
                {label && `${label}:`}
            </Text>
            <Text color={"#1A202C"} fontWeight={600}>{children || "--"}</Text>
        </VStack>
    )
}

function PrintHeader({ draft, onLogoLoad }: PrintContentProps) {
    const { compConfig } = useConfigCheck()
    const formatedAddress = useMemo(() => {
        if (compConfig) return compConfig?.company_address.replace(/\r\n/g, "<br />")
    }, [compConfig])


    return (
        <HStack className="bgColor" w="full" p={5} gap={[2, 3]} borderRadius={"6px"} justifyContent="space-between" bgColor={"#F8F8FB"} >
            <Image id="logo" src={compConfig?.large_logo} w={"110px"} onLoad={onLogoLoad} />
            <Text
                align="center"
                color={"#3455FF"}
                fontSize={"14px"}
                fontWeight={700}
                textTransform="uppercase"
            >
                {compConfig?.name} <br /> Bill of Lading <br />
                #{draft?.number || "--"}
            </Text>
            <VStack alignItems={'stretch'} color={"#1A202C"} fontSize={"10px"}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: formatedAddress,
                    }}
                />
                <Text fontWeight={700} fontSize={"12px"}>{compConfig?.gst_number}</Text>
            </VStack>
        </HStack>
    )
}

function StampField({ label, children }: PrintFieldProps) {
    return (
        <VStack fontSize={"10px"} alignItems={"stretch"} gap={0}>
            <Text fontWeight={400} color={"#666E82"}  >
                {label && `${label}:`}
            </Text>
            <Text color={"#1A202C"} fontWeight={600} display={'block'} minH={"12px"}>{children}</Text>
        </VStack>
    )
}


function PrintOverview({ draft, isWithCharge }: PrintContentProps) {
    const { basic, locations, pickUp, stampDetails } = draft
    const { userProfile: { role, phone, user } = {} } = useConfigCheck() //get role of current user
    const getTerminalName = (terminal: string | number | undefined) => {
        if (typeof terminal === "number") {
            return useAppSelector(state => selectTerminalById(state, terminal)?.name);
        }
        return terminal;
    };
    const getLocationName = (terminal: string | number | undefined, location: number | string | undefined) => {
        if (typeof terminal === "string" || typeof location === "string") {
            return location;
        }
        return location && useAppSelector(state => selectLocationById(state, terminal || 0, location)?.name);
    };
    const consignorTerminal = getTerminalName(locations?.consignor?.terminal);
    const consignorLocation = getLocationName(locations?.consignor?.terminal, locations?.consignor?.location);
    const consigneeTerminal = getTerminalName(locations?.consignee?.terminal);
    const consigneeLocation = getLocationName(locations?.consignee?.terminal, locations?.consignee?.location);
    const customer = useAppSelector(
        (state) => selectCustomerById(state, basic?.customer || 0)?.name,
    )
    const residue_last_contained = typeof pickUp?.residue_last_contained == "number" ? useAppSelector((state) => selectProductById(state, pickUp?.residue_last_contained)?.name) : pickUp?.residue_last_contained



    return (
        <HStack w="full" justifyContent={"space-between"} align="start" gap={1} fontSize={[13, 14]}  >
            <VStack flex={1} maxW={"60%"} align="start" h="full" spacing={2} gap={2}>
                <SimpleGrid w="full" columns={1}  >
                    {customer || "--"}
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1} >
                    <PrintField label="Driver Name">
                        {phone ? `${user?.first_name}${' '}${user?.last_name}${' '}(${phone})` : `${user?.first_name}${' '}${user?.last_name}`}
                    </PrintField>
                    <PrintField label="Date">
                        {formatDateOnly(basic?.reference_date)}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1} >
                    <PrintField label="Equipment Used">
                        {basic?.equipments.map((equipment, index) => (
                            <React.Fragment key={index}>
                                <EquipmentTrait id={equipment} />
                                {index !== (basic?.equipments?.length ?? 0) - 1 && " / "}
                            </React.Fragment>
                        ))}
                    </PrintField>
                    {/* {
                        role !== "driver" ?
                            <PrintField label="24 hour consignor #">
                                {ticket?.emergency_contact || '--'}
                            </PrintField> :
                            null
                    } */}
                </SimpleGrid>
                <SimpleGrid w="full" columns={3} spacing={1} >
                    <PrintField label="Confirmed Empty">
                        {pickUp?.confirmed_empty ? "Yes" : "No"}
                    </PrintField>
                    <PrintField label="Last Contained">
                        {residue_last_contained}
                    </PrintField>
                    <PrintField label="Sequence">
                        {pickUp?.sequence}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1} >
                    <PrintField label="SW Percent">
                        {pickUp?.sw_percent}
                    </PrintField>
                    <PrintField label="Billing Email Address">
                        {draft?.basic?.billing_email}
                    </PrintField>
                </SimpleGrid>
                <HStack w="90%">
                    <Box w="fit-content" >
                        <Text color={"#666E82"} fontSize={"10px"}>From</Text>
                        {
                            (consignorTerminal || locations?.consignor?.lsd || consignorLocation) ?
                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"flex-start"}>
                                    <KnownIcon name="location" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Box fontSize={"10px"}>
                                        <Text color={"#0E1628"} fontWeight={600}>{consignorTerminal || "--"} </Text>
                                        <Text color={"#666E82"}>{locations?.consignor?.lsd || "--"} </Text>
                                        <Text color={"#666E82"}>{consignorLocation || "--"} </Text>
                                    </Box>
                                </Flex>
                                : "--"
                        }
                    </Box>
                    <Spacer my="auto" h={0} borderBottom={"1px dashed #9F9F9F"} />
                    <Box w="fit-content" >
                        <Text color={"#666E82"} fontSize={"10px"}>To</Text>
                        {
                            (consigneeTerminal || locations?.consignee?.lsd || consigneeLocation) ?
                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"flex-start"}>
                                    <KnownIcon name="location" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Box fontSize={"10px"}>
                                        <Text color={"#0E1628"} fontWeight={600} >{consigneeTerminal || "--"} </Text>
                                        <Text color={"#666E82"} >{locations?.consignee?.lsd || "--"} </Text>
                                        <Text color={"#666E82"} >{consigneeLocation || "--"} </Text>
                                    </Box>
                                </Flex>
                                : "--"
                        }
                    </Box>
                </HStack>
            </VStack>
            {
                (isWithCharge) &&
                <VStack w="280px" h="265px" gap={6} borderWidth={1} borderRadius={"6px"} borderColor="#666E82" p={2} fontWeight={500} alignItems={"start"} justifyContent="space-between">
                    <Text w="full" align="center" fontSize={"10px"} fontWeight={600}>
                        AFE STAMP
                    </Text>
                    <VStack align="start" gap={3}>
                        <StampField label="AEF# / Cost Class"> {stampDetails?.cost_class} </StampField>
                        <Flex gap={3}>
                            <StampField label="Minor"> {stampDetails?.minor_class} </StampField>
                            <StampField label="Major"> {stampDetails?.major_class} </StampField>
                        </Flex>
                        <StampField label="Other"> {stampDetails?.other} </StampField>
                    </VStack>
                    <VStack w="full" flex={1} justifyContent={'flex-end'} gap={0} alignItems={"start"}>
                        {
                            (isWithCharge) && stampDetails &&
                            < Image
                                w="full"
                                h="50px"
                                src={stampDetails?.digital_sign || ""}
                                objectFit="contain"
                            />
                        }
                        <Divider borderColor="#666E82" />
                        <Text fontSize={"10px"} color={"#1A202C"} fontWeight={600}>
                            Signature
                        </Text>
                    </VStack>
                </VStack>
            }
        </HStack >
    )
}

function PrintTankGaugeReadings({ draft }: PrintContentProps) {
    return (
        <>
            <VStack w="full" gap={0} >
                <Text fontWeight={700} color={"#1A202C"} fontSize={"12px"} w={"full"} textAlign={"center"} mb={2}>TANK GUAGE READINGS</Text>
                <Box w="full" border={'1px'} borderRadius='4px' borderColor={"#EDF2F7"}>
                    <Table fontSize={12} >
                        <Thead bgColor={"#F8F8FB"} className="bgColor">
                            <Tr sx={{ th: { color: "#4A5568", textAlign: "center", py: '8px', lineHeight: '14px' } }}>
                                <Th>TANK</Th>
                                <Th>START</Th>
                                <Th>FINISH</Th>
                            </Tr>
                        </Thead>
                        <Tbody>

                            {draft?.pickUp?.tank_gauge_details &&
                                draft?.pickUp?.tank_gauge_details?.length !== 0 ?
                                draft?.pickUp?.tank_gauge_details.map((record, index) => {
                                    return (
                                        <Tr key={index} sx={{ td: { textAlign: 'center', p: 1 }, _last: { td: { borderBottom: 'none' } } }}>
                                            <Td>{++index}</Td>
                                            <Td>{record?.start || '--'}</Td>
                                            <Td>{record?.finish || '--'}</Td>
                                        </Tr>
                                    )
                                })
                                :
                                <Tr>
                                    <Td p={1} colSpan={3} textAlign="center">No tank gauge readings.</Td>
                                </Tr>
                            }
                        </Tbody>
                    </Table>
                </Box>
            </VStack >
        </>
    )
}

function PrintProducts({ draft }: PrintContentProps) {
    const products = useAppSelector(selectAllProducts)
    const productsById = useMemo(() => {
        const productsObj: { [key: number]: ProductResource } = {}
        products.forEach((item) => {
            productsObj[item.id] = item
        })
        return productsObj
    }, [draft?.products])

    return (
        <VStack w="full">
            <Text fontWeight={700} color={"#1A202C"} fontSize={"12px"} w={"full"} textAlign={"center"} mb={2}>SHIPMENT</Text>
            <Box w="full" border={'1px'} borderRadius='4px' borderColor={"#EDF2F7"}>
                <Table fontSize={12}  >
                    <Thead bgColor={"#F8F8FB"} verticalAlign={'top'} className="bgColor">
                        <Tr className="avoidSplit" sx={{ th: { color: "#4A5568", textAlign: 'center', p: 2, lineHeight: '14px' } }} >
                            <Th>Number</Th>
                            <Th w={[0, 300]}>Shipping Name</Th>
                            <Th>Primary Class</Th>
                            <Th>Subsidiary Class</Th>
                            <Th>Packing Group</Th>
                            <Th>Toxic by Inhalation</Th>
                            <Th>Total Quantity</Th>
                            <Th># of Packages</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {draft?.products && draft.products.length > 0 ? (
                            draft.products.map((detail, index) => {
                                const product = typeof detail?.product === "number" ? productsById[Number(detail.product)] : detail?.product;
                                const isProductValid = product && typeof product !== 'string';

                                return (
                                    <Tr key={index} className="avoidSplit" sx={{ td: { textAlign: "center" }, _last: { td: { borderBottom: 'none' } } }}>
                                        <Td p={1} textAlign="center">{isProductValid && product.un_number ? product.un_number : "--"}</Td>
                                        <Td p={1} textAlign="center">{isProductValid && product.name ? product.name : "--"}</Td>
                                        <Td p={1} textAlign="center">{isProductValid && product.primary_class ? product.primary_class : "--"}</Td>
                                        <Td p={1} textAlign="center">{detail?.subsidiary_class || "--"}</Td>
                                        <Td p={1} textAlign="center">{isProductValid && product.packing_group ? product.packing_group : "--"}</Td>
                                        <Td p={1} textAlign="center">{detail?.toxic_by_inhalation ? "Yes" : 'No'}</Td>
                                        <Td p={1} textAlign="center">{detail?.quantity || '--'}</Td>
                                        <Td p={1} textAlign="center">{detail?.packages || '--'}</Td>
                                    </Tr>
                                )
                            })) :
                            (
                                <Tr>
                                    <Td p={1} colSpan={8} textAlign="center">No entiries!!</Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </Box>
        </VStack >
    )
}

function PrintCharges({ draft }: PrintContentProps) {
    return (
        <VStack w="full" textAlign={"center"} gap={0} >
            <Text fontWeight={700} color={"#1A202C"} fontSize={"12px"} w={"full"} textAlign={"center"} mb={2}>BILLING</Text>
            <Box w="full" border={'1px'} borderRadius='4px' borderColor={"#EDF2F7"}>
                <Table fontSize={12}  >
                    <Thead bgColor={"#F8F8FB"} verticalAlign={'top'} className="bgColor">
                        <Tr sx={{ th: { color: "#4A5568", textAlign: "center", py: '8px', lineHeight: '14px' } }}>
                            <Th p={1} w={300}>Description</Th>
                            <Th p={1} >Quantity</Th>
                            <Th p={1} >Unit</Th>
                            <Th p={1} >Rate</Th>
                            <Th p={1} >Amount</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            draft?.charges && draft?.charges.length > 0 ? (
                                draft?.charges?.map((charge, index) => {
                                    return (
                                        <Tr key={index} sx={{ td: { textAlign: "center" }, _last: { td: { borderBottom: 'none' } } }} >
                                            <Td p={1} >{charge.description || '--'}</Td>
                                            <Td p={1} >{charge.quantity || '--'}</Td>
                                            <Td p={1} >{charge.unit || '--'}</Td>
                                            <Td p={1} >{charge.rate || '--'}</Td>
                                            <Td p={1} >{charge.amount || '--'}</Td>
                                        </Tr>
                                    )
                                })
                            ) : (
                                <Tr>
                                    <Td p={1} colSpan={5} textAlign="center">No entiries!!</Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </Box>
        </VStack>
    )
}

function PrintDescription({ draft }: PrintContentProps) {

    const { charges, droppOff, services } = draft || {}

    const totalAmount = useMemo(() =>
        charges?.reduce((total, { amount }) => total + Number(amount), 0)
        , [draft?.charges]);

    const description = useMemo(() => {
        const desc: string[] = [];
        if (droppOff?.work_description) {
            desc.push(droppOff.work_description);
        }
        services?.forEach(({ description }) => {
            if (description) {
                desc.push(description);
            }
        });
        return desc.join('. ');
    }, [draft]);

    return (
        <Box w="full" px={5}>
            <HStack w="full" bgColor={"#F8F8FB"} className="bgColor avoidSplit" h="fit-content" borderRadius={"4px"} p={2} mt={3} justifyContent="space-between" fontSize={12} px={5} >
                <VStack alignItems={"stretch"} p={3} gap={2} maxW={"70%"}>
                    <Text fontWeight={400} color={"#666E82"}>DESCRIPTION OF WORK</Text>
                    <Text whiteSpace="normal" color={"#1A202C"} fontWeight={600} wordBreak="break-word">{description || '--'}</Text>
                </VStack>
                <VStack alignItems={"stretch"} p={3} gap={2} maxW={"70%"}>
                    <Text fontWeight={400} color={"#666E82"}>TOTAL CHARGES : </Text>
                    <Text color={"#1A202C"} fontWeight={600}> {(totalAmount || 0).toFixed(2)}</Text>
                </VStack>
            </HStack>
        </Box>
    )
}

export const PrintContent = forwardRef<HTMLDivElement, PrintContentProps>(
    ({ draft, isWithCharge, onLogoLoad }: PrintContentProps, ref) => {
        return (
            <>
                <VStack w="full" gap={2} padding={0} >
                    <PrintHeader draft={draft} onLogoLoad={onLogoLoad} />
                    <Stack w={"full"} divider={<StackDivider />} gap={"10px"} px={5}>
                        <PrintOverview draft={draft} isWithCharge={isWithCharge} />
                        <PrintTankGaugeReadings draft={draft} />
                        <PrintProducts draft={draft} />
                        {isWithCharge ? <PrintCharges draft={draft} /> : null}
                    </Stack>
                    {isWithCharge ? <PrintDescription draft={draft} /> : null}
                </VStack>


            </>
        )
    },
)

export function PrintDraft() {
    const { id } = useParams()
    const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const { compConfig } = useConfigCheck();  //get company config
    const currentDraft = useAppSelector(selectDraft(draftIndex))  // get current draft
    const withCharge = searchParams.get('withCharge') === "true";

    const [isLogoLoaded, setIsLogoLoaded] = useState(false);
    // Callback to trigger when logo loads
    const handleLogoLoad = () => setIsLogoLoaded(true);

    const hasPrinted = useRef(false);
    useEffect(() => {
        if (currentDraft && !hasPrinted.current && compConfig && isLogoLoaded) {
            const printTimeout = setTimeout(() => {
                document.getElementById("printBtn")?.click();
                hasPrinted.current = true
            }, 500)
            return () => clearTimeout(printTimeout);
        }
    }, [currentDraft, compConfig, isLogoLoaded])
    return (
        <>
            <Button display={"none"} id="printBtn" onClick={() => window.print()} />
            <PrintContent draft={currentDraft} isWithCharge={withCharge} onLogoLoad={handleLogoLoad} />
        </>
    )
}

