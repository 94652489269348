import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { components } from "../../app/api/schema";
import { activitiesApi } from "./activitiesApi";
import { RootState } from "../../app/store"

type ActivityResource = components["schemas"]["Activity"]

const activitysAdapter = createEntityAdapter<ActivityResource>({
    selectId: (activity) => activity.id,
})


const initialState = activitysAdapter.getInitialState({
    status: "idle",
    error: null,
})


const activitySlice = createSlice({
    name: "activities",
    initialState,
    reducers: {
        // Reducer functions will go here if needed
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            activitiesApi.endpoints.listActivities.matchFulfilled,
            (state, action) => {
                activitysAdapter.setAll(state, action.payload)
            },
        )
    }

})

export default activitySlice.reducer

export const {
    selectAll: selectAllActivities,
    selectById: selectActivityById,
} = activitysAdapter.getSelectors<RootState>((state) => state.activities)