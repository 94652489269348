import { useForm, FormProvider } from "react-hook-form"

interface FormProps {
  defaultValues?: any
  onSave(data: any): void
  children: React.ReactNode | React.ReactNode[]
}

export function Form({ defaultValues, onSave, children }: FormProps) {
  const methods = useForm({ defaultValues })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSave)} >{children}</form>
    </FormProvider>
  )
}
