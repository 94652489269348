import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { stringifyDate } from '../app/utils';
import { useConfigCheck } from '../app/hooks';
import { KnownIcon } from './widgets/KnownIcon';
import { StatusBadges } from './widgets/StatusBadges';
import { TicketData } from '../pages/user/TicketsPage';
import { Link as ChakraLink } from '@chakra-ui/react';
import { Box, Card, CardBody, Flex, HStack, Spacer, Stack, StackDivider, Text, VStack } from '@chakra-ui/react';

interface TicketCardProps {
    ticket: TicketData
}

export function TicketCard({ ticket }: TicketCardProps) {
    const { userProfile: { role } = {} } = useConfigCheck()
    const { isOnline } = useConfigCheck()
    return (
        <Fragment >
            <Card w="full" >
                <CardBody p={{ base: 2, lg: 5 }}>
                    <Stack p={2} divider={<StackDivider />} spacing='4' color={"#0E1628"}>
                        <HStack justifyContent={"space-between"}>
                            <Text fontSize={{ base: "12px", sm: "18px" }} fontWeight={600}>#{ticket?.number}</Text>
                            <Flex gap={2} color="#3455FF" fontSize="12px">
                                <ChakraLink as={Link} to={role === 'customer' ?
                                    `/user/stamp/tickets/${ticket?.id}`
                                    : `/user/tickets/${ticket?.id}`}
                                    cursor={!isOnline ? "not-allowed" : "pointer"}
                                    _hover={{ textDecoration: "none" }}
                                    pointerEvents={!isOnline ? "none" : undefined}
                                > View Details
                                </ChakraLink>
                                <KnownIcon name="link" />
                            </Flex>
                        </HStack>
                        <HStack w="full" gap={5} >
                            <Flex
                                alignItems="center"
                                columnGap={4}
                                rowGap={2}
                                wrap="wrap"
                                justifyContent="space-between"
                                flex="1"
                            >
                                <Text
                                    whiteSpace="nowrap"
                                    overflow="hidden"
                                    maxWidth={{ base: "160px", sm: "250px", md: "250px", lg: "350px" }}
                                    fontSize={{ base: "14px", sm: "16px", lg: "18px" }} fontWeight={500}
                                    isTruncated
                                >
                                    {ticket?.customer}
                                </Text>
                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"center"}>
                                    <KnownIcon name="calendar" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Text fontSize={{ base: "12px", lg: "16px" }}  >{stringifyDate(ticket?.reference_date)}</Text>
                                </Flex>
                            </Flex>
                            <StatusBadges status={ticket?.status} />
                        </HStack>
                        {
                            (ticket?.consignor_name || ticket?.consignee_name) &&
                            <HStack color="#0E1628" spacing={4} alignItems={"flex-start"}>
                                <VStack w="fit-content" minW="100px" maxW="35%" alignItems={'stretch'}>
                                    <Text fontSize={{ base: 12, md: 14 }}>From</Text>
                                    <Flex gap={{ base: "6px", lg: 3 }} alignItems="flex-start">
                                        <KnownIcon name="location" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                        <Box w="full" wordBreak="break-word" whiteSpace="normal" overflow="hidden">
                                            <Text
                                                fontWeight={500}
                                                noOfLines={2}
                                                fontSize={[12, 14, 16]}
                                            >
                                                {ticket?.consignor_name || "--"}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </VStack>
                                <Spacer borderBottom="2px dashed #9F9F9F" />
                                <VStack w="fit-content" minW="100px" maxW="35%" alignItems={'stretch'}>
                                    <Text fontSize={{ base: 12, md: 14 }}>To</Text>
                                    <Flex gap={{ base: "6px", lg: 3 }} alignItems="flex-start">
                                        <KnownIcon name="location" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                        <Box w="full" wordBreak="break-word" whiteSpace="normal" overflow="hidden">
                                            <Text
                                                fontWeight={500}
                                                noOfLines={2}
                                                fontSize={[12, 14, 16]}
                                            >
                                                {ticket?.consignee_name || "--"}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </VStack>
                            </HStack>
                        }
                    </Stack>
                </CardBody>
            </Card>
        </Fragment >
    )
}
