import { useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import { useAppSelector } from "../../app/hooks";
import { ErrorBox } from "../../components/ErrorBox";
import { Flex, useMediaQuery } from "@chakra-ui/react";
import { Container } from "../../components/Container";
import { useLocation, useParams } from "react-router-dom";
import { MainPageHeader } from "../../components/MainPageHeader";
import { selectDraft } from "../../features/draft/draftSlice";
import { VolumeTable } from "../../features/tickets/VolumeTable";
import { ChargePageHeader } from "../../components/ChargePageHeader";
import { BreadcrumbItemType } from "../../components/BreadCrumbs";
import { selectTicketById } from "../../features/tickets/ticketsSlice";
import { useRetrieveTicketQuery } from "../../features/tickets/ticketsApi";
import { ChargeDetailWrapper } from "../../components/ChargeDetailWrapper";
import { ChargePanelOverview } from "../../features/charges/ChargePanelOverview";
import { TicketServicesOverview } from "../../features/tickets/TicketServicesOverview";
import { TimeEntriesAggregated } from "../../features/timeEntries/TimeEntriesAggregated";

export function ChargesPage() {
    const { id } = useParams<{ id: string }>();
    const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0
    const [networkError, setNetworkError] = useState<string>("");

    //Check whether the current page is a draft page or not.
    const pathname = useLocation().pathname
    const isdraft = pathname.includes("user/draft");
    //retrive ticket , skip api call for draft page
    const { isLoading, isError, error, isSuccess, data } = useRetrieveTicketQuery(Number(id), { skip: isdraft })
    const dataresource = isdraft ? useAppSelector(selectDraft(draftIndex))
        : useAppSelector((state) =>
            selectTicketById(state, id || 0),
        );

    const breadCrumbItems: BreadcrumbItemType[] = isdraft
        ? [
            { label: 'Home', path: '/user/home', icon: 'home' },
            { label: dataresource?.number ? `#${dataresource?.number}` : 'Create New Ticket', path: `/user/draft/${id}` },
            { label: 'All Charges', isCurrentPage: true },
        ]
        : [
            { label: 'Home', path: '/user/home', icon: 'home' },
            ...(!isError ? [
                { label: 'View All Tickets', path: '/user/tickets' },
                { label: `#${dataresource?.number || ''}`, path: `/user/tickets/${id}` },
                { label: 'All Charges', isCurrentPage: true },
            ] : [])
        ];

    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("Please try again later. The data you are attempting to view is either unavailable or your network connection is unstable");
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else if (isSuccess && data) {
            setNetworkError("");
        }
    }, [isError, isSuccess, data]);

    return (
        <>

            <MainPageHeader isLoading={isLoading} breadCrumbItems={breadCrumbItems} />
            {
                !isLoading ?
                    !isError ?
                        <Container direction="column" >
                            <ChargePageHeader title={!isdraft ? `Charges for #${dataresource?.number}` : 'Add charge to draft'} />
                            <Container direction="column" gap={8}>
                                <ChargeDetailWrapper >
                                    <VolumeTable />
                                    <TimeEntriesAggregated />
                                    <TicketServicesOverview />
                                    <ChargePanelOverview />
                                </ChargeDetailWrapper>
                            </Container>
                        </Container>
                        :
                        <ErrorBox show={isError} error={error} message={networkError} />
                    :
                    <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                        <PuffLoader
                            color="#3D82CE"
                            size={80}
                        />
                    </Flex>
            }
        </>
    )
}
