import { useEffect, useState } from "react";
import { Box, VStack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { FieldArray } from "../../components/forms/FieldArray";
import { EquipmentSelect } from "../equipments/EquipmentSelect";
import { InputStreak } from "../../components/forms/InputStreak";
import { EmailField } from "../../components/forms/fields/EmailField";
import { DateField } from "../../components/forms/fields/DateField";
import { InputField } from "../../components/forms/fields/InputField";
import { PhoneField } from "../../components/forms/fields/PhoneField";
import { CustomerAutocomplete } from "../customers/CustomerAutocomplete";

interface BasicFormProps {
  onRemove?(index: number): void
}

export function BasicForm({ onRemove }: BasicFormProps) {
  const { watch } = useFormContext()
  const equipmentsValues = watch("equipments")
  const [isvalue, setIsValue] = useState<null | number>(null)
  useEffect(() => {
    setIsValue(watch("equipments")?.slice(-1).pop() || null)
  }, [equipmentsValues])

  return (
    <>
      <VStack w="full" gap={4} p={[0, 2, 4]}>
        <InputStreak>
          <DateField label="Date" name="reference_date" />
          <CustomerAutocomplete label="Customer" name="customer" registerOptions={{ required: "Please select customer" }} />
        </InputStreak>
        <InputStreak label="Consignor Contact">
          <InputField label="Name" name="consignor_contact" icon="blueUser" placeholder="Enter Name" />
          <PhoneField label="Phone Number" name="consignor_phone" />
        </InputStreak>
        <InputStreak label="Billing Email Address" >
          <EmailField label="Email" name="billing_email" />
          <PhoneField label="Emergency Contact" name="emergency_contact" />
        </InputStreak>
        <InputStreak label="Equipment" >
          <FieldArray name="equipments" onRemove={onRemove} isValue={isvalue} setIsValue={setIsValue}>
            {(fieldName) => <EquipmentSelect name={fieldName} setIsValue={setIsValue} />}
          </FieldArray>
          <Box></Box>
        </InputStreak>
      </VStack>
    </>
  )
}
