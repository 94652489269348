import { useState } from 'react';
import { ModalForm } from './forms/ModalForm';
import { DetailHeading } from './DetailHeading';
import { OverlayLoader } from './OverlayLoader';
import { CustomButton } from './forms/CustomButton';
import { HStack, useDisclosure } from '@chakra-ui/react';
import { useConfigCheck, useCustomToast } from '../app/hooks';
import { TimeEntryForm } from '../features/timeEntries/TimeEntryForm';
import { useCreateTimeEntryMutation } from '../features/timeEntries/timeEntriesApi';

export function TrackingPageHeader() {
    const { isOnline } = useConfigCheck();
    const { showToast } = useCustomToast();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [createTimeEntry] = useCreateTimeEntryMutation();
    const [formIsSubmitting, setFormIsSubmiting] = useState<boolean>(false);

    const handleSave = async (data: any) => {
        setFormIsSubmiting?.(true)
        try {
            await createTimeEntry(data).unwrap()
            showToast({
                status: 'success',
                description: 'Successful submission of the time entry',
            });
        } catch (error) {
            showToast({
                status: 'error',
                description: 'Something went wrong',
            });
        } finally {
            setFormIsSubmiting?.(false)
        }
    };

    return (
        <>
            {formIsSubmitting && <OverlayLoader />}
            <HStack w={"full"} p={[4, 6, 8]} h="80px" bgColor={"#EBEEFF"} alignItems={"center"} justifyContent={"space-between"} borderRadius={"4px"}>
                <DetailHeading title="Time Tracking" />
                <CustomButton title="Add New Entry" onClick={() => onOpen()} isDisabled={!isOnline} />
            </HStack>

            {/* {model for add time entry} */}
            <ModalForm
                title={`Add New Entry`}
                isOpen={isOpen}
                size={["full", "lg"]}
                onClose={onClose}
                onCancel={onClose}
                onSave={handleSave}
            >
                <TimeEntryForm />
            </ModalForm>
        </>
    )
}
