import { TicketHeaderBox } from "./TicketHeaderBox";
import { BreadCrumbs, BreadcrumbItemType } from "./BreadCrumbs";

type MainPageHeaderProps = {
    isLoading?: boolean
    breadCrumbItems: BreadcrumbItemType[];
};


export function MainPageHeader({ isLoading = false, breadCrumbItems }: MainPageHeaderProps) {
    return (
        !isLoading &&
        <TicketHeaderBox>
            <BreadCrumbs items={breadCrumbItems} />
        </TicketHeaderBox >
    )
}




