import { components } from "../../app/api/schema";
import { DetailTile } from "../../components/DetailTile";
import { PanelWrapper } from "../../components/PanelWrapper";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { HStack, Stack, StackDivider, Text, useMediaQuery, VStack } from "@chakra-ui/react";

type TankGaugeDetailsResource = components["schemas"]["Tank_Gauge_Record"]

interface LoadInfoPanelProps extends WrapperProps {
    dataresource?: TicketResource
}

export function LoadInfoPanel({
    dataresource: ticket
}: LoadInfoPanelProps) {
    const [isBreakPoint] = useMediaQuery("(max-width: 700px)")

    if (!ticket) return null

    // Helper function to render tank gauge details
    const renderTankGaugeDetails = (details: TankGaugeDetailsResource[] | undefined) => {
        return details && details.length > 0 ? details.map((entry, index) => (
            <HStack key={index} alignItems="flex-start" gap={{ base: 2, lg: "60px" }} divider={<StackDivider />}>
                <DetailTile flexBasis={{ base: "50%", md: "25%" }} detail={entry.start} />
                <DetailTile flexBasis={{ base: "50%", md: "25%" }} px={{ base: 2, sm: 5 }} detail={entry.finish} />
            </HStack>
        )) : (
            <HStack alignItems="flex-start" gap={{ base: 3, lg: 5 }} divider={<StackDivider />}>
                <DetailTile flexBasis={{ base: "50%", md: "25%" }} />
                <DetailTile flexBasis={{ base: "50%", md: "25%" }} px={{ base: 2, sm: 5 }} />
            </HStack>
        )
    }
    return (
        <>
            <PanelWrapper title="Load">
                <VStack align="stretch" gap={{ base: 3, lg: 5 }} p={2} divider={<StackDivider />}>
                    <Stack direction={{ base: "column", lg: "row" }} w="full" align="flex-start" gap={{ base: 2, lg: "60px" }} divider={<StackDivider />}>
                        <DetailTile title="Estimated Volume" detail={ticket?.estimated_volume} />
                        <Stack direction={"row"} w="full" gap={{ base: "20px", lg: "60px" }} divider={<StackDivider />}>
                            <DetailTile title="Sequence" detail={ticket?.sequence} />
                            <DetailTile title="SW Percent" detail={ticket?.sw_percent} />
                        </Stack>
                    </Stack>
                    <Stack direction={isBreakPoint ? "column-reverse" : "row"} w={"full"} alignItems="flex-start" gap={isBreakPoint ? 3 : "60px"} divider={<StackDivider />}>
                        <DetailTile flexBasis={"20%"} title="Confirmed Empty" detail={ticket?.confirmed_empty ? "Yes" : "No"} />
                        <DetailTile title="Product last transported" detail={ticket?.residue_last_contained?.name} />
                    </Stack >
                    <HStack w="full">
                        <VStack width="full" alignItems='stretch' gap={2} >
                            <HStack alignItems="flex-start" fontSize="14px" color="#455360" textAlign="left" gap={{ base: 2, lg: "60px" }} divider={<StackDivider />}>
                                <Text flexBasis={{ base: "50%", md: "25%" }}>Tank Gauge Start</Text>
                                <Text px={{ base: 2, sm: 5 }} flexBasis={{ base: "50%", md: "25%" }}>
                                    Tank Gauge Finish
                                </Text>
                            </HStack>
                            <VStack align="stretch">
                                {renderTankGaugeDetails(ticket?.tank_gauge_details)}
                            </VStack>
                        </VStack>
                    </HStack>
                </VStack>
            </PanelWrapper>
        </>
    )
}
