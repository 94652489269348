import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { components } from "../../app/api/schema";
import { RootState } from "../../app/store"
import { compConfigApi } from "./compConfigApi";

interface ConfigResource {
    id: number
    archived: boolean,
    name: string,
    small_logo: string,
    large_logo: string,
    current_company: boolean,
    company_address: string,
    gst_number: string
}
const compConfigAdapter = createEntityAdapter<ConfigResource>({
    selectId: (activity) => activity.id,
})


const initialState = compConfigAdapter.getInitialState({
    status: "idle",
    error: null,
})


const compConfigSlice = createSlice({
    name: "compConfig",
    initialState,
    reducers: {
        // Reducer functions will go here if needed
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            compConfigApi.endpoints.getCompConfig.matchFulfilled,
            (state, action) => {
                compConfigAdapter.setAll(state, action.payload)
            },
        )
    }

})

export default compConfigSlice.reducer

// export const {
//     selectAll: selectCompConfig
// } = compConfigAdapter.getSelectors<RootState>((state) => state.compConfig)