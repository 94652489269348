import { PuffLoader } from "react-spinners";
import { Box, Flex } from "@chakra-ui/react";
import { PanelWrapper } from "../../components/PanelWrapper";
import { useRetriveAttachmentsQuery } from "../aws/awsApi";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { ImageWithSkeleton } from "../../components/forms/fields/ImageWithSkeleton";

interface AttachmensPanelProps extends WrapperProps {
    ticket?: TicketResource
}

export interface Attachment {
    id?: number | null;
    presigned_url: string | null;
    file_key?: string | null;
}

export function AttachmentsDetailsPanel({
    dataresource: ticket
}: AttachmensPanelProps) {
    const ticketNumber = ticket?.number
    const shouldSkipAttachmentsQuery = !ticketNumber
    const { data: attachments, isLoading: isAttachmentLoading } = useRetriveAttachmentsQuery(ticketNumber, { skip: shouldSkipAttachmentsQuery });

    return (
        <PanelWrapper title="Attachmnets">
            <Flex gap={4} p={2} justifyContent="flex-start" wrap="wrap">
                {
                    !isAttachmentLoading ?
                        attachments && attachments?.length > 0 ? attachments?.map((attachment: Attachment) => (
                            <Box position="relative" key={attachment.file_key}>
                                <ImageWithSkeleton
                                    key={attachment.file_key}
                                    attachment={attachment}
                                    showRemoveIcon={false}
                                />
                            </Box>
                        )) : (
                            <Box w="full" fontSize="14px" color="#455360" bgColor={"#fff"} borderRadius={"8px"} >
                                There are no attachments
                            </Box>
                        )
                        :
                        <Flex w="full" justifyContent="center" alignItems="center">
                            <PuffLoader
                                color="#3D82CE"
                                size={80}
                            />
                        </Flex>
                }
            </Flex>
        </PanelWrapper>
    )

}
