import { useEffect, useMemo } from "react";
import { PuffLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { Link, Outlet } from "react-router-dom";
import BoletalLogo from "/images/Boletal_largeLogo.png";
import { discardAllDrafts } from "../../features/draft/draftSlice";
import { useCleanAttachmentMutation } from "../../features/aws/awsApi";
import { KnownIcon } from "../../components/widgets/KnownIcon";
import { useAppDispatch, useAppSelector, useConfigCheck } from "../../app/hooks";
import { clearAllLocalEntries } from "../../features/timeEntries/localEntriesSlice";
import { loadSession, selectIsLoggedIn } from "../../features/session/sessionSlice";
import { Image, Menu, MenuButton, MenuList, MenuItem, Text, Divider, Box, Flex, HStack, VStack, Spacer, Avatar, useBreakpointValue } from "@chakra-ui/react";
export function UserArea() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const { isOnline, compConfig, userProfile: { role, user } = {} } = useConfigCheck();
    const drafts = useAppSelector((state) => state.drafts.drafts);
    const [cleanAttachment] = useCleanAttachmentMutation();
    const isSmallScreen = useBreakpointValue({ base: true, md: false });

    useEffect(() => {
        if (!isLoggedIn) {
            if (!dispatch(loadSession())) {
                navigate("/auth/login")
            }
        }
    }, [isLoggedIn, dispatch, navigate])

    const draftWithAttachments = useMemo(() => {
        return drafts?.reduce<number[]>((acc, item) => {
            if (item?.attachments && item?.attachments?.length > 0 && item.number) {
                acc.push(item.number);
            }
            return acc;
        }, []) || [];
    }, [drafts]);

    const handleLogout = () => {
        if (draftWithAttachments.length > 0) cleanAttachment({ draft_ids: draftWithAttachments })
        dispatch(discardAllDrafts())
        dispatch(clearAllLocalEntries())
        navigate("/auth/logout")
    }
    if (!isLoggedIn) {
        return (
            <Flex w="full" h="full" justifyContent="center" alignItems="center">
                <PuffLoader
                    color="#3D82CE"
                    size={80}
                />
            </Flex>
        )
    }
    return (<>
        <Box w="full">

            <Box w="full" bg={isOnline ? "#F8F8FB" : "#EF9A9A"} >
                <HStack
                    p={2}
                    mx={"auto"}
                    h={"70px"}
                    w="90%"
                    textAlign="right"
                    maxW={"1440px"}
                >
                    <Box>
                        <Link to={role !== "customer" ? "/user/home" : "/user/tickets"}>
                            <Image src={compConfig?.large_logo || BoletalLogo} width={[100, 120, 144]} />
                        </Link>
                    </Box>
                    <Spacer />
                    <Box color="white" fontWeight={700}>{!isOnline && <Text> YOU ARE OFFLINE </Text>}</Box>
                    <Spacer />
                    <Menu>
                        <MenuButton alignItems={"center"}>
                            <HStack spacing={2}>
                                <Avatar icon={<KnownIcon name="lightUser" />} bg="#7C8DB5" fontSize="xs" boxSize={8} />
                                {
                                    !isSmallScreen &&
                                    <Text fontWeight={400}>
                                        {user?.first_name
                                            ? `${user?.first_name} ${user?.last_name}`
                                            : user?.username}
                                    </Text>
                                }
                                <KnownIcon name="arrowDown" />
                            </HStack>
                        </MenuButton>
                        <MenuList minW="180px" boxShadow={" 0px 0px 14px 0px #0000001F"} p={3}>
                            <MenuItem px={0} onClick={() => navigate("/user/profile")}>
                                <HStack alignItems={'center'}>
                                    <KnownIcon name="darkUser" />
                                    <Text>Profile</Text>
                                </HStack>
                            </MenuItem>
                            <Divider mx="auto" />
                            <MenuItem px={0} onClick={handleLogout} color={"#E02020"}>
                                <KnownIcon name="logout" />
                                <Box ml={2}>Sign out</Box>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </HStack>
            </Box>
            <VStack align="start" w="full" minH={"100vh"} bgColor={"#F8F8FB"}>
                <Outlet />
            </VStack>
            <Box bgColor={"#F8F8FB"} color="#455360" w="full" h={"60px"} fontSize={"14px"} textAlign="center" alignContent="center">
                &copy; 2024 DIMEC Inc.
            </Box>
        </Box >
    </>
    )
}
