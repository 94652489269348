import { useMemo } from "react";
import { FieldProps } from "../../forms";
import { useAppSelector } from "../../app/hooks";
import { selectAllCustomers } from "./customersSlice";
import { SearchDropdownField } from "../../components/forms/fields/SearchDropdownField";

interface CustomerAutocompleteProps extends FieldProps { }

export interface SearchSuggestions {
  value?: number | string
  label?: string
}

export function CustomerAutocomplete({
  label,
  name,
  registerOptions,
  helper,
}: CustomerAutocompleteProps) {
  const customers = useAppSelector(selectAllCustomers)
  const suggestions = useMemo(() => {
    const filteredCustomers = customers.filter(customer => customer?.profile !== null);
    return filteredCustomers.map(customer => ({
      value: customer.id,
      label: `${customer?.name}`,
    }));
  }, [customers]);

  return (
    <SearchDropdownField
      label={label}
      name={name}
      suggestions={suggestions}
      registerOptions={registerOptions}
      helper={helper}
    />
  )
}
