import { RegisterOptions, useFormContext } from "react-hook-form";
import { FormControl, FormLabel, FormHelperText, FormErrorMessage, Switch, VStack } from "@chakra-ui/react";

interface BooleanFieldProps {
  label: string
  name: string
  registerOptions?: RegisterOptions
  helper?: string
}

export function BooleanField({
  label,
  name,
  registerOptions,
  helper,
}: BooleanFieldProps) {
  const { register } = useFormContext()

  return (
    <FormControl>
      <VStack w="full" gap="10px" alignItems="flex-start">
        <FormLabel htmlFor={label} color="#455360" >{label}</FormLabel>
        <Switch id={label} {...register(name, registerOptions)}
          sx={{
            '.chakra-switch__track': {
              bg: 'gray.300', // background when off
            },
            '.chakra-switch__track[data-checked]': {
              bg: '#3455FF', // custom background color when on
            },
          }}
        />
        {helper && <FormHelperText>{helper}</FormHelperText>}
        <FormErrorMessage>You missed this</FormErrorMessage>
      </VStack>
    </FormControl>
  )
}
