import { bolmintApi } from "../../app/api";


export const compConfigApi = bolmintApi.injectEndpoints({
    endpoints: (builder) => ({
        getCompConfig: builder.query<any, void>({
            query: () => ({
                url: `${import.meta.env.VITE_CONFIG_API || ""}`,
                method: "GET",
            }),
        }),
    }),
})

export const {
    useGetCompConfigQuery
} = compConfigApi