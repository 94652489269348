import { VStack } from '@chakra-ui/react'
import { NumberField } from '../../components/forms/fields/NumberField'
import { TextareaField } from '../../components/forms/fields/TextareaField'

export function DropoffPanelForm() {
    return (
        <>
            <VStack w="full" gap={4} p={[0, 2, 4]}>
                <NumberField label="Actual Volume" name="actual_volume" placeholder="Write it here..." />
                <TextareaField label="Description of Work" name="work_description" placeholder="Describe the work in few words" />
            </VStack>
        </>
    )
}
