import { FieldProps } from "../../forms";
import { CustomButton } from "./CustomButton";
import { useFieldArray } from "react-hook-form";
import { KnownIcon } from "../widgets/KnownIcon";
import { Fragment, ReactNode, useEffect, useRef } from "react";
import { Box, FormControl, FormLabel, Grid, GridItem, IconButton, Text } from "@chakra-ui/react";


interface DualFieldArrayProps extends FieldProps {
    children: (fieldName1: string, fieldName2: string) => ReactNode
    onRemove?(index: number): void
}

function FieldGroupArray({ name, label, children, onRemove }: DualFieldArrayProps) {

    const { fields, append, remove } = useFieldArray({ name })
    const appendedFirst = useRef(false)
    useEffect(() => {
        if (fields.length === 0 && !appendedFirst.current) {
            appendedFirst.current = true
            append({})
        }
    }, [append, fields])


    const handleAppend = () => append({});
    const handleRemove = (index: number) => {
        remove(index)
        onRemove && onRemove(index)
    };

    return (
        <Box w="full" >
            <FormControl >
                <Grid width={{ base: "full", md: "80%" }} templateColumns="1fr 1fr auto" rowGap={"10px"} columnGap={{ base: 4, md: 8 }} >
                    <GridItem>
                        <Text color="#455360">Start</Text>
                    </GridItem>
                    <GridItem>
                        <Text color="#455360">Finish</Text>
                    </GridItem>
                    <GridItem>
                        <CustomButton title="Add" variant="outline" w={{ base: "40px", md: "80px" }} size={["sm"]} onClick={handleAppend} />
                    </GridItem>
                    {fields.map((field, index) => {
                        const fieldName1 = `${name}[${index}].start`
                        const fieldName2 = `${name}[${index}].finish`

                        return (
                            <Fragment key={field.id}>
                                <GridItem>{(children(fieldName1, fieldName2) as React.ReactElement<any, string | React.JSXElementConstructor<any>>)?.props?.children?.[0] ?? null}</GridItem>
                                <GridItem>{(children(fieldName1, fieldName2) as React.ReactElement<any, string | React.JSXElementConstructor<any>>)?.props.children[1] ?? null}</GridItem>
                                <GridItem>
                                    {
                                        fields.length > 1 &&
                                        <IconButton
                                            aria-label="Remove"
                                            icon={<KnownIcon name="delete" boxSize="24px" cursor="pointer" />}
                                            onClick={() => handleRemove(index)}
                                            backgroundColor="transparent"
                                            _hover={{ backgroundColor: "transparent" }}
                                        />
                                    }
                                </GridItem>
                            </Fragment>
                        )
                    })}
                </Grid>
            </FormControl >
        </Box>

    )
}
export default FieldGroupArray;
