import { InputStreak } from '../../components/forms/InputStreak';
import { NumberField } from '../../components/forms/fields/NumberField';
import { DigitalSignPadField } from '../../components/forms/fields/DigitalSignPadField';
export function StampForm() {
    return (
        <>
            <InputStreak >
                <NumberField name="cost_class" label="AEF# / Cost Class" placeholder='Add Cost Class' />
                <NumberField name="minor_class" label="Minor Class" placeholder='Add Minor Class' />
            </InputStreak>
            <InputStreak  >
                <NumberField name="major_class" label="Major Class" placeholder='Add Major Class' />
                <NumberField name="other" label="Other" placeholder='Additional Details' />
            </InputStreak>
            <InputStreak label="Signature" >
                <DigitalSignPadField name="digital_sign" />
            </InputStreak>
        </>
    )
}

