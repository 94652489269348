import React, { useState } from "react";
import { FieldProps } from "../../../forms";
import { useFormContext } from "react-hook-form";
import { KnownIcon } from "../../widgets/KnownIcon";
import { TraitSelector, useTrait } from "../../../app/hooks";
import { FormControl, FormLabel, FormHelperText, FormErrorMessage, InputGroup, Input, List, ListItem, Box, Text, InputLeftAddon, VStack, InputRightElement, Button } from "@chakra-ui/react";

export interface AutocompleteSuggestion {
  value: number
  text: string
}

interface AutocompleteFieldProps extends FieldProps {
  suggestions: AutocompleteSuggestion[]
  onMatch?: (selectedSuggestion: AutocompleteSuggestion) => void
  traitGetter?: TraitSelector;
}

export function AutocompleteField({
  label,
  name,
  suggestions,
  helper,
  onMatch,
  registerOptions,
  icon,
  placeholder,
  traitGetter
}: AutocompleteFieldProps) {
  const { formState: { errors }, setValue, trigger, watch } = useFormContext()

  //get antity name using hook
  const result = traitGetter && useTrait(
    (entry) => entry.name,
    watch(name),
    traitGetter)
  const { trait = '', isNew = false } = result || {};

  const [filteredSuggestions, setFilteredSuggestions] = useState<AutocompleteSuggestion[]>([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const handleSelectSuggestion = (suggestion: AutocompleteSuggestion) => {
    // setInputValue(suggestion.text)
    setValue(name, suggestion.value)
    onMatch && onMatch(suggestion)
    setShowSuggestions(false)
    trigger(name)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // setInputValue(value)
    if (value.trim().length > 0) {
      setValue(name, value)
      const filtered = suggestions.filter((suggestion) =>
        suggestion.text.toLowerCase().includes(value.toLowerCase()),
      )
      setFilteredSuggestions(filtered)
      setShowSuggestions(value !== "" && filtered.length > 0)
    } else {
      // Handle empty input value
      setValue(name, undefined)
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  }

  const handleBlur = () => {
    setTimeout(() => {
      if (filteredSuggestions.length === 1) {
        handleSelectSuggestion(filteredSuggestions[0])
      } else {
        setShowSuggestions(false)
      }
    }, 200)
    trigger(name)
  }

  return (
    <FormControl isInvalid={errors[name] !== undefined}  >
      <VStack gap="10px" alignItems="flex-start" position={"relative"}>
        <FormLabel htmlFor={name} color="#455360">{label} {registerOptions?.required && <span style={{ color: 'red' }}>*</span>}</FormLabel>
        <InputGroup >
          {icon &&
            <InputLeftAddon h={"48px"} border="none">
              <KnownIcon name={icon} />
            </InputLeftAddon>
          }
          <Input
            id={name} // Ensure the ID matches the label's htmlFor
            value={trait}
            h={"48px"}
            color={"#0E1628"}
            variant={"filled"}
            borderRadius={"4px"}
            placeholder={placeholder}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {
            isNew &&
            <InputRightElement width='4.5rem' h={"48px"}>
              <Button h='1.75rem' size='xs' color={"#3455FF"} bgColor={"white"} cursor='none'>
                New
              </Button>
            </InputRightElement>
          }
        </InputGroup>
        {showSuggestions && (
          <Box position="absolute" top="95px" width="100%" zIndex="dropdown">
            <List bg="white" boxShadow="lg">
              {filteredSuggestions.map((suggestion, index) =>
                <ListItem
                  key={suggestion.value}
                  cursor="pointer"
                  onClick={() => {
                    handleSelectSuggestion(suggestion)
                  }}
                  px={4}
                  py={2}
                  _hover={{ bg: "gray.100" }}
                >
                  <Text>{suggestion.text}</Text>
                </ListItem>
              )}
            </List>
          </Box>
        )}
        {helper && <FormHelperText>{helper}</FormHelperText>}
        <FormErrorMessage>{errors[name]?.message?.toString()}</FormErrorMessage>
      </VStack>
    </FormControl >
  )
}
