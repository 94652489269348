import { FormControl, FormLabel, FormHelperText, InputGroup, Textarea } from "@chakra-ui/react"

interface ReadonlyTextareaFieldProps {
    label?: string
    helper?: string
    value?: string | number
}

export function ReadonlyTextareaField({ label, helper, value }: ReadonlyTextareaFieldProps) {

    return (
        <FormControl cursor={"not-allowed"}>
            <FormLabel htmlFor={label} color="#455360">{label}</FormLabel>
            <InputGroup>
                <Textarea
                    id={label}
                    defaultValue={value}
                    variant="filled"
                    color={"#0E1628"}
                    cursor={"not-allowed"} pointerEvents="none" tabIndex={-1}
                />
            </InputGroup>
            {helper && <FormHelperText color="gray.400">{helper}</FormHelperText>}
        </FormControl>
    )
}