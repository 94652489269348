import { Image } from '@chakra-ui/react';
import { formatDateOnly } from "../../app/utils";
import { components } from "../../app/api/schema";
import { useConfigCheck } from "../../app/hooks";
import { useParams, useLocation } from "react-router-dom";
import { useRetriveSignatureQuery } from "../../features/aws/awsApi";
import React, { forwardRef, useEffect, useMemo, useRef } from "react";
import { KnownIcon } from "../../components/widgets/KnownIcon";
import { EquipmentTrait } from "../../features/equipments/EquipmentTrait";
import { useRetrieveTicketQuery } from "../../features/tickets/ticketsApi";
import { Attachment } from '../../components/forms/fields/AttachmentDropZone';
import { Box, Button, Divider, Flex, HStack, SimpleGrid, Spacer, Stack, StackDivider, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react";

type TicketResource = components["schemas"]["Ticket"];
type ProductResource = components["schemas"]["Product"];
type ChargeResource = components["schemas"]["Charge"];
type TankGaugeRecord = components["schemas"]["Tank_Gauge_Record"];

interface TicketInfoProps {
    ticket: TicketResource
    isWithCharge?: boolean
    signatureData?: Attachment
}

function PrintHeader({ ticket }: TicketInfoProps) {
    const { compConfig } = useConfigCheck();

    const formatedAddress = useMemo(() => {
        if (compConfig) return compConfig?.company_address.replace(/\r\n/g, "<br />")
    }, [compConfig]);
    return (
        <HStack className="bgColor" w="full" p={5} gap={[2, 3]} borderRadius={"6px"} justifyContent="space-between" bgColor={"#F8F8FB"} >
            <Image src={compConfig?.large_logo} w={"110px"} />
            <Text
                align="center"
                color={"#3455FF"}
                fontSize={"14px"}
                fontWeight={700}
            >
                {compConfig?.name} <br /> Bill of Lading <br />
                #{ticket?.number || "--"}
            </Text>
            <VStack alignItems={'stretch'} color={"#1A202C"} fontSize={"10px"}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: formatedAddress,
                    }}
                />
                <Text fontWeight={700} fontSize={"12px"}>{compConfig?.gst_number}</Text>
            </VStack>
        </HStack>
    )
}

function PrintFooter({ ticket }: TicketInfoProps) {
    return (
        <Box w="full" px={5}>
            <VStack w="full" bgColor={"#F8F8FB"} className="bgColor avoidSplit" borderRadius={"4px"} p={5} fontSize={10} divider={<StackDivider />}>
                <Text color={"#666E82"}>
                    Consignor’s Certification: I hereby declare that the contents of this
                    consignment are fully and accurately described above by the proper
                    shipping name, are properly classified and packaged, have dangerous
                    goods safely marks properly affixed or displayed on them, and are in all
                    respects in proper condition for transport according to the
                    Transportation of Dangerous Goods Regulations.
                </Text>
                <HStack w="full" justifyContent="space-between" p={2} >
                    <VStack alignItems={'stretch'} gap={1}>
                        <Text textTransform="uppercase" color={"#666E82"}>Client Name :</Text>
                        <Text color={"#1A202C"} fontWeight={600}> {ticket?.consignor_contact || '--'}</Text>
                    </VStack>
                    <VStack alignItems={'stretch'} gap={1}>
                        <Text textTransform="uppercase" color={"#666E82"}>Client Approved :</Text>
                        <Text color={"#1A202C"} fontWeight={600}> {(ticket?.status === "stamped" || ticket.digital_signed) ? "Yes" : ""}</Text>
                    </VStack >
                    <VStack alignItems={'stretch'} gap={1}>
                        <Text textTransform="uppercase" color={"#666E82"}>Telephone :</Text>
                        <Text color={"#1A202C"} fontWeight={600}>{ticket?.consignor_phone || '--'}</Text>
                    </VStack >
                </HStack >
            </VStack >
        </Box>
    )
}

interface PrintFieldProps {
    label?: string
    children: any
}

function PrintField({ label, children }: PrintFieldProps) {
    return (
        <VStack fontSize={"10px"} alignItems={"stretch"} gap={0}>
            <Text fontWeight={400} color={"#666E82"}  >
                {label && `${label}:`}
            </Text>
            <Text color={"#1A202C"} fontWeight={600}>{children || "--"}</Text>
        </VStack>
    )
}
function StampField({ label, children }: PrintFieldProps) {
    return (
        <VStack fontSize={"10px"} alignItems={"stretch"} gap={0}>
            <Text fontWeight={400} color={"#666E82"}  >
                {label && `${label}:`}
            </Text>
            <Text color={"#1A202C"} fontWeight={600} display={'block'} minH={"12px"}>{children}</Text>
        </VStack>
    )
}


function PrintOverview({ ticket, isWithCharge, signatureData }: TicketInfoProps) {
    const { userProfile: { role } = {} } = useConfigCheck() //get role of current user
    return (
        <HStack w="full" justifyContent={"space-between"} align="start" gap={1} fontSize={[13, 14]}  >
            <VStack flex={1} maxW={"60%"} align="start" h="full" spacing={2} gap={2}>
                <SimpleGrid w="full" columns={1}  >
                    <Text fontWeight={700} fontSize={16} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" >{ticket?.customer?.name || "--"}</Text>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1} >
                    <PrintField label="Driver Name">
                        {ticket?.driver.phone ? `${ticket?.driver?.first_name}${' '}${ticket?.driver?.last_name}${' '}(${ticket?.driver?.phone})` : `${ticket?.driver?.first_name}${' '}${ticket?.driver?.last_name}`}
                    </PrintField>
                    <PrintField label="Date">
                        {formatDateOnly(ticket?.reference_date)}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1} >
                    <PrintField label="Equipment Used">
                        {ticket?.equipmentdetails?.map((detail, index) => (
                            <React.Fragment key={index}>
                                <EquipmentTrait id={detail?.equipment_id} />
                                {index !== (ticket?.equipmentdetails?.length ?? 0) - 1 && " / "}
                            </React.Fragment>
                        ))}
                    </PrintField>
                    {
                        role !== "driver" ?
                            <PrintField label="24 hour consignor #">
                                {ticket?.emergency_contact}
                            </PrintField> :
                            null
                    }
                </SimpleGrid>
                <SimpleGrid w="full" columns={3} spacing={1} >
                    <PrintField label="Confirmed Empty">
                        {ticket?.confirmed_empty ? "Yes" : "No"}
                    </PrintField>
                    <PrintField label="Last Contained">
                        {ticket?.residue_last_contained?.name}
                    </PrintField>
                    <PrintField label="Sequence">
                        {ticket?.sequence}
                    </PrintField>
                </SimpleGrid>
                <SimpleGrid w="full" columns={2} spacing={1} >
                    <PrintField label="SW Percent">
                        {ticket?.sw_percent}
                    </PrintField>
                    <PrintField label="Billing Email Address">
                        {ticket?.billing_email}
                    </PrintField>
                </SimpleGrid>
                <HStack w="90%">
                    <Box w="fit-content" >
                        <Text color={"#666E82"} fontSize={"10px"}>From</Text>
                        {
                            (ticket?.consignor_terminal?.name || ticket?.consignor_lsd || ticket?.consignor_location?.name) ?
                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"flex-start"}>
                                    <KnownIcon name="location" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Box fontSize={"10px"}>
                                        <Text color={"#0E1628"} fontWeight={600}>{ticket?.consignor_terminal?.name || "--"} </Text>
                                        <Text color={"#666E82"}>{ticket?.consignor_lsd || "--"} </Text>
                                        <Text color={"#666E82"}>{ticket?.consignor_location?.name || "--"} </Text>
                                    </Box>
                                </Flex> : '--'
                        }
                    </Box>
                    <Spacer my="auto" h={0} borderBottom={"1px dashed #9F9F9F"} />
                    <Box w="fit-content" >
                        <Text color={"#666E82"} fontSize={"10px"}>To</Text>
                        {
                            (ticket?.consignee_terminal?.name || ticket?.consignee_lsd || ticket?.consignee_location?.name) ?
                                <Flex gap={{ base: "6px", lg: 3 }} alignItems={"flex-start"}>
                                    <KnownIcon name="location" boxSize={{ base: "16px", md: "20px", lg: "24px" }} />
                                    <Box fontSize={"10px"}>
                                        <Text color={"#0E1628"} fontWeight={600} >{ticket?.consignee_terminal?.name || "--"} </Text>
                                        <Text color={"#666E82"} >{ticket?.consignee_lsd || "--"} </Text>
                                        <Text color={"#666E82"} >{ticket?.consignee_location?.name || "--"} </Text>
                                    </Box>
                                </Flex> :
                                '--'
                        }
                    </Box>
                </HStack>
            </VStack>
            {
                (isWithCharge || role === "customer") &&
                <VStack w="280px" h="265px" gap={6} borderWidth={1} borderRadius={"6px"} borderColor="#666E82" p={2} fontWeight={500} alignItems={"start"} justifyContent="space-between">
                    <Text w="full" align="center" fontSize={"10px"} fontWeight={600}>
                        AFE STAMP
                    </Text>
                    <VStack align="start" gap={3}>
                        <StampField label="AEF# / Cost Class"> {ticket?.cost_class} </StampField>
                        <Flex gap={3}>
                            <StampField label="Minor"> {ticket?.minor_class} </StampField>
                            <StampField label="Major"> {ticket?.major_class} </StampField>
                        </Flex>
                        <StampField label="Other"> {ticket?.other} </StampField>
                    </VStack>
                    <VStack w="full" flex={1} justifyContent={'flex-end'} gap={0} alignItems={"start"}>
                        {
                            (isWithCharge || role === "customer") && signatureData &&
                            < Image
                                w="full"
                                h="50px"
                                src={signatureData?.presigned_url || ""}
                                objectFit="contain"
                            />
                        }
                        <Divider borderColor="#666E82" />
                        <Text fontSize={"10px"} color={"#1A202C"} fontWeight={600}>
                            Signature
                        </Text>
                    </VStack>
                </VStack>
            }
        </HStack >
    )
}

interface TankGaugeDetailsProps {
    records: TankGaugeRecord[]
}
function PrintTankGaugeReadings({ records }: TankGaugeDetailsProps) {

    return (
        <>
            <VStack w="full" gap={0} >
                <Text fontWeight={700} color={"#1A202C"} fontSize={"12px"} w={"full"} textAlign={"center"} mb={2}>TANK GUAGE READINGS</Text>
                <Box w="full" border={'1px'} borderRadius='4px' borderColor={"#EDF2F7"}>
                    <Table fontSize={12} color={"#4A5568"}>
                        <Thead bgColor={"#F8F8FB"} className="bgColor" >
                            <Tr sx={{ th: { color: "#4A5568", textAlign: 'center', lineHeight: '14.52px', py: '8px' } }}>
                                <Th>TANK</Th>
                                <Th>START</Th>
                                <Th>FINISH</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                records && records?.length !== 0 ?
                                    records.map((record: TankGaugeRecord, index: number) => {
                                        return (
                                            <Tr key={index} sx={{ td: { textAlign: 'center', p: 1, color: '#4A5568' }, _last: { td: { borderBottom: 'none' } } }}>
                                                <Td>{++index}</Td>
                                                <Td>{record?.start || '--'}</Td>
                                                <Td>{record?.finish || '--'}</Td>
                                            </Tr>
                                        )
                                    })
                                    :
                                    <Tr>
                                        <Td p={1} colSpan={3} textAlign="center">No tank gauge readings.</Td>
                                    </Tr>
                            }
                        </Tbody>
                    </Table>
                </Box>
            </VStack >
        </>
    )
}

function PrintProducts({ ticket }: TicketInfoProps) {
    const productsById = useMemo(() => {
        const productsObj: { [key: number]: ProductResource } = {}
        ticket?.products.forEach((product) => {
            productsObj[product.id] = product
        })
        return productsObj
    }, [ticket?.products])

    return (
        <VStack w="full">
            <Text fontWeight={700} color={"#1A202C"} fontSize={"12px"} w={"full"} textAlign={"center"} mb={2}>SHIPMENT</Text>
            <Box w="full" border={'1px'} borderRadius='4px' borderColor={"#EDF2F7"}>
                <Table fontSize={12}  >
                    <Thead bgColor={"#F8F8FB"} verticalAlign={'top'} className="bgColor">
                        <Tr className="avoidSplit" sx={{ th: { color: "#4A5568", textAlign: 'center', p: 2, lineHeight: '14px' } }} >
                            <Th>Number</Th>
                            <Th w={[0, 300]}>Shipping Name</Th>
                            <Th>Primary Class</Th>
                            <Th>Subsidiary Class</Th>
                            <Th>Packing Group</Th>
                            <Th>Toxic by Inhalation</Th>
                            <Th>Total Quantity</Th>
                            <Th># of Packages</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            ticket?.productdetails && ticket?.productdetails?.length > 0 ? (
                                ticket?.productdetails?.map((detail, index) => {
                                    const product = productsById[detail.product_id]
                                    return (
                                        <Tr key={index} className="avoidSplit" sx={{ td: { textAlign: "center", color: '#4A5568' }, _last: { td: { borderBottom: 'none' } } }}>
                                            <Td p={1} textAlign="center">{product?.un_number || '--'}</Td>
                                            <Td p={1} textAlign="center">{product?.name || '--'}</Td>
                                            <Td p={1} textAlign="center">{product?.primary_class || '--'}</Td>
                                            <Td p={1} textAlign="center">{detail?.subsidiary_class || '--'}</Td>
                                            <Td p={1} textAlign="center">{product?.packing_group || '--'}</Td>
                                            <Td p={1} textAlign="center">{detail?.toxic_by_inhalation ? "Yes" : 'No'}</Td>
                                            <Td p={1} textAlign="center">{detail?.quantity || '--'}</Td>
                                            <Td p={1} textAlign="center">{detail?.packages || '--'}</Td>
                                        </Tr>
                                    )
                                })) :
                                (
                                    <Tr>
                                        <Td p={1} colSpan={8} textAlign="center">No entiries!!</Td>
                                    </Tr>
                                )
                        }
                    </Tbody>
                </Table>
            </Box>
        </VStack >
    )
}

interface PrintChargesProps {
    charges: ChargeResource[]
}

function PrintCharges({ charges }: PrintChargesProps) {
    return (
        <VStack w="full" textAlign={"center"} gap={0} >
            <Text fontWeight={700} color={"#1A202C"} fontSize={"12px"} w={"full"} textAlign={"center"} mb={2}>BILLING</Text>
            <Box w="full" border={'1px'} borderRadius='4px' borderColor={"#EDF2F7"}>
                <Table fontSize={12}  >
                    <Thead bgColor={"#F8F8FB"} verticalAlign={'top'} className="bgColor">
                        <Tr sx={{ th: { color: "#4A5568", textAlign: "center", py: '8px', lineHeight: '14px' } }}>
                            <Th p={1} w={300}>Description</Th>
                            <Th p={1} >Quantity</Th>
                            <Th p={1} >Unit</Th>
                            <Th p={1} >Rate</Th>
                            <Th p={1} >Amount</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            charges.length > 0 ? (
                                charges?.map((charge, index) => {
                                    return (
                                        <Tr key={index} sx={{ td: { textAlign: "center", color: '#4A5568' }, _last: { td: { borderBottom: 'none' } } }} >
                                            <Td p={1} >{charge.description || '--'}</Td>
                                            <Td p={1} >{charge.quantity || '--'}</Td>
                                            <Td p={1} >{charge.unit || '--'}</Td>
                                            <Td p={1} >{charge.rate || '--'}</Td>
                                            <Td p={1} >{charge.amount || '--'}</Td>
                                        </Tr>
                                    )
                                })
                            ) : (
                                <Tr>
                                    <Td p={1} colSpan={5} textAlign="center">No entiries!!</Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </Box>
        </VStack>
    )
}

interface PrintDescriptionProps {
    ticket: TicketResource,
    charges: ChargeResource[]
}
function PrintDescription({ ticket, charges }: PrintDescriptionProps) {

    const totalAmount = useMemo(() =>
        charges.reduce((total, { amount }) => total + Number(amount), 0)
        , [charges]);

    const description = useMemo(() => {
        const desc: string[] = [];
        if (ticket?.work_description) {
            desc.push(ticket.work_description);
        }
        ticket?.servicedetails?.forEach(({ description }) => {
            if (description) {
                desc.push(description);
            }
        });
        return desc;
    }, [ticket]);

    return (
        <Box w="full" px={5}>
            <HStack w="full" bgColor={"#F8F8FB"} className="bgColor avoidSplit" h="fit-content" borderRadius={"4px"} p={2} mt={3} justifyContent="space-between" fontSize={12} px={5} >
                <VStack alignItems={"stretch"} p={3} gap={2} maxW={"70%"}>
                    <Text fontWeight={400} color={"#666E82"}>DESCRIPTION OF WORK</Text>
                    <Text whiteSpace="normal" color={"#1A202C"} fontWeight={600} wordBreak="break-word">{description.join('. ') || '--'}</Text>
                </VStack>
                <VStack alignItems={"stretch"} p={3} gap={2} maxW={"70%"}>
                    <Text fontWeight={400} color={"#666E82"}>TOTAL CHARGES : </Text>
                    <Text color={"#1A202C"} fontWeight={600}> {(totalAmount).toFixed(2)}</Text>
                </VStack>
            </HStack>
        </Box>

    )
}

interface PrintContentProps {
    ticket: TicketResource
    charges: ChargeResource[]
    isWithCharge?: boolean,
    signatureData?: Attachment
}

export const PrintContent = forwardRef<HTMLDivElement, PrintContentProps>(
    ({ ticket, charges, isWithCharge, signatureData }: PrintContentProps, ref) => {
        return (
            <VStack w="full" gap={2} padding={0} >
                <PrintHeader ticket={ticket} />
                <Stack w={"full"} divider={<StackDivider />} gap={0} px={5}>
                    <PrintOverview ticket={ticket} isWithCharge={isWithCharge} signatureData={signatureData} />
                    <PrintTankGaugeReadings records={ticket?.tank_gauge_details ?? []} />
                    <PrintProducts ticket={ticket} />
                    {isWithCharge ? <PrintCharges charges={charges} /> : null}
                </Stack>
                {isWithCharge ? <PrintDescription ticket={ticket} charges={charges} /> : null}
                {isWithCharge ? <PrintFooter ticket={ticket} /> : null}
            </VStack>
        )
    },
)

export function TicketDetailPrint() {
    const { ticketId } = useParams();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const withCharge = searchParams.get('withCharge') === "true";
    const { data: ticket, isLoading } = useRetrieveTicketQuery(Number(ticketId));
    const { data: signatureData, isLoading: isSignatureDataLoading } = useRetriveSignatureQuery(ticket?.number, { skip: !(ticket?.number && ticket.digital_signed && withCharge) });

    const hasPrinted = useRef(false);
    useEffect(() => {
        if (ticket && !hasPrinted.current && !isSignatureDataLoading) {
            const printTimeout = setTimeout(() => {
                document.getElementById("printBtn")?.click();
                hasPrinted.current = true;
            }, 500);
            return () => clearTimeout(printTimeout);
        }
    }, [ticket, isSignatureDataLoading, signatureData]);

    if (ticket === undefined) return null;

    return (!(isLoading && isSignatureDataLoading) &&
        <>
            <Button display={"none"} id="printBtn" onClick={() => window.print()} />
            <PrintContent ticket={ticket} charges={ticket.chargedetails || []} isWithCharge={withCharge} signatureData={signatureData?.[0]} />
        </>
    )
}
