import { PuffLoader } from "react-spinners";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { components } from "../../app/api/schema";
import { ErrorBox } from "../../components/ErrorBox";
import { Params, useParams } from "react-router-dom";
import { Container } from "../../components/Container";
import { Flex, useMediaQuery } from "@chakra-ui/react";
import { MainPageHeader } from "../../components/MainPageHeader";
import { BreadcrumbItemType } from "../../components/BreadCrumbs";
import { LoadInfoPanel } from "../../features/tickets/LoadInfoPanel";
import { ServicesPanel } from "../../features/tickets/ServicesPanel";
import { LabelInfoPanel } from "../../features/tickets/LabelInfoPanel";
import { selectTicketById } from "../../features/tickets/ticketsSlice";
import { BasicDetailPanel } from "../../features/tickets/BasicDetailPanel";
import { UnloadInfoPanel } from "../../features/tickets/UnloadInfoPanel";
import { ChargeDetailPanel } from "../../features/tickets/ChargeDetailPanel";
import { TicketDetailWrapper } from "../../components/TicketDetailWrapper";
import { useRetrieveTicketQuery } from "../../features/tickets/ticketsApi";
import { TicketDetailPageHeader } from "../../components/TicketDetailPageHeader";
import { LocationsDetailsPanel } from "../../features/tickets/LocationsDetailsPanel";
import { DriverHoursDetailPanel } from "../../features/tickets/DriverHoursDetailPanel";
import { AttachmentsDetailsPanel } from "../../features/tickets/AttachmentsDetailsPanel";

export type TankGaugeDetailsResource = components["schemas"]["Tank_Gauge_Record"];
export type ServiceDetailsResource = components["schemas"]["Service"];
export type ChargeDetailsResource = components["schemas"]["Charge"];
export type TicketResource = components["schemas"]["Ticket"];


export function TicketDetailPage() {
    const { id: ticketId } = useParams<Params>();
    const [networkError, setNetworkError] = useState<string>('');
    const { data, isLoading, isError, error, isSuccess } = useRetrieveTicketQuery(Number(ticketId));
    const ticket = useAppSelector((state) =>
        ticketId ? selectTicketById(state, ticketId) : null);

    useEffect(() => {
        if (error && 'status' in error && error.status === 'FETCH_ERROR') {
            setNetworkError("Please try again later. The data you are attempting to view is either unavailable or your network connection is unstable");
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else if (isSuccess && data) {
            // Clear the error if data fetch is successful
            setNetworkError("");
        }
    }, [isError, isSuccess, data])

    const breadCrumbItems: BreadcrumbItemType[] = [
        { label: 'Home', path: '/user/home', icon: 'home' },
        ...(!isError ? [
            { label: 'View All Tickets', path: '/user/tickets' },
            { label: ticket?.number ? `#${ticket?.number}` : '', isCurrentPage: true },
        ] : []),
    ];

    return (
        <>
            <MainPageHeader isLoading={isLoading} breadCrumbItems={breadCrumbItems} />
            {
                !isLoading ?
                    !isError ?
                        <Container direction="column">
                            {
                                ticket ?
                                    <TicketDetailWrapper>
                                        <TicketDetailPageHeader />
                                        <BasicDetailPanel />
                                        <LocationsDetailsPanel />
                                        <LabelInfoPanel />
                                        <LoadInfoPanel />
                                        <UnloadInfoPanel />
                                        <DriverHoursDetailPanel />
                                        <ServicesPanel />
                                        <ChargeDetailPanel />
                                        <AttachmentsDetailsPanel />
                                    </TicketDetailWrapper>
                                    :
                                    <Flex
                                        w="full"
                                        h="100vh"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <PuffLoader
                                            color="#3D82CE"
                                            size={80}
                                        />
                                    </Flex>
                            }
                        </Container > :
                        <ErrorBox show={isError} error={error} message={networkError} />
                    :

                    <Flex w="full" justifyContent="center" alignItems="center" flex={1}>
                        <PuffLoader
                            color="#3D82CE"
                            size={80}
                        />
                    </Flex>
            }
        </>
    )
}
