import { FieldProps } from "../../forms";
import { ReactNode, useEffect } from "react";
import { CustomButton } from "./CustomButton";
import { useFieldArray } from "react-hook-form";
import { Box, IconButton } from "@chakra-ui/react";
import { KnownIcon } from "../widgets/KnownIcon";

interface FieldArrayProps extends FieldProps {
  children: (fieldName: string) => ReactNode
  onRemove?(index: number): void
  isValue?: number | null
  setIsValue(data: number | null): void
}

export function FieldArray({ name, children, onRemove, isValue, setIsValue }: FieldArrayProps) {
  const { fields, append, remove } = useFieldArray({ name })
  useEffect(() => {
    if (fields.length === 0) {
      append(null)
    }
  }, [append, fields])

  const handleRemove = (index: number) => {
    remove(index)
    onRemove && onRemove(index)
  }
  const handleAdd = () => {
    append(null)
    setIsValue(null)
  }

  return (
    <Box w="full">
      {fields.map((field, index) => (
        <Box key={field.id} display="flex" alignItems="center" gap={{ base: 4, md: 8 }}>
          {children(`${name}[${index}]`)}
          {index !== fields.length - 1 ? (
            <IconButton
              aria-label="Remove"
              icon={<KnownIcon name="delete" boxSize="24px" cursor="pointer" />}
              onClick={() => handleRemove(index)}
              isDisabled={(isValue == null && fields.length == 2) ? true : false}
              backgroundColor="transparent"
              _hover={{ backgroundColor: "transparent" }}
            />
          ) : (
            <CustomButton title="Add" variant="outline" w={{ base: "40px", md: "80px" }} size={["sm"]} onClick={() => handleAdd()} isDisabled={isValue !== null ? false : true} visibility={fields.length == 5 ? "hidden" : "visible"} />
          )}
        </Box>
      ))}
    </Box>
  )
}
