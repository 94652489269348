import { useAppSelector } from '../app/hooks';
import { DetailHeading } from './DetailHeading';
import { KnownIcon } from './widgets/KnownIcon';
import { formatDateOnly, formatTimeOnly } from '../app/utils';
import { HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { selectActivityById } from '../features/activities/activitySlice';

interface LocalTimeEntryCardProps {
    index: number,
    timeEntry: any,
    onRemove(index: number): void
}

export default function LocalTimeEntryCard({
    index,
    timeEntry,
    onRemove
}: LocalTimeEntryCardProps) {

    const { activity, ticketDraft, start, finish } = timeEntry
    const activityName = useAppSelector(state => selectActivityById(state, Number(activity)))?.name
    return (
        <VStack alignItems={"flex-start"} justifyContent={"space-between"} bgColor={"#EBEEFF"}
            w={{ base: "280px", sm: "360px", lg: "400px" }}
            h={"120px"}
            flexShrink={0}
            p={4} borderRadius={"6px"}
        >
            <HStack w="full" alignItems={"center"} justifyContent={"space-between"}>
                <HStack gap={"10px"}>
                    <DetailHeading
                        title={`${activityName}`}
                        fontSize={[16, 16, 18]}
                        fontWeight={600}
                    />
                    <DetailHeading
                        title={`(${ticketDraft})`}
                        fontSize={'16px'}
                        fontWeight={300}
                    />
                </HStack>
                <HStack alignItems={"center"} cursor={'pointer'} onClick={() => onRemove(index)}>
                    <Text color={"#E02020"}>Delete</Text>
                    <KnownIcon name='delete' width={"1rem"} />
                </HStack>
            </HStack>
            <Stack w="full" direction={["column", "row"]} gap={[0]} alignItems={["flex-start", "center"]} justifyContent={"space-between"}>
                <HStack gap={"10px"}>
                    <DetailHeading
                        title={`${formatDateOnly(start)}`}
                        fontSize={"12px"}
                        fontWeight={500}
                    />
                    <KnownIcon name='clock' />
                    <DetailHeading
                        title={`${formatTimeOnly(start)}`}
                        fontSize={'12px'}
                        fontWeight={300}
                    />
                </HStack>
                <HStack gap={"10px"}>
                    <DetailHeading
                        title={`${formatDateOnly(finish)}`}
                        fontSize={"12px"}
                        fontWeight={500}
                    />
                    <KnownIcon name='clock' />
                    <DetailHeading
                        title={`${formatTimeOnly(finish)}`}
                        fontSize={'12px'}
                        fontWeight={300}
                    />
                </HStack>
            </Stack>
        </VStack >
    )
}
