import { useDispatch } from 'react-redux';
import { useAppSelector } from '../app/hooks';
import { useLocation, useParams } from 'react-router-dom';
import { TicketResource } from '../pages/user/TicketDetailPage';
import { selectTicketById } from '../features/tickets/ticketsSlice';
import { calculateAmount, capitalizeFirstLetter } from '../app/utils';
import { usePartialUpdateTicketMutation } from '../features/tickets/ticketsApi';
import { Children, ReactElement, ReactNode, cloneElement, isValidElement } from 'react';
import { Draft, DraftChargeValues, saveDraftCharge, selectDraft } from '../features/draft/draftSlice';
import { useCreateChargeMutation, useDestroyChargeMutation } from '../features/charges/chargesApi';

export interface ChargeDetailWrapperProps {
    children?: ReactNode | ReactNode[]
}

export interface ChargeWrapperProps {
    dataresource?: TicketResource | Draft | null;
    isdraft?: boolean
    resourceId?: string | number
    handlesavecharge?(data: any): void
    handleRemoveCharge?(index: number): void
    handleAskToDriverLead?(e: React.ChangeEvent<HTMLInputElement>): void
}


export const ChargeDetailWrapper = ({ children }: ChargeDetailWrapperProps) => {
    const dispatch = useDispatch()
    const { id } = useParams<{ id: string }>();
    const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0
    const pathname = useLocation().pathname

    const isdraft = pathname.includes("user/draft");
    const resourceId = isdraft ? draftIndex : id;

    const [createCharge] = useCreateChargeMutation()
    const [destroyCharge] = useDestroyChargeMutation()
    const [partialUpdateTicket] = usePartialUpdateTicketMutation();

    //selector based on draft or ticket <---- hear defined separate selectors, so typescript will understand types based on selectors
    const draftData = isdraft
        ? useAppSelector(selectDraft(Number(resourceId))) as Draft
        : null;

    const ticketData = !isdraft
        ? useAppSelector((state) => selectTicketById(state, Number(resourceId))) as TicketResource
        : null;

    const dataresource = isdraft ? draftData : ticketData;


    //save handlers based on darft or ticket
    const handleTicketCharge = async (data: any) => {
        const capitalizedUnit = capitalizeFirstLetter(data.unit);
        const payload = {
            ...data,
            ticket: resourceId,
            unit: capitalizedUnit,
        };
        await createCharge(payload);
    };

    const handleDraftCharge = (data: any) => {
        const { description, quantity, rate, unit, allow_surcharge } = data;
        const capitalizedUnit = capitalizeFirstLetter(unit);
        const calculatedAmount = calculateAmount(rate, quantity, unit);

        const finalPayload: DraftChargeValues = {
            rate,
            unit: capitalizedUnit,
            quantity,
            description,
            allow_surcharge,
            amount: calculatedAmount,
        };
        if (isdraft) {
            const updatedCharges = [...(draftData?.charges ?? []), finalPayload];
            dispatch(saveDraftCharge({ id: resourceId, updatedCharges }));
        }
    };
    const handlesavecharge = (data: any) => {
        isdraft ? handleDraftCharge(data) : handleTicketCharge(data);
    };

    //remove handler based on draft or ticket
    const handleRemoveTickeCharge = (index: number) => {
        if (dataresource && "chargedetails" in dataresource) {
            const destroyingCharge = dataresource?.chargedetails[index]
            destroyCharge(destroyingCharge.id)
            console.log("Destroyed charge", index, destroyingCharge)
        }
    }
    const handleRemoveDraftCharge = (index: number) => {
        if (dataresource && 'charges' in dataresource) {
            const updatedCharges = [...(dataresource?.charges ?? [])];
            updatedCharges.splice(index, 1)
            dispatch(saveDraftCharge({ id: resourceId, updatedCharges }));
        }
    }
    const handleRemoveCharge = (data: any) => {
        isdraft ? handleRemoveDraftCharge(data) : handleRemoveTickeCharge(data);
    };


    const handleAskToDriverLead = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target
        const body = {
            id: Number(id),
            driver_lead_add_charges: checked,
        }
        partialUpdateTicket(body)
    }

    return (
        <>
            {
                Children.map(children, (child) => {
                    if (isValidElement(child)) {
                        return cloneElement(child as ReactElement<ChargeWrapperProps>, { resourceId, dataresource, isdraft, handlesavecharge, handleRemoveCharge, handleAskToDriverLead });
                    }
                    return child;
                })
            }
        </>
    );
};
