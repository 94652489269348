import { Flex } from '@chakra-ui/react';
import { TICKET_STATUS_OPTIONS } from '../../features/tickets/ticket_choices';

interface StatusBadgesProps {
    status: string
}

export function StatusBadges({ status }: StatusBadgesProps) {
    const statusOption = TICKET_STATUS_OPTIONS.find(option => option.value === status);
    const bgColor = statusOption?.bgColor;
    const color = statusOption?.color;
    const label = statusOption?.label;

    return (
        status && <Flex width="fit-content" minW={{ base: "80px", lg: "125px" }} h={"29px"} fontSize={{ base: "12px", lg: "14px" }} borderRadius={"40px"} bgColor={bgColor} color={color} justifyContent={"center"} alignItems={"center"}> {label}</Flex>
    )
}


