import { VStack } from '@chakra-ui/react';
import { InputStreak } from '../../components/forms/InputStreak';
import FieldGroupArray from '../../components/forms/FieldGroupArray';
import { ProductAutocomplete } from '../products/ProductAutocomplete';
import { InputField } from '../../components/forms/fields/InputField';
import { NumberField } from '../../components/forms/fields/NumberField';
import { BooleanField } from '../../components/forms/fields/BooleanField';
import TankGaugeReadingField from '../../components/forms/fields/TankGaugeReadingField';

interface PickUpFormProps {
    onRemove?(index: number): void
}

export function PickUpPanelForm({ onRemove }: PickUpFormProps) {
    return (
        <>
            <VStack w="full" gap={4} p={[0, 2, 4]}>
                <InputStreak >
                    <NumberField label="Estimated Volume" name="estimated_volume" placeholder="Estimated volume as seen by the driver" />
                    <InputField label="Sequence" name="sequence" placeholder="Write it here..." />
                </InputStreak>
                <InputStreak >
                    <NumberField label="SW Percent" name="sw_percent" placeholder="Write it here..." />
                </InputStreak>
                <InputStreak>
                    <ProductAutocomplete label="Residue Last Contained" name="residue_last_contained" placeholder="Product last transported" />
                    <BooleanField label="Confirmed Empty" name="confirmed_empty" />
                </InputStreak>
                <InputStreak label="Tank Gauge" >
                    <FieldGroupArray name="tank_gauge_details" onRemove={onRemove}>
                        {(fieldName1, fieldName2) =>
                        (<>
                            <TankGaugeReadingField name={fieldName1} />
                            <TankGaugeReadingField name={fieldName2} />
                        </>)}
                    </FieldGroupArray>
                </InputStreak>
            </VStack>

        </>
    )
}

