import { useEffect, useMemo, useState } from "react"
import { useAppSelector } from "../../app/hooks"
import { PanelWrapper } from "../../components/PanelWrapper"
import { DetailTile } from "../../components/DetailTile"
import { selectTicketTimeEntries } from "./ticketsSlice"
import { WrapperProps } from "../../components/TicketDetailWrapper"
import { TicketResource } from "../../pages/user/TicketDetailPage"
import { Box, HStack, StackDivider, Text, VStack } from "@chakra-ui/react"
import { formatMinutesAsHours, getGroupedTimeEntiryValue } from "../../app/utils"


interface DriverHours {
    [activity: string]: number;
}

interface DriverHourPanelProps extends WrapperProps {
    dataresource?: TicketResource
}

export function DriverHoursDetailPanel({
    dataresource: ticket,
    resourceid
}: DriverHourPanelProps) {
    const [driverHours, setDriverHours] = useState({})
    const [grandTotal, setGrandTotal] = useState<number>()
    const ticketTimeEntries = useAppSelector(selectTicketTimeEntries(Number(resourceid)))

    const memoizedDriverHours: { [activity: string]: number } = useMemo(() => {
        if (ticketTimeEntries) {
            return getGroupedTimeEntiryValue(ticketTimeEntries)
        }
        return {}
    }, [ticketTimeEntries])
    const memoizedGrandTotal = useMemo(() => {
        if (ticketTimeEntries) {
            const total = Object.values(memoizedDriverHours).reduce(
                (acc, totalHours) => acc + totalHours,
                0,
            )
            return total
        }
        return 0
    }, [memoizedDriverHours])
    useEffect(() => {
        setDriverHours(memoizedDriverHours)
        setGrandTotal(memoizedGrandTotal)
    }, [memoizedDriverHours, memoizedGrandTotal, ticketTimeEntries])


    // Helper function to render driver hours details
    const renderDriverHoursDetail = (driverHours: DriverHours) => {
        return Object.entries(driverHours).map(([activity, totalHours], index) => (
            <HStack key={index} alignItems="flex-start" gap={{ base: 2, lg: "60px" }} divider={<StackDivider />}>
                <DetailTile flexBasis={{ base: "50%", md: "30%" }} detail={activity} />
                <DetailTile flexBasis={{ base: "50%", md: "30%" }} px={{ base: 2, sm: 5 }} detail={formatMinutesAsHours(Number(totalHours))} />
            </HStack>
        ))
    }

    return (
        <>
            <PanelWrapper title="Driver Hours">
                {
                    ticket && ticketTimeEntries && ticketTimeEntries?.length > 0 ?
                        <VStack align="stretch" gap={{ base: 2, lg: 4 }} p={2} divider={<StackDivider />}>
                            <VStack width="full" alignItems='stretch' gap={2}>
                                <HStack alignItems="flex-start" fontSize="14px" color="#455360" textAlign="left" gap={{ base: 3, lg: "60px" }} divider={<StackDivider />}>
                                    <Text flexBasis={{ base: "50%", md: "30%" }}>Activity</Text>
                                    <Text px={{ base: 2, sm: 5 }} flexBasis={{ base: "50%", md: "30%" }}>
                                        Total Hours
                                    </Text>
                                </HStack>
                                <VStack align="stretch" >
                                    {renderDriverHoursDetail(driverHours)}
                                </VStack>
                            </VStack>
                            <HStack w={"full"} alignItems="flex-start" gap={"60px"} divider={<StackDivider />}>
                                <DetailTile title="Grand Total" detail={formatMinutesAsHours(grandTotal)} />
                            </HStack>
                        </VStack>
                        :
                        <Box fontSize="14px" color="#455360">
                            No time entries have been added.
                        </Box>
                }
            </PanelWrapper >
        </>
    )
}

