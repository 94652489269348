
import { useMemo } from "react"
import { formatDateOnly } from "../../app/utils"
import { DetailTile } from "../../components/DetailTile"
import { PanelWrapper } from "../../components/PanelWrapper"
import { Stack, StackDivider, VStack } from "@chakra-ui/react"
import { StatusBadges } from "../../components/widgets/StatusBadges"
import { WrapperProps } from "../../components/TicketDetailWrapper"
import { TicketResource } from "../../pages/user/TicketDetailPage"


interface BasicDetailPanelProps extends WrapperProps {
    dataresource?: TicketResource
}

export function BasicDetailPanel({
    dataresource: ticket,
}: BasicDetailPanelProps) {

    const equipmentsDetail = useMemo(() => {
        return ticket?.equipments?.map?.((equipment) => (
            `${equipment.unit} (${equipment?.category?.name})`
        ))
    }, [ticket])?.join(", ")

    if (!ticket) return null;
    return (
        <>
            <PanelWrapper title="General Information">
                <VStack align="stretch" gap={{ base: 0, lg: 3 }} p={2} divider={<StackDivider />}>
                    <Stack direction={{ base: "column", lg: "row" }} w="full" align="flex-start" gap={{ base: 0, lg: 2 }} divider={<StackDivider />}>
                        <DetailTile
                            flexBasis="60%"
                            title="Customer"
                            icon="blueUser"
                            detail={ticket?.customer.name}
                        />
                        <Stack direction={"row"} w="full" gap={6} divider={<StackDivider />}>
                            <DetailTile
                                title="Date"
                                icon="blueUser"
                                flexBasis={{ base: "50%", lg: "40%" }}
                                detail={formatDateOnly(ticket?.reference_date)}
                            />
                            <DetailTile
                                title="Status"
                                detail={<StatusBadges status={ticket?.status || ''} />}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction={{ base: "column", lg: "row" }} w="full" align="flex-start" gap={{ base: 3, lg: 8 }} divider={<StackDivider />}>
                        <Stack direction={{ base: "column", md: "row" }} w="full" gap={{ base: 3, md: 6 }} divider={<StackDivider />}>
                            <DetailTile
                                flexBasis="50%"
                                title="Consignor Contact"
                                icon="phone"
                                detail={
                                    ticket?.consignor_contact
                                        ? `${ticket.consignor_contact} (${ticket?.consignor_phone})`
                                        : ticket?.consignor_phone
                                }
                            />
                            <DetailTile
                                title="Emergency Contact"
                                icon="phone"
                                detail={ticket?.emergency_contact}
                            />
                        </Stack>
                        <DetailTile
                            flexBasis={"60%"}
                            title="Billing Email Address"
                            icon="at"
                            detail={ticket?.billing_email}
                        />

                    </Stack>
                    <DetailTile
                        title="Equipment"
                        detail={equipmentsDetail}
                        w="full"
                    />
                </VStack>

            </PanelWrapper>
        </>
    )
}
