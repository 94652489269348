import { useState } from "react";
import { ChargesForm } from "../charges/ChargesForm";
import { selectTicketDropOffDetail } from "./ticketsSlice";
import { Draft, selectDroppOff } from "../draft/draftSlice";
import { ModalForm } from "../../components/forms/ModalForm";
import { PanelWrapper } from "../../components/PanelWrapper";
import { useAppSelector, useConfigCheck } from "../../app/hooks";
import { CustomButton } from "../../components/forms/CustomButton";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { ChargeWrapperProps } from "../../components/ChargeDetailWrapper";
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useMediaQuery } from "@chakra-ui/react";

export interface VolumeTableProps extends ChargeWrapperProps {
  dataresource?: TicketResource | Draft
  handlesavecharge?(data: any): void
}

export function VolumeTable({ dataresource, resourceId, isdraft, handlesavecharge }: VolumeTableProps) {
  const { isOnline } = useConfigCheck()
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure()
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
  const modalSize = isLargerThanMobile ? "lg" : "full"

  const [defaultValues, setDefaultValues] = useState(null) //set default value for modal

  //check whether entry is in charge already or not
  const isInCharges = () => {
    if (isdraft && dataresource && 'charges' in dataresource) {
      return dataresource.charges?.some((item) => item.description === "Actual Volume");
    } else if (dataresource && 'chargedetails' in dataresource) {
      return dataresource.chargedetails.some((item) => item.description === "Actual Volume");
    }
    return false;
  };

  //Selector based on draft or ticket
  const selectResource = isdraft ?
    useAppSelector(selectDroppOff(Number(resourceId)))
    : useAppSelector(selectTicketDropOffDetail(Number(resourceId)))

  return (
    <PanelWrapper title="Volume">
      {
        selectResource?.actual_volume ? (
          <Box
            className="custom-scrollbar"
            maxWidth="calc(100vw - 90px)"
            width="100%"
            overflowX="auto"
          >
            <Table >
              <Thead color={"#455360"}>
                <Tr sx={{ th: { borderBottom: "none", fontSize: "1rem", fontWeight: 600 } }} >
                  <Th></Th>
                  <Th>Cubic Meters</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody color={"#7B8389"} >
                <Tr h="72px" sx={{ td: { borderBottom: "none" } }} >
                  <Td w="40%">{selectResource?.actual_volume}</Td>
                  <Td w="40%" >Actual</Td>
                  <Td w="20%" textAlign={"end"}>
                    {
                      !isInCharges() &&
                      <CustomButton title="$" w={"40px"}
                        isDisabled={(isOnline || isdraft) ? false : true}
                        onClick={() => {
                          openModal()
                          const data = {
                            description: "Actual Volume",
                            unit: "Cubic Meters",
                            quantity: selectResource?.actual_volume,
                            allow_surcharge: true,
                          }
                          setDefaultValues(data as any)
                        }} />
                    }
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        )
          :
          <Text>{"Volume charges will be added by the Driver Lead"}</Text>
      }
      {/* Dialog for adding new charge entry */}
      <ModalForm
        isdraft={isdraft}
        title="Add New Entry"
        isOpen={isModalOpen}
        size={modalSize}
        onClose={closeModal}
        onCancel={closeModal}
        onSave={handlesavecharge}
        defaultValues={defaultValues}
      >
        <ChargesForm />
      </ModalForm>
    </PanelWrapper>
  )
}
