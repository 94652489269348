import { useMemo } from 'react';
import { TimeEntryForm } from '../timeEntries/TimeEntryForm';
import { ModalForm } from '../../components/forms/ModalForm';
import { LocalEntry } from '../timeEntries/localEntriesSlice';
import { InputStreak } from '../../components/forms/InputStreak';
import { selectActivityById } from '../activities/activitySlice';
import { CustomButton } from '../../components/forms/CustomButton';
import { WrapperProps } from '../../components/TicketDetailWrapper';
import { KnownIcon } from '../../components/widgets/KnownIcon';
import { ReadonlyField } from '../../components/forms/fields/ReadonlyField';
import { calculateDeltaMinutes, formatMinutesAsHours } from '../../app/utils';
import { Box, HStack, IconButton, StackDivider, Text, useDisclosure, VStack } from '@chakra-ui/react';


interface DriverHoursForm_NEWProps extends WrapperProps {
    timeEntries: LocalEntry[]
    onSave(data: any): void
    onRemove(activity: number): void
}

function DriverHoursForm_NEW({
    timeEntries,
    onRemove,
    onSave,
}: DriverHoursForm_NEWProps) {
    const { isOpen, onOpen, onClose, } = useDisclosure()
    const grouped: { [activity: number]: number } = useMemo(() => {
        return timeEntries?.reduce((acc: any, entry: any) => {
            acc[entry.activity] =
                (acc[entry.activity] || 0) +
                calculateDeltaMinutes(entry.start, entry.finish)
            return acc
        }, {})
    }, [timeEntries])
    const grandTotal = useMemo(() => {
        return Object.values(grouped).reduce(
            (acc, totalHours) => acc + totalHours,
            0,
        )
    }, [grouped])

    return (
        <>
            <VStack w="full" alignItems="flex-start" gap={4} p={[0, 2, 4]} divider={<StackDivider />}>
                {
                    timeEntries?.length > 0 ?
                        (Object.entries(grouped).map(([activity, minutes], index) => (
                            <HStack w="full" alignItems="flex-end" key={index} >
                                <InputStreak>
                                    <ReadonlyField label="Activity" traitId={Number(activity)} traitGetter={selectActivityById} />
                                    <ReadonlyField label="Total Hours" value={formatMinutesAsHours(minutes)} />
                                </InputStreak>
                                <IconButton
                                    aria-label="Remove"
                                    icon={<KnownIcon name="delete" boxSize="24px" cursor="pointer" />}
                                    onClick={() => { onRemove(Number(activity)) }}
                                    backgroundColor="transparent"
                                    _hover={{ backgroundColor: "transparent" }}
                                />
                            </HStack>)
                        )) : (
                            <Box w="full" fontSize="14px" color="#455360" bgColor={"#fff"} borderRadius={"8px"} p={3}>
                                Add Driver Hours
                            </Box>
                        )}
                <Box>
                    {Object.entries(grouped).length > 0 &&
                        <HStack mb={5}><Text fontSize="14px" color="#455360" >Grand Total Hours:</Text> <Box color={"#0E1628"} fontSize={18} >{formatMinutesAsHours(grandTotal)}</Box></HStack>
                    }
                    <CustomButton title="Add New Entry" variant='outline' onClick={onOpen} />
                </Box>
            </VStack>
            <ModalForm
                title={`Add New Entry `}
                isOpen={isOpen}
                size={["full", "lg"]}
                onClose={onClose}
                onCancel={onClose}
                onSave={onSave}
            >
                <TimeEntryForm />
            </ModalForm>
        </>
    )
}

export default DriverHoursForm_NEW
