import { DetailHeading } from './DetailHeading';
import { CustomButton } from './forms/CustomButton';
import { Box, HStack, VStack } from '@chakra-ui/react';
import React, { Children, isValidElement, ReactElement, ReactNode } from 'react';


interface PanelWrapperProps {
    title: string,
    children: React.ReactNode | React.ReactNode[],
}

export function PanelWrapper({
    children,
    title,
}: PanelWrapperProps) {
    const { button, filteredChildren } = Children.toArray(children).reduce<{
        button: ReactElement | null;
        filteredChildren: ReactNode[];
    }>(
        (acc, child) => {
            if (isValidElement(child) && child.type === CustomButton) {
                acc.button = child;
            } else {
                acc.filteredChildren.push(child);
            }
            return acc;
        },
        { button: null, filteredChildren: [] }
    );

    return (
        <VStack w="full" alignItems={"flex-start"} gap={[3, 5]}>
            <HStack w={"full"} justifyContent={"space-between"}>
                <DetailHeading title={title} />
                {button}
            </HStack>
            <Box w="full" bgColor={"#fff"} borderRadius={"8px"} p={3}>
                {filteredChildren}
            </Box>
        </VStack>
    )
}
