import { OptionType } from "./tickets"

export const TICKET_STATUS_OPTIONS = [
    { value: "submitted", label: "Submitted", bgColor: "#FFF8B9", color: "#958603" },
    { value: "approved", label: "Approved", bgColor: "#FFD6AA", color: "#D16400" },
    { value: "stamped", label: "Stamped", bgColor: "#C7FFDD", color: "#009F40" },
    { value: "invoiced", label: "Invoiced", bgColor: "#DDEFFF", color: "#0061BA" },
    { value: "unapproved", label: "Unapproved", bgColor: "#FFDFDF", color: "#FF0101" },
    { value: "with_customer", label: "With Customer", bgColor: "#E7D7FF", color: "#6200EE" },
]


export const pageSizeOptions: OptionType[] = [10, 20, 30, 40].map((pageSize): OptionType => {
    return {
        value: pageSize,
        label: `${pageSize} / page`,
    }
})
export const Options: OptionType[] = [10, 20, 30, 40].map((pageSize): OptionType => {
    return {
        value: pageSize,
        label: `${pageSize} / page`,
    }
})