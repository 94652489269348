import { bolmintApi } from "../../app/api";
import { operations } from "../../app/api/schema";

export const awsApi = bolmintApi.injectEndpoints({
    endpoints: (builder) => ({
        getPresignedUrl: builder.mutation<
            operations["presigned_generate"]["responses"]["200"]["content"]["application/json"],
            operations["presigned_generate"]["requestBody"]["content"]["application/json"]
        >({
            query: (resource) => ({
                url: `/presigned-url/post/`,
                method: "POST",
                body: resource,
            }),
        }),
        uploadFileToS3: builder.mutation<
            any, { url: string, file: File | Blob }
        >({
            query: ({ url, file }) => ({
                url: url,
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type,
                },
            }),
            invalidatesTags: ['attachments']
        }),
        retriveAttachments: builder.query<
            operations["tickets_attachments_list"]["responses"]["200"]["content"]["application/json"]
            , any
        >({
            query: (draft_id) => ({
                url: `/presigned-urls/get/${draft_id}/?folder=attachments`
            }),
            providesTags: ['attachments']
        }),
        retriveSignature: builder.query<
            operations["tickets_attachments_list"]["responses"]["200"]["content"]["application/json"]
            , any
        >({
            query: (draft_id) => ({
                url: `/presigned-urls/get/${draft_id}/?folder=signature`
            }),
            providesTags: ['signature']
        }),
        destroyAttachments: builder.mutation<void, number>({
            query: (draft_id) => ({
                url: `/delete-drft/destroy/${draft_id}/`,
                method: "DELETE",
            }),
        }),
        removeAttachment: builder.mutation<
            void, { draft_id: number; file_key: string }
        >({
            query: ({ draft_id, file_key }) => ({
                url: `/destroy-files/${draft_id}/`,
                method: "POST",
                body: JSON.stringify({ file_key }),
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            invalidatesTags: ['ticket', 'attachments']
        }),
        cleanAttachment: builder.mutation<
            void, { draft_ids: number[] }
        >({
            query: ({ draft_ids }) => ({
                url: `/destroy-multiple-drafts/`,
                method: "POST",
                body: JSON.stringify({ draft_ids }),
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        })
    }),
})


export const {
    useGetPresignedUrlMutation,
    useUploadFileToS3Mutation,
    useRetriveAttachmentsQuery,
    useRetriveSignatureQuery,
    useDestroyAttachmentsMutation,
    useRemoveAttachmentMutation,
    useCleanAttachmentMutation
} = awsApi
