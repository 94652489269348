import { useEffect } from "react";
import { BasicForm } from "./BasicForm";
import { DraftBasicValues } from "./tickets";
import { Container } from "../../components/Container";
import { PanelWrapper } from "../../components/PanelWrapper";
import { EditForm } from "../../components/forms/EditForm";
import { OverlayLoader } from "../../components/OverlayLoader";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { CustomButton } from "../../components/forms/CustomButton";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { saveDraftBasic, selectDraftBasic } from "../draft/draftSlice";
import { removeEquipment, selectTicketBasic, updateTicketBasic } from "./ticketsSlice";

interface BasicPanelProps extends WrapperProps {
    scrollTo?(name: string): void,
    dataresource?: TicketResource
}

export function BasicPanel({ dataresource, scrollTo, isdraft, resourceid }: BasicPanelProps) {
    const dispatch = useAppDispatch()

    //Selector based on draft or ticket
    const selectResource = isdraft
        ? useAppSelector(selectDraftBasic(Number(resourceid)))
        : useAppSelector(selectTicketBasic(Number(resourceid))) || {};

    const methods = useForm({ defaultValues: selectResource })
    const { control, handleSubmit, formState: { isSubmitting }, watch } = methods

    // Watch form values to detect changes
    const watchedValues = useWatch({
        control,
        defaultValue: selectResource,
    })
    const hasChanged = JSON.stringify(watchedValues) !== JSON.stringify(selectResource)

    /* Save handler based on draft or ticket
     * Save to local storage whenever watched values change for draft (AutoSave)
     */
    const handleSaveResource = async (data: any) => {
        if (!isdraft) await dispatch(updateTicketBasic({ id: resourceid, ...data }))
        scrollTo?.("LocationsForm")
    }
    useEffect(() => {
        if (isdraft) dispatch(saveDraftBasic({ id: Number(resourceid), ...watchedValues } as DraftBasicValues));
    }, [watchedValues, resourceid, dispatch, isdraft]);


    // Equipment remove handler
    const handleEquipmentRemove = async (index: number) => {
        const equipmentId = dataresource?.equipments && dataresource?.equipments?.[index]
        await dispatch(removeEquipment({ id: Number(resourceid), ticket_equipment_id: Number(equipmentId) }))
    }

    return (
        <>
            {isSubmitting && <OverlayLoader />}
            <Container mb={0}>
                <FormProvider {...methods}>
                    <EditForm onSave={handleSubmit(handleSaveResource)}>
                        <PanelWrapper title="General Information">
                            {hasChanged && !isdraft && <CustomButton title="Save" type="submit" variant="outline" w={["80px"]} size={"sm"} isLoading={isSubmitting} />}
                            <BasicForm onRemove={!isdraft ? handleEquipmentRemove : undefined} />
                        </PanelWrapper>
                    </EditForm>
                </FormProvider>
            </Container>
        </>
    )
}
