import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit"
import { ticketsApi } from "./ticketsApi"
import { RootState } from "../../app/store"
import { productsApi } from "../products/productsApi"
import { servicesApi } from "../services/servicesApi"
import { customersApi } from "../customers/customersApi"
import { terminalsApi } from "../terminals/terminalsApi"
import { locationsApi } from "../terminals/locationsApi"
import { TicketResource } from "../../pages/user/TicketDetailPage"
import { DraftBasicValues, DraftExtraValues, DraftLocationsValues, DraftProductsValues, DraftServiceValues, DroppOffValues, UpdatedDraftProductsValues, UpdateDraftServiceValues, UpdateDraftTankGaugeValues, UpdateEquipmentValues } from "./tickets"

const ticketsAdapter = createEntityAdapter<TicketResource>({
  selectId: (ticket) => ticket.id,
})
const initialState = ticketsAdapter.getInitialState({
  status: "idle",
  error: null,
})
const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    // Reducer functions will go here if needed
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ticketsApi.endpoints.listTickets.matchFulfilled,
      (state, action) => {
        ticketsAdapter.setAll(state, action.payload)
      },
    )
    builder.addMatcher(
      ticketsApi.endpoints.createTicket.matchFulfilled,
      (state, action) => {
        ticketsAdapter.addOne(state, action.payload)
      },
    )
    builder.addMatcher(
      ticketsApi.endpoints.retrieveTicket.matchFulfilled,
      (state, action) => {
        ticketsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      ticketsApi.endpoints.updateTicket.matchFulfilled,
      (state, action) => {
        ticketsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      ticketsApi.endpoints.partialUpdateTicket.matchFulfilled,
      (state, action) => {
        ticketsAdapter.upsertOne(state, action.payload)
      },
    )
    builder.addMatcher(
      ticketsApi.endpoints.destroyTicket.matchFulfilled,
      (state, action) => {
        ticketsAdapter.removeOne(state, action.meta.arg.originalArgs)
      },
    )
  },
})

const selectTicket = (id: number) => (state: RootState) => selectTicketById(state, id)

export const selectTicketBasic = (id: number) => createSelector(
  [selectTicket(id)],
  (ticket) => {
    if (ticket) {
      return {
        reference_date: ticket?.reference_date,
        consignor_contact: ticket?.consignor_contact,
        consignor_phone: ticket?.consignor_phone,
        emergency_contact: ticket?.emergency_contact,
        customer: ticket?.customer_id,
        equipments: ticket?.equipmentdetails?.length
          ? ticket.equipmentdetails.map((detail) => detail.equipment_id)
          : [null],
        billing_email: ticket?.billing_email,
      };
    }
    return null;
  }
);

export const selectTicketConsignor = (id: number) => createSelector(
  [selectTicket(id)],
  (ticket) => {
    if (ticket) {
      return {
        terminal: ticket?.consignor_terminal_id,
        location: ticket?.consignor_location_id,
        lsdprefix: ticket?.consignor_lsdprefix,
        lsd: ticket?.consignor_lsd,
      }
    }
    return null
  }
);

export const selectTicketConsignee = (id: number) => createSelector(
  [selectTicket(id)],
  (ticket) => {
    if (ticket) {
      return {
        terminal: ticket?.consignee_terminal_id,
        location: ticket?.consignee_location_id,
        lsdprefix: ticket?.consignee_lsdprefix,
        lsd: ticket?.consignee_lsd,
      }
    }
    return null
  }
);
export const selectTicketLocations = (id: number) => createSelector(
  [selectTicket(id)],
  (ticket) => {
    if (ticket) {
      return {
        consignor: {
          terminal: ticket?.consignor_terminal_id,
          location: ticket?.consignor_location_id,
          lsdprefix: ticket?.consignor_lsdprefix,
          lsd: ticket?.consignor_lsd,
        },
        consignee: {
          terminal: ticket?.consignee_terminal_id,
          location: ticket?.consignee_location_id,
          lsdprefix: ticket?.consignee_lsdprefix,
          lsd: ticket?.consignee_lsd,
        }
      }
    }
    return null
  }
);



export const selectTicketProducts = (id: number) => createSelector(
  [selectTicket(id)],
  (ticket) => {
    if (ticket) {
      const productList = ticket.products.map((item, index) => {
        return {
          product: item?.id,
          subsidiary_class: ticket?.productdetails?.[index]?.subsidiary_class,
          toxic_by_inhalation: ticket?.productdetails?.[index]?.toxic_by_inhalation,
          quantity: ticket?.productdetails?.[index]?.quantity,
          packages: ticket?.productdetails?.[index]?.packages
        }
      })
      return productList
    }
    return null
  }
);
export const selectTicketPickUpDetail = (id: number) => createSelector(
  [selectTicket(id)],
  (ticket) => {
    if (ticket) {
      return {
        estimated_volume: ticket?.estimated_volume && Number(ticket?.estimated_volume),
        sequence: ticket?.sequence,
        sw_percent: Number(ticket?.sw_percent),
        tank_gauge_details: ticket?.tank_gauge_details?.length ? ticket.tank_gauge_details
          : [
            {
              start: '',
              finish: '',
            },
          ]
        ,
        confirmed_empty: ticket?.confirmed_empty,
        residue_last_contained: ticket?.residue_last_contained?.id,
      }

    }
    return null
  }
);
export const selectTicketDropOffDetail = (id: number) => createSelector(
  [selectTicket(id)],
  (ticket) => {
    if (ticket) {
      return {
        actual_volume: ticket?.actual_volume && Number(ticket?.actual_volume),
        work_description: ticket?.work_description,
      }
    }
    return null
  }
);



export const selectTicketExtra = (id: number) => createSelector(
  [selectTicket(id)],
  (ticket) => {
    if (ticket) {
      return {
        estimated_volume: ticket?.estimated_volume,
        actual_volume: ticket?.actual_volume,
        sequence: ticket?.sequence,
        sw_percent: ticket?.sw_percent,
        tank_gauge_details: ticket?.tank_gauge_details,
        work_description: ticket?.work_description,
      }
    }
    return null
  }
);

export const selectTicketServices = (id: number) => createSelector(
  [selectTicket(id)],
  (ticket) => {
    if (ticket) {
      const serviceList = ticket.services.map((item, index) => {
        return {
          service: item?.id,
          rate: ticket?.servicedetails?.[index].rate,
          description: ticket?.servicedetails?.[index].description,
          quantity: ticket?.servicedetails?.[index].quantity,
          record_id: ticket?.servicedetails?.[index].ticket_service_id
        }
      })
      return serviceList
    }
    return null
  }
);

export const selectTicketServiceDetails = (id: number) => createSelector(
  [selectTicket(id)],
  (ticket) => {
    if (ticket) {

      const serviceList = ticket.services.map((item, index) => {
        return {
          service: item?.id,
          rate: ticket?.servicedetails?.[index].rate,
          description: ticket?.servicedetails?.[index].description,
          quantity: ticket?.servicedetails?.[index].quantity
        }
      })
      return serviceList
    }
    return null
  }
);
export const selectTicketTimeEntries = (id: number) => createSelector(
  [selectTicket(id)],
  (ticket) => {
    if (ticket) {
      return ticket?.timeentries
    }
    return null
  }
);
export const selectTicketCharges = (id: number) => createSelector(
  [selectTicket(id)],
  (ticket) => {
    if (ticket) {
      return ticket?.chargedetails
    }
    return null
  }
);

export const updateTicketBasic = createAsyncThunk(
  "tickets/updateTicketBasic",
  async (basicValues: DraftBasicValues, { dispatch }) => {
    const customerId =
      typeof basicValues.customer === "string"
        ? (
          await dispatch(
            customersApi.endpoints.createCustomer.initiate({
              name: basicValues.customer,
            }),
          ).unwrap()
        ).id
        : basicValues.customer

    const equipmentDetails = basicValues.equipments.filter((item) => item && typeof item === "number").map((equipment) => ({
      equipment_id: equipment as number,
    }))

    // const lastContainedProductId =
    //   typeof basicValues.residue_last_contained === "string" &&
    //     basicValues.residue_last_contained.trim().length > 0
    //     ? (
    //       await dispatch(
    //         productsApi.endpoints.createProduct.initiate({
    //           name: basicValues.residue_last_contained,
    //         }),
    //       ).unwrap()
    //     ).id
    //     : basicValues.residue_last_contained

    const ticketPayload = {
      id: basicValues.id,
      reference_date: basicValues.reference_date,
      customer_id: customerId,
      // confirmed_empty: basicValues.confirmed_empty,
      // residue_last_contained: lastContainedProductId,
      emergency_contact: basicValues.emergency_contact,
      consignor_contact: basicValues.consignor_contact,
      consignor_phone: basicValues.consignor_phone,
      equipmentdetails: equipmentDetails,
      billing_email: basicValues.billing_email
    }

    await dispatch(
      ticketsApi.endpoints.partialUpdateTicket.initiate(ticketPayload),
    ).unwrap()
  },
)

export const updateTicketLocations = createAsyncThunk(
  "tickets/updateTicketLocations",
  async (locationValues: DraftLocationsValues, { getState, dispatch }) => {
    const { terminals, locations } = getState() as RootState;
    const { id, consignor, consignee } = locationValues;

    const getLocationEntity = (id: number) => locations?.entities?.[id];
    const getTerminalLocations = (id: number) => terminals?.entities?.[id]?.locations;

    const createTerminal = async (name: string, forType: "consignor" | "consignee") => {
      const response = await dispatch(terminalsApi.endpoints.createTerminal.initiate({ name, for: forType })).unwrap();
      return response.id;
    };

    const createLocation = async (name: string, terminalId: number, forType: "consignor" | "consignee") => {
      const response = await dispatch(locationsApi.endpoints.createLocation.initiate({ name, terminal_id: terminalId, for: forType })).unwrap();
      return response.id;
    };

    const isNonEmptyString = (value: any): value is string => typeof value === 'string' && value.trim() !== '';

    const processLocation = async (terminal: string | number | undefined | null, location: string | number | undefined | null, forType: "consignor" | "consignee") => {
      let terminalId: number | undefined;
      let locationId: number | undefined;

      if (typeof terminal === 'number' && typeof location === 'number') {
        const currentTerminalLocations = getTerminalLocations(terminal);
        const isExist = currentTerminalLocations?.some((item) => item.id === location);

        if (!isExist) {
          const currentLocation = getLocationEntity(location);
          if (!currentLocation?.name) {
            throw new Error(`${forType} location or location name is invalid`);
          }
          locationId = await createLocation(currentLocation.name, terminal, forType);
        } else {
          locationId = location;
        }
        terminalId = terminal;
      } else if (isNonEmptyString(terminal) && typeof location === 'number') {
        const currentLocation = getLocationEntity(location);
        if (!currentLocation?.name) {
          throw new Error(`${forType} location or location name is invalid`);
        }
        terminalId = await createTerminal(terminal, forType);
        locationId = await createLocation(currentLocation.name, terminalId, forType);
      } else if (isNonEmptyString(terminal) && isNonEmptyString(location)) {
        terminalId = await createTerminal(terminal, forType);
        locationId = await createLocation(location, terminalId, forType);
      } else if (typeof terminal === 'number' && isNonEmptyString(location)) {
        locationId = await createLocation(location, terminal, forType);
        terminalId = terminal;
      }

      return { terminalId, locationId };
    };

    try {
      const consignorResult = await processLocation(consignor?.terminal, consignor?.location, "consignor");
      const consigneeResult = await processLocation(consignee?.terminal, consignee?.location, "consignee");

      const ticketPayload = {
        id,
        consignor_terminal_id: consignorResult.terminalId,
        consignor_location_id: consignorResult.locationId,
        consignor_lsdprefix: consignor?.lsdprefix,
        consignor_lsd: consignor?.lsd,
        consignee_terminal_id: consigneeResult.terminalId,
        consignee_location_id: consigneeResult.locationId,
        consignee_lsdprefix: consignee?.lsdprefix,
        consignee_lsd: consignee?.lsd,
      };

      await dispatch(ticketsApi.endpoints.partialUpdateTicket.initiate(ticketPayload)).unwrap();
    } catch (error) {
      console.error("Error updating ticket locations:", error);
      throw error;
    }
  }
);
export const updateTicketProductDetails = createAsyncThunk(
  "tickets/updateTicketProducts",
  async (productValues: DraftProductsValues, { dispatch }) => {
    const productId =
      typeof productValues?.product === "string"
        ? (
          await dispatch(
            productsApi.endpoints.createProduct.initiate({
              name: productValues?.product,
            }),
          ).unwrap()
        ).id
        : productValues.product

    const ticketPayload = {
      id: productValues.id,
      productdetails: [
        {
          product_id: productId,
          toxic_by_inhalation: productValues.toxic_by_inhalation ?? false,
          subsidiary_class: productValues.subsidiary_class ?? "",
          quantity: productValues.quantity,
          packages: productValues.packages,
        }
      ]
    }
    await dispatch(
      ticketsApi.endpoints.partialUpdateTicket.initiate(ticketPayload),
    ).unwrap()

  }
)

export const removeEquipment = createAsyncThunk(
  "tickets/EquipmentRemove",
  async (equipmentValues: UpdateEquipmentValues, { dispatch }) => {
    await dispatch(
      ticketsApi.endpoints.removeEquipment.initiate(equipmentValues),
    ).unwrap()
  }
)

export const removeProductDetail = createAsyncThunk(
  "tickets/removeTicketProduct",
  async (productValues: UpdatedDraftProductsValues, { dispatch }) => {
    await dispatch(
      ticketsApi.endpoints.removeTicketProduct.initiate(productValues),
    ).unwrap()
  }
)

export const updateTicketExtra = createAsyncThunk(
  "tickets/updateTicketExtra",
  async (extraValues: DraftExtraValues, { dispatch }) => {
    const ticketPayload = {
      id: extraValues.id,
      estimated_volume: extraValues?.estimated_volume,
      actual_volume: extraValues?.actual_volume,
      sequence: extraValues?.sequence,
      sw_percent: Number(extraValues?.sw_percent),
      tank_gauge_details: extraValues.tank_gauge_details,
      work_description: extraValues?.work_description,
    }
    await dispatch(
      ticketsApi.endpoints.partialUpdateTicket.initiate(ticketPayload),
    ).unwrap()
  },
)
export const updateTicketPickUpDetail = createAsyncThunk(
  "tickets/updateTicketPickUpDetail",
  async (pickupValues: DraftExtraValues, { dispatch }) => {
    const lastContainedProductId =
      typeof pickupValues.residue_last_contained === "string" &&
        pickupValues.residue_last_contained.trim().length > 0
        ? (
          await dispatch(
            productsApi.endpoints.createProduct.initiate({
              name: pickupValues.residue_last_contained,
            }),
          ).unwrap()
        ).id
        : pickupValues.residue_last_contained

    const ticketPayload = {
      id: pickupValues.id,
      estimated_volume: pickupValues?.estimated_volume,
      sequence: pickupValues?.sequence,
      sw_percent: Number(pickupValues?.sw_percent),
      tank_gauge_details: pickupValues.tank_gauge_details,
      confirmed_empty: pickupValues?.confirmed_empty,
      residue_last_contained: lastContainedProductId,
    }
    await dispatch(
      ticketsApi.endpoints.partialUpdateTicket.initiate(ticketPayload),
    ).unwrap()
  },
)

export const updateTicketDropOffDetail = createAsyncThunk(
  "ticket/updateTicketDropoffDetail",
  async (dropOffValues: DroppOffValues, { dispatch }) => {
    const ticketPayload = {
      id: dropOffValues.id,
      actual_volume: dropOffValues?.actual_volume,
      work_description: dropOffValues?.work_description,
    }
    await dispatch(
      ticketsApi.endpoints.partialUpdateTicket.initiate(ticketPayload),
    ).unwrap()
  }
)

export const removeTicketTankGaugeRecord = createAsyncThunk(
  "ticket/removeTicketTankGaugeRecord",
  async (tankGaugeValues: UpdateDraftTankGaugeValues, { dispatch }) => {
    await dispatch(
      ticketsApi.endpoints.removeTankGaugeRecord.initiate(tankGaugeValues),
    ).unwrap()
  }
)

export const updateTicketServiceDetails = createAsyncThunk(
  "tickets/updateTicketServiceDetails",
  async (serviceValues: DraftServiceValues, { dispatch }) => {
    const serviceId =
      typeof serviceValues?.service === "string"
        ? (
          await dispatch(
            servicesApi.endpoints.createService.initiate({
              name: serviceValues?.service,
            }),
          ).unwrap()
        ).id
        : serviceValues.service

    const ticketPayload = {
      id: serviceValues.id,
      servicedetails: [
        {
          service_id: serviceId,
          rate: serviceValues.rate,
          quantity: serviceValues.quantity,
          description: serviceValues.description
        }
      ]
    }
    await dispatch(
      ticketsApi.endpoints.partialUpdateTicket.initiate(ticketPayload),
    ).unwrap()

  }
)

export const removeServiceDetail = createAsyncThunk(
  "tickets/removeTicketServiceDetails",
  async (serviceValues: UpdateDraftServiceValues, { dispatch }) => {
    await dispatch(
      ticketsApi.endpoints.removeTicketService.initiate(serviceValues),
    ).unwrap()
  }
)

export const updateAttachments = createAsyncThunk(
  "tickets/updateTicketAttchments",
  async (attachmentsvalues: any, { dispatch }) => {
    const ticketPayload = {
      id: attachmentsvalues.id,
      attachments: attachmentsvalues.attachments
    }

    await dispatch(
      ticketsApi.endpoints.partialUpdateTicket.initiate(ticketPayload),
    ).unwrap()
  }
)

export default ticketsSlice.reducer

export const {
  selectAll: selectAllTickets,
  selectById: selectTicketById,
  selectIds: selectTicketIds,
  selectEntities: selectTicketEntities,
  selectTotal: selectTotalTickets,
} = ticketsAdapter.getSelectors<RootState>((state) => state.tickets)
