import { Divider } from "@chakra-ui/react";
import { Container } from "../../components/Container";
import { SubmittedTimeEntries } from "./SubmittedTimeEntries";
import { MainPageHeader } from "../../components/MainPageHeader";
import { BreadcrumbItemType } from "../../components/BreadCrumbs";
import { TrackingPageHeader } from "../../components/TrackingPageHeader";
import { TimeEntryPanel } from "../../features/timeEntries/TimeEntryPanel";

export function TrackingPage() {

    const breadCrumbItems: BreadcrumbItemType[] = [
        { label: 'Home', path: '/user/home', icon: 'home' },
        { label: 'Time Tracking', isCurrentPage: true }
    ];

    return (
        <>
            <MainPageHeader breadCrumbItems={breadCrumbItems} />
            <Container direction="column">
                <TrackingPageHeader />
                <TimeEntryPanel />
                <Divider />
                <SubmittedTimeEntries />
            </Container>
        </>
    )
}
