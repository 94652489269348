import { bolmintApi } from "../../app/api"

interface Credentials {
  username: string
  password: string
}

export const sessionApi = bolmintApi.injectEndpoints({
  endpoints: (builder) => ({
    createSession: builder.mutation<any, Credentials>({
      query: (credentials) => ({
        url: "/sessions/",
        method: "POST",
        body: credentials,
      }),
    }),
    deleteSession: builder.mutation<any, number>({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/`,
        method: "DELETE",
      }),
    }),
  }),
})
