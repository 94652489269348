import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { baseValidationSchema } from '../../app/utils';
import { FocusView } from '../../layout/views/FocusView';
import { MessageBox } from '../../components/MessageBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { DetailHeading } from '../../components/DetailHeading';
import { useCustomToast, useMessageBox } from '../../app/hooks';
import { CustomButton } from '../../components/forms/CustomButton';
import { useIsValidTokenQuery, useResetPasswordMutation } from '../../features/profile/profileApi';
import { Box, FormControl, FormErrorMessage, FormLabel, Icon, Input, InputGroup, InputRightElement, VStack } from '@chakra-ui/react';

type Inputs = {
    new_password: string,
    confirm_new_password: string
}

export function ResetPasswordPage() {
    const navigate = useNavigate()
    const { showToast } = useCustomToast();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [passwordShow, setPasswordShow] = useState<boolean>(false)
    const [confPasswordShow, setConfPasswordShow] = useState<boolean>(false)
    const { showAlert, alertStatus, alertMessage, errorDetail, setAlert, clearAlert } = useMessageBox();
    const { isError: isTokenError, isLoading: isTokenValid } = useIsValidTokenQuery({ token })

    const defaultValues = {
        new_password: "",
        confirm_new_password: ""
    }
    const {
        handleSubmit,
        formState: { errors },
        control,
        reset
    } = useForm<Inputs>({ defaultValues, resolver: yupResolver(baseValidationSchema) })
    const [resetPassword] = useResetPasswordMutation()
    const onSubmit = async (data: Inputs) => {
        clearAlert()
        try {
            const payload = {
                ...data,
                token: token
            }
            await resetPassword(payload).unwrap()

            showToast({
                status: 'success',
                description: 'Your password has been reset successfully',
            });
            navigate('auth/login')
        } catch (error) {
            const err = error as FetchBaseQueryError;
            const errString = Object.entries(err?.data as object).map(([key, value]) => {
                return value.message
            }).join('')
            setAlert("error", errString)
        } finally {
            reset()
            setPasswordShow(false)
            setConfPasswordShow(false)
        }
    }

    useEffect(() => {
        if (!token) {
            navigate("auth/login")
        }
    }, [token])

    useEffect(() => {
        if (isTokenError) {
            setAlert("error", 'Invalid token detected. Please reinitiate the process.');
        }
    }, [isTokenError]);
    return (
        !isTokenValid &&
        <FocusView >
            <VStack width="full" gap={6}>
                <MessageBox
                    show={showAlert}
                    status={alertStatus}
                    message={alertMessage}
                    error={errorDetail}
                />
                {
                    !isTokenError &&
                    <>
                        <DetailHeading title='Change your password' />
                        <Box w="full">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <VStack width="full" gap={6}>
                                    <FormControl isInvalid={!!errors.new_password}>
                                        <FormLabel>New Password</FormLabel>
                                        <Controller
                                            control={control}
                                            name="new_password"
                                            rules={{ required: 'This field is required' }}
                                            render={({ field }) => (
                                                <InputGroup>
                                                    <Input
                                                        {...field}
                                                        type={passwordShow ? 'text' : 'password'}
                                                        variant="filled"
                                                        placeholder="Type your password"
                                                        onChange={(e) => field.onChange(e.target.value.trim())} // Trim value on change
                                                    />
                                                    <InputRightElement width='4.5rem'>
                                                        {
                                                            passwordShow ?
                                                                <Icon as={VscEyeClosed} onClick={() => setPasswordShow(!passwordShow)} boxSize={6} cursor="pointer" />
                                                                :
                                                                <Icon as={VscEye} onClick={() => setPasswordShow(!passwordShow)} boxSize={6} cursor="pointer" />
                                                        }
                                                    </InputRightElement>
                                                </InputGroup>
                                            )}
                                        />
                                        <FormErrorMessage>{errors.new_password?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.confirm_new_password}>
                                        <FormLabel>Confirm New Password</FormLabel>
                                        <Controller
                                            control={control}
                                            name="confirm_new_password"
                                            rules={{ required: 'This field is required' }}
                                            render={({ field }) => (
                                                <InputGroup>
                                                    <Input
                                                        {...field}
                                                        type={confPasswordShow ? 'text' : 'password'}
                                                        variant="filled"
                                                        placeholder="Type your password"
                                                        onChange={(e) => field.onChange(e.target.value.trim())} // Trim value on change
                                                    />
                                                    <InputRightElement width='4.5rem'>
                                                        {
                                                            confPasswordShow ?
                                                                <Icon as={VscEyeClosed} onClick={() => setConfPasswordShow(!confPasswordShow)} boxSize={6} cursor="pointer" />
                                                                :
                                                                <Icon as={VscEye} onClick={() => setConfPasswordShow(!confPasswordShow)} boxSize={6} cursor="pointer" />
                                                        }
                                                    </InputRightElement>
                                                </InputGroup>
                                            )}
                                        />
                                        <FormErrorMessage>{errors.confirm_new_password?.message}</FormErrorMessage>
                                    </FormControl>
                                    {/* <SolidButton title='Change password' w={"240px"} type='submit' /> */}
                                    <CustomButton title='Change password' type='submit' />
                                </VStack>
                            </form>
                        </Box>
                    </>
                }
            </VStack>
        </FocusView >
    )
}