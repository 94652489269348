import { Divider } from '@chakra-ui/react';
import { ConsignorForm } from '../terminals/ConsignorForm';
import { ConsigneeForm } from '../terminals/ConsigneeForm';

export function LocationsForm() {
    return (
        <>
            <ConsignorForm title="Consignor / From" />
            <Divider orientation='horizontal' borderColor='blue.200' my={4} />
            <ConsigneeForm title="Consignee / To" />
        </>
    )
}