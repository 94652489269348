import { useMemo, useState } from "react";
import { ActivityTrait } from "./ActivityTrait";
import { useAppSelector, useConfigCheck } from "../../app/hooks";
import { ChargesForm } from "../charges/ChargesForm";
import { PanelWrapper } from "../../components/PanelWrapper";
import { ModalForm } from "../../components/forms/ModalForm";
import { selectTicketTimeEntries } from "../tickets/ticketsSlice";
import { selectAllActivities } from "../activities/activitySlice";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { CustomButton } from "../../components/forms/CustomButton";
import { Draft, selectDraftTimeEntries } from "../draft/draftSlice";
import { ChargeWrapperProps } from "../../components/ChargeDetailWrapper";
import { formatMinutesAsHours, getGroupedDraftTimeEntry, getGroupedTicketTimeEntry } from "../../app/utils";
import { Box, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useDisclosure, useMediaQuery } from "@chakra-ui/react";

export interface TimeEntriesAggregatedProps extends ChargeWrapperProps {
  dataresource?: TicketResource | Draft | null
  handlesavecharge?(data: any): void
}

export function TimeEntriesAggregated({
  dataresource, resourceId, isdraft, handlesavecharge
}: TimeEntriesAggregatedProps) {
  const { isOnline } = useConfigCheck()
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
  const modalSize = isLargerThanMobile ? "lg" : "full"

  const [defaultValues, setDefaultValues] = useState(null)
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure()

  //check whether entry is in charge already or not
  const isInCharge = (activity: string | number) => {
    if (isdraft && dataresource && 'charges' in dataresource) {
      const act = getActivityLabel(Number(activity))
      return dataresource?.charges?.some((item) =>
        item.description === act
      )
    } else if (dataresource && 'chargedetails' in dataresource) {
      return dataresource.chargedetails.some((item) => item.description === activity);
    }
    return false;
  };
  //get activity label
  const Activities = useAppSelector(selectAllActivities)
  const getActivityLabel = (activity: number) => {
    return Activities.find((item) => item.id === activity)?.name
  }

  //Selector based on draft or ticket
  const selectResource = isdraft ?
    useAppSelector(selectDraftTimeEntries(Number(resourceId)))
    : useAppSelector(selectTicketTimeEntries(Number(resourceId)))

  //group same activity and get grand total for individual activity
  const grouped: { [activity: string]: number } = useMemo(() => isdraft ? getGroupedDraftTimeEntry(selectResource) : getGroupedTicketTimeEntry(selectResource), [selectResource]);
  const grandTotal = grouped && Object?.values(grouped)?.reduce(
    (acc, totalHours) => acc + totalHours,
    0,
  )

  return (
    <PanelWrapper title="Billable Time">
      {
        selectResource && selectResource?.length > 0 ?
          <Box
            className="custom-scrollbar"
            maxWidth="calc(100vw - 90px)"
            width="100%"
            overflowX="auto"
          >

            <Table variant="simple" >
              <Thead color={"#455360"} fontSize={"16px"} >
                <Tr sx={{ th: { borderBottom: "none", fontSize: "1rem", fontWeight: 600 } }}>
                  <Th>Activity</Th>
                  <Th>Hours</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody color={"#7B8389"} fontSize={"16px"} >
                {grouped && Object?.entries(grouped).map(([activity, totalHours]) => (
                  <Tr key={activity} h="72px">
                    <Td w="40%">
                      {isdraft ? <ActivityTrait choice={activity} /> : activity}
                    </Td>
                    <Td w="40%">{formatMinutesAsHours(totalHours)}</Td>
                    <Td w="20%" textAlign={"right"} borderBottom={"none"}>
                      {
                        !isInCharge(activity) &&
                        <>
                          <CustomButton title="$" w={"40px"}
                            isDisabled={(isOnline || isdraft) ? false : true}
                            onClick={() => {
                              const data = {
                                description: isdraft ? getActivityLabel(Number(activity)) : activity,
                                quantity: formatMinutesAsHours(totalHours),
                                unit: "Hours",
                                allow_surcharge: true,
                              }
                              setDefaultValues(data as any)
                              openModal()
                            }}
                          />
                        </>

                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
              <Tfoot color={"#455360"}>
                {
                  grouped && Object.entries(grouped).length > 0 &&
                  <Tr color={"#455360"} >
                    <Th fontSize={"1rem"} fontWeight={600} >
                      Total Hours:
                    </Th>
                    <Th fontSize={"1rem"} fontWeight={600}>{formatMinutesAsHours(grandTotal)}</Th>
                    <Th></Th>
                  </Tr>
                }
              </Tfoot>
            </Table >
          </Box >
          :
          <Text>{"There are no time entries"}</Text>
      }
      {/* Dialog for adding new charge entry */}
      <ModalForm
        isdraft={isdraft}
        title="Add New Entry"
        isOpen={isModalOpen}
        size={modalSize}
        onClose={closeModal}
        onCancel={closeModal}
        onSave={handlesavecharge}
        defaultValues={defaultValues}
      >
        <ChargesForm />
      </ModalForm>
    </PanelWrapper>
  )
}
