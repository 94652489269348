import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box, Flex, VStack, Spinner } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useGetCompConfigQuery } from "../../features/compConfig/compConfigApi";
import { loadSession, selectIsLoggedIn } from "../../features/session/sessionSlice";

export function PrintArea() {
  useGetCompConfigQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      if (!dispatch(loadSession())) {
        navigate("/auth/login")
      }
    }
  }, [isLoggedIn, dispatch, navigate])

  if (!isLoggedIn) {
    return <Spinner />
  }

  return (
    <Flex w="full" justifyContent="center">
      <Box w="full" margin={0}>
        <VStack align="start">
          <Outlet />
        </VStack>
      </Box>
    </Flex>
  )
}
