import { DetailHeading } from './DetailHeading';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { TicketResource } from '../pages/user/TicketDetailPage';
import { TicketDetailWrapperProps } from './TicketDetailWrapper';


interface TicketEditPageHeader extends TicketDetailWrapperProps {
    dataresource?: TicketResource
}

export function TicketEditPageHeader({
    dataresource
}: TicketEditPageHeader) {
    return (
        <>
            <HStack w={"full"} h={{ base: "60px", md: "80px" }} alignItems={"center"} justifyContent={"space-between"} mt={[0, 2, 5]} p={[2]} >
                <VStack gap={[2, 4]} alignItems={"stretch"}>
                    <DetailHeading title={`#${dataresource?.number}`} fontSize={{ base: "1.5rem", sm: "1.8rem", lg: "2rem" }} />
                    <Text fontWeight={400} color={"#737791"} >Edit Ticket Details</Text>
                </VStack>
            </HStack>
        </>
    )
}

