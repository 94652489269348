import { DetailTile } from "../../components/DetailTile"
import { PanelWrapper } from "../../components/PanelWrapper"
import { HStack, Stack, StackDivider, useMediaQuery, VStack } from "@chakra-ui/react"
import { WrapperProps } from "../../components/TicketDetailWrapper"
import { TicketResource } from "../../pages/user/TicketDetailPage"

interface UnloadInfoPanelProps extends WrapperProps {
    dataresource?: TicketResource
}
export function UnloadInfoPanel({
    dataresource: ticket
}: UnloadInfoPanelProps) {
    const [isBreakPoint] = useMediaQuery("(max-width: 700px)")

    return (
        <>
            <PanelWrapper title="Unload">
                <VStack align="stretch" gap={5} p={2} divider={<StackDivider />}>
                    <Stack direction={isBreakPoint ? "column" : "row"} w={"full"} alignItems="flex-start" gap={isBreakPoint ? 3 : "60px"} divider={<StackDivider />}>
                        <DetailTile flexBasis={"20%"} title="Actual Volume" detail={ticket?.actual_volume} />
                        <DetailTile flex={1} title="Description of Work" detail={ticket?.work_description} />
                    </Stack>
                </VStack>
            </PanelWrapper>
        </>
    )
}

