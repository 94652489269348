import { ProductsForm } from './ProductsForm';
import { DraftProductsValues } from './draftSlice';
import { ModalForm } from '../../components/forms/ModalForm';
import { selectProductById } from '../products/productsSlice';
import { KnownIcon } from '../../components/widgets/KnownIcon';
import { InputStreak } from '../../components/forms/InputStreak';
import { CustomButton } from '../../components/forms/CustomButton';
import { ReadonlyField } from '../../components/forms/fields/ReadonlyField';
import { Box, IconButton, StackDivider, useDisclosure, VStack } from '@chakra-ui/react';
import { ReadOnlyBooleanField } from '../../components/forms/fields/ReadonlyBooleanField';

interface ProductFormProps {
    products: DraftProductsValues[]
    onRemove(index: number): void
    onSave(data: any): void
}

export function ProductForm({
    products,
    onRemove,
    onSave
}: ProductFormProps) {
    const { isOpen, onOpen, onClose, } = useDisclosure()
    return (
        <>
            <VStack w="full" alignItems="flex-start" gap={4} p={[0, 2, 4]} divider={<StackDivider />}>
                {
                    products?.length > 0 ?
                        (products?.map((entry: DraftProductsValues, index: number) => (
                            <VStack w="full" alignItems="flex-start" key={index} >
                                <InputStreak>
                                    <ReadonlyField label="Shipping Name" traitId={entry?.product} traitGetter={selectProductById} />
                                    <ReadonlyField label="Subsidiary Class" value={entry?.subsidiary_class || '--'} />
                                </InputStreak>
                                <InputStreak>
                                    <ReadonlyField label="Quantity" value={entry?.quantity || '--'} />
                                    <ReadonlyField label="Packages" value={entry?.packages || '--'} />
                                    <ReadOnlyBooleanField label="Toxic" isChecked={entry?.toxic_by_inhalation || false} />
                                </InputStreak >
                                <Box w="full" textAlign="right">
                                    <IconButton
                                        aria-label="Remove"
                                        icon={<KnownIcon name="delete" boxSize="24px" cursor="pointer" />}
                                        onClick={() => onRemove(index)}
                                        backgroundColor="transparent"
                                        _hover={{ backgroundColor: "transparent" }}
                                    />
                                </Box>
                            </VStack>)

                        )) : (
                            <Box w="full" fontSize="14px" color="#455360" bgColor={"#fff"} borderRadius={"8px"} p={3}>
                                Add a Product
                            </Box>
                        )}
                <CustomButton title="Add New Entry" variant='outline' onClick={onOpen} />
            </VStack >
            <ModalForm
                title={`Add New Entry`}
                isOpen={isOpen}
                size={["full", "lg"]}
                onClose={onClose}
                onCancel={onClose}
                onSave={onSave}
            >
                <ProductsForm />
            </ModalForm>
        </>
    )
}
