import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  UnorderedList,
  ListItem,
  BoxProps,
} from "@chakra-ui/react"

interface ErrorBoxProps extends BoxProps {
  message?: string | string[]
  error?: any
  show: boolean
}

export function ErrorBox({
  show,
  message,
  error,
  ...rest
}: ErrorBoxProps) {
  return show ? (
    <Alert status="error" rounded="md" mt={5} mx={'auto'} w={"95%"} {...rest}>
      <AlertIcon />
      <AlertTitle>

        {!Array.isArray(message) ? message :
          <UnorderedList >
            {message.map((item) => (
              <ListItem>{item}</ListItem>
            ))}
          </UnorderedList>
        }
      </AlertTitle>
      <AlertDescription>{error?.data?.detail}</AlertDescription>
    </Alert>
  ) : null
}
