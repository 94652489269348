import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store, persistor } from "./app/store";
import { ProtectedRoute } from "./ProtectedRoute";
import { AuthArea } from "./layout/areas/AuthArea";
import { LoginPage } from "./pages/auth/LoginPage";
import { UserArea } from "./layout/areas/UserArea";
import { DraftPage } from "./pages/user/DraftPage";
import { LogoutPage } from "./pages/auth/LogoutPage";
import { PrintArea } from "./layout/areas/PrintArea";
import { PrintDraft } from "./pages/print/PrintDraft";
import { ProfilePage } from "./pages/user/ProfilePage";
import { ChargesPage } from "./pages/user/ChargesPage";
import { TicketsPage } from "./pages/user/TicketsPage";
import { TrackingPage } from "./pages/user/TrackingPage";
import { ConfigProvider } from "./contaxt/ConfigProvider";
import { TicketEditPage } from "./pages/user/TicketEditPage";
import { DriverHomePage } from "./pages/user/DriverHomePage";
import { PersistGate } from "redux-persist/integration/react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { TicketDetailPage } from "./pages/user/TicketDetailPage";
import { ResetPasswordPage } from "./pages/user/ResetPasswordPage";
import { StampTicketDetail } from "./pages/stamp/StampTicketDetail";
import { TicketDetailPrint } from "./pages/print/TicketDetailPrint";
import { ForgotPasswordPage } from "./pages/user/ForgotPasswordPage";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { ApproveSubmittedTimeEntries } from "./pages/user/ApproveSubmittedTimeEntries";

import "react-datepicker/dist/react-datepicker.css";
import 'react-responsive-pagination/themes/bootstrap.css';

const router = createBrowserRouter([
  {
    path: "/user",
    element: <UserArea />,
    children: [
      {
        index: true,
        element: <Navigate to="home" />
      },
      {
        path: "home",
        element: <DriverHomePage />
      },

      {
        path: "profile",
        element: <ProfilePage />,
      },
      {
        path: "draft/:id?",
        element: <DraftPage />,
      },
      {
        path: "/user/draft/:id/charges",
        element: <ChargesPage />,
      },
      {
        path: "tracking",
        element: <TrackingPage />,
      },
      {
        path: "tickets",
        element: <TicketsPage />,
      },
      {
        path: "tickets/:id",
        element: <TicketDetailPage />,
      },
      {
        path: "tickets/:id/edit",
        element: <TicketEditPage />,
      },
      {
        path: "tickets/:id/charges",
        element: <ProtectedRoute Component={ChargesPage} roles={['driver-lead', 'driver']} />,
      },
      {
        path: "stamp/tickets/:id",
        element: <StampTicketDetail />,
      },

      {
        path: "approve-time-entries",
        element: <ProtectedRoute Component={ApproveSubmittedTimeEntries} roles={['driver-lead']} />,
      },
    ],
  },
  {
    path: "/print",
    element: <PrintArea />,
    children: [
      {
        path: "tickets/:ticketId/",
        element: <TicketDetailPrint />,
      },
      {
        path: "draft/:id",
        element: <PrintDraft />,
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthArea />,
    children: [
      { index: true, element: <Navigate to="login" /> },
      { path: "login", element: <LoginPage /> },
      { path: "logout", element: <LogoutPage /> },
      { path: "forgot-password", element: <ForgotPasswordPage /> },
      { path: "reset-password/:token?", element: <ResetPasswordPage /> },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/user/home" />,
  }
])



const customTheme = extendTheme({
  fonts: {
    body: `Inter ,sans-serif`,
  }
})

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={customTheme}>
          <ConfigProvider>
            <RouterProvider router={router} />
          </ConfigProvider>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
