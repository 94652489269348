import { useEffect, useState } from "react";
import ActivitySelect from "./ActivitySelect";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "../../app/hooks";
import { selectActivityById } from "../activities/activitySlice";
import { DatetimeField } from "../../components/forms/fields/DatetimeField";
import { TextareaField } from "../../components/forms/fields/TextareaField";

interface TimeEntryFormProps {
}

export function TimeEntryForm({
}: TimeEntryFormProps) {

  const { watch } = useFormContext();

  const startTime = watch("start")
  const finishTime = watch("finish")

  const [finishTimeError, setFinishTimeError] = useState("");
  const selectedActivity = useAppSelector(state => selectActivityById(state, watch("activity")));

  const validateTime = (start: string, finish: string) => {
    if (start > finish) {
      setFinishTimeError(
        "The finish time cannot be earlier than the start time.",
      )
    } else {
      setFinishTimeError("")
    }
  }

  useEffect(() => {
    if (startTime && finishTime) {
      validateTime(startTime, finishTime)
    }
  }, [startTime, finishTime])

  return (
    <>
      <ActivitySelect label="Activity" name="activity" isRequired={true} />
      <DatetimeField label="Start" name="start" isRequired={true} />
      <DatetimeField label="Finish" name="finish" error={finishTimeError} isRequired={true} />
      {selectedActivity?.description_field && (
        <TextareaField label="Description" name="description" />
      )}
    </>
  )
}