import { FieldProps } from "../../../forms";
import { useFormContext } from "react-hook-form";
import { KnownIcon } from "../../widgets/KnownIcon";
import { FormControl, FormLabel, FormHelperText, FormErrorMessage, InputGroup, Input, InputLeftAddon, VStack, } from "@chakra-ui/react";

interface InputFieldProps extends FieldProps {
}

export function InputField({
  label,
  name,
  registerOptions,
  helper,
  readOnly,
  placeholder,
  icon
}: InputFieldProps) {
  const { register, formState } = useFormContext()
  return (
    <FormControl isInvalid={!!formState.errors?.[name]}>
      <VStack w="full" gap="10px" alignItems="flex-start">
        <FormLabel htmlFor={name} color="#455360" >{label}</FormLabel>
        <InputGroup>
          {
            icon &&
            <InputLeftAddon h={"48px"} border="none">
              <KnownIcon name={icon} />
            </InputLeftAddon>
          }
          <Input
            id={name} // Ensure the ID matches the label's htmlFor
            {...register(name, registerOptions)}
            h={"48px"}
            borderRadius={"4px"}
            variant="filled"
            color={"#0E1628"}
            placeholder={placeholder}
            readOnly={readOnly}
          />
        </InputGroup>
        {helper && <FormHelperText>{helper}</FormHelperText>}
        <FormErrorMessage>{formState.errors?.[name]?.message?.toString()}</FormErrorMessage>
      </VStack>
    </FormControl>
  )
}
