import Select from 'react-select';
import { FieldProps } from "../../../forms";
import { useFormContext } from "react-hook-form";
import { FormControl, FormLabel, FormHelperText, FormErrorMessage, BoxProps } from "@chakra-ui/react"
interface SelectFieldProps extends FieldProps, BoxProps {
  options: { value: number | string; label: string }[]
  isRequired?: boolean
  setIsValue?(data: number | null): void
  isClearable?: boolean
}

export function SelectField({
  label,
  name,
  registerOptions,
  helper,
  options,
  isRequired,
  setIsValue,
  isClearable = false,
  ...rest
}: SelectFieldProps) {
  const { register, formState, setValue, watch, clearErrors } = useFormContext()
  const selectedValue = watch(name); // Track current value

  const defaultRegisterOptions = {
    required: isRequired ? "Please pick one" : false,
    valueAsNumber: options?.some((option) => typeof option.value === "number")
  }
  const mergedRegisterOptions = {
    ...defaultRegisterOptions,
    ...registerOptions,
  }

  // Format selected value for react-select
  const currentOption = options.find(
    (option) => option.value === selectedValue
  );

  const handleChange = (selectedOption: { value: number | string; label: string } | null) => {
    setIsValue?.(selectedOption ? Number(selectedOption.value) : null);
    setValue(name, selectedOption ? selectedOption.value : null);
    clearErrors(name)
  };

  return (
    <FormControl isInvalid={!!formState.errors?.[name]} isRequired={isRequired} {...rest}>
      <FormLabel fontWeight={500} color={"#455360"}>{label}</FormLabel>
      <Select
        {...register(name, mergedRegisterOptions)}
        value={currentOption}
        placeholder="Pick One"
        options={options}
        onChange={handleChange}
        isClearable={isClearable}
        styles={{
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: "#7B8389",
          }),
          container: (base) => ({
            ...base,
            width: "100%",
            borderRadius: "4px",
            border: formState.errors?.[name] ? "1px solid #E53E3E" : 'none'
          }),
          control: (base) => ({
            ...base,
            height: "48px",
            border: "none",
            backgroundColor: "#ECF2F7",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor:
              state.isSelected
                ? "#EBEEFF"
                : state.isFocused
                  ? "#D3D8FF" // Adjust this for hover effect contrast
                  : provided.backgroundColor,
            "&:active": {
              backgroundColor: "#EBEEFF",
            },
            color: state.isSelected ? "#0E1628" : "#455360",
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: "14px",
            color: "#0E1628",
          }),
        }}
      />

      {/* </InputGroup> */}
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>
        {formState.errors?.[name]?.message?.toString()}
      </FormErrorMessage>
    </FormControl>
  )
}
