import { FieldInfoProps } from "../../forms";
import { Stack, Text, useMediaQuery } from "@chakra-ui/react";

interface LsdInputStreakProps extends FieldInfoProps {
    children: React.ReactElement[] | React.ReactElement
}

export function LsdInputStreak({ label, children }: LsdInputStreakProps) {
    const [isSmallScreen] = useMediaQuery("(max-width: 570px)")

    return (
        <Stack w="full" gap={"10px"}  >
            <Text fontWeight={500} fontSize={"18px"} color={"#05004E"}>
                {label}
            </Text>
            <Stack align="left" gap={isSmallScreen ? 4 : 8} w="full" direction={isSmallScreen ? "column" : "row"} >
                {children}
            </Stack>
        </Stack>
    )
}
