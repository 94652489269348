import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  Input,
} from "@chakra-ui/react"
import { useFormContext } from "react-hook-form"
import { RegisterOptions } from "react-hook-form"

interface LsdFieldProps {
  label?: string
  name: string
  registerOptions?: RegisterOptions
  helper?: string
}

export function LsdField({
  label,
  name,
  registerOptions,
  helper,
}: LsdFieldProps) {
  const { register, setValue, formState } = useFormContext()

  const transformToLsdFormat = (value: string) => {
    if (value.trim().length > 0) {
      const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase()

      // Formatting to LSD pattern ##-##-##-##-##
      const formattedValue = `${alphanumericValue.slice(
        0,
        2,
      )}-${alphanumericValue.slice(2, 4)}-${alphanumericValue.slice(
        4,
        6,
      )}-${alphanumericValue.slice(6, 8)}-${alphanumericValue.slice(8, 10)}`

      // Set the formatted value in the field
      setValue(name, formattedValue)
    } else {
      setValue(name, "")
    }
  }

  const validateLsd = (value: string | undefined) => {
    if (value) {
      const isValidLsd = value && /^(?:\d{2}|NE|NW|SE|SW)-\d{2}-\d{2}-\d{2}-W\d$/.test(
        value,
      )
      if (isValidLsd) {
        return undefined // Valid LSD value
      } else {
        return "Invalid LSD format. Please use ##-##-##-##-w#"
      }
    }

  }

  // Default registration options
  const defaultRegisterOptions = {
    // required: true,
    validate: {
      validLsd: validateLsd,
    },
  }

  // Merge provided registerOptions with default options
  const mergedRegisterOptions = {
    ...defaultRegisterOptions,
    ...registerOptions,
  }

  return (
    <FormControl isInvalid={!!formState.errors?.[name]} >
      <FormLabel htmlFor={name} color="#455360">{label}</FormLabel>
      <InputGroup>
        <Input
          id={name} // Ensure the ID matches the label's htmlFor
          {...register(name, mergedRegisterOptions)}
          placeholder="##-##-##-##-W#"
          onBlur={(e) => transformToLsdFormat(e.target.value)}
          h="48px" borderRadius="4px" variant="filled" color={"#0E1628"}
        />
      </InputGroup>
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>
        {formState.errors?.[name]?.message?.toString()}
      </FormErrorMessage>
    </FormControl>
  )
}
