import { Box, BoxProps, Heading, HeadingProps, VStack } from "@chakra-ui/react"
import { DetailHeading } from "./DetailHeading"

interface StreamProps extends HeadingProps {
    title?: string
    children: React.ReactNode | React.ReactNode[]
}

export function Stream({ title, children, ...headingProps }: StreamProps) {
    return (
        <VStack w="full" align="left" gap={7}>
            <DetailHeading title={title || ""} />
            <VStack w="full" gap={7} align="left">
                {children}
            </VStack>
        </VStack>
    )
}
