import { KnownIcon } from './widgets/KnownIcon';
import { Box, Heading, HStack, Text } from '@chakra-ui/react';

interface CardViewProps {
    title: string
    helper: string
    onClick?: () => void;
}

export function CardView({ title, helper, onClick }: CardViewProps) {
    return (
        <Box bgColor={"#EBEEFF"} w={"full"} h={"120px"} p={8} borderRadius={"6px"} onClick={onClick} cursor={"pointer"}>
            <HStack alignItems={"center"} justifyContent={"space-between"}>
                <Box>
                    <Heading fontSize={"20px"} fontWeight={600} color={"#0E1628"} lineHeight={"1.5rem"} mb={2}>{title}</Heading>
                    <Text fontWeight={400} color={"#666E82"}>{helper}</Text>
                </Box>
                <Box>
                    <KnownIcon name='link' boxSize={"1.5rem"} />
                    {/* <OutlineButton title="Add" handleClick={onClick} /> */}
                </Box>
            </HStack>
        </Box>
    )
}
