import { FieldProps } from "../../forms";
import { useAppSelector } from "../../app/hooks";
import { selectAllEquipments } from "./equipmentsSlice";
import { SelectField } from "../../components/forms/fields/SelectField";

interface EquipmentSelectProps extends FieldProps {
  setIsValue(data: number | null): void
}

export function EquipmentSelect(props: EquipmentSelectProps) {
  const equipments = useAppSelector(selectAllEquipments)
  const options = equipments.map((equipment) => ({
    value: equipment.id,
    label: `${equipment.unit} (${equipment?.category?.name})`,
  }))
  return <SelectField w={{ base: "70%", md: "50%" }} {...props} options={options} setIsValue={props.setIsValue} />
}
