import { Box } from "@chakra-ui/react";
import { forwardRef, useState } from "react";
import { Container } from "../../components/Container";
import AdditionalItemsForm from "./AdditionalItemsForm";
import { PanelWrapper } from "../../components/PanelWrapper";
import { OverlayLoader } from "../../components/OverlayLoader";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { saveDraftServices, selectDraftServices } from "./draftSlice";
import { removeServiceDetail, selectTicketServices, updateTicketServiceDetails } from "../tickets/ticketsSlice";

interface AdditionalItemsPanelProps extends WrapperProps {
    dataresource?: TicketResource
}

export const AdditionalItemsPanel = forwardRef<HTMLDivElement, AdditionalItemsPanelProps>(
    function AdditionalItemsPanel({ dataresource, resourceid, isdraft }: AdditionalItemsPanelProps, ref) {
        const dispatch = useAppDispatch()
        const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

        //Selector based on draft or ticket
        const ticketServices = isdraft ?
            useAppSelector(selectDraftServices(Number(resourceid)))
            : useAppSelector(selectTicketServices(Number(resourceid))) || []

        //Save handler based on draft or ticket
        const handleServiceSave = async (data: any) => {
            setIsSubmitting(true)
            const action = isdraft
                ? saveDraftServices({ id: Number(resourceid), updateDraftServices: [...ticketServices, data] })
                : updateTicketServiceDetails({ id: Number(resourceid), ...data });

            await dispatch(action);
            setIsSubmitting(false)
        };

        // Remove handler based on draft or ticket
        const handleServiceRemove = async (index: number) => {
            if (isdraft) {
                const updateDraftServices = ticketServices.filter((_, i) => i !== index);
                dispatch(saveDraftServices({ id: Number(resourceid), updateDraftServices }))
            } else {
                setIsSubmitting(true)
                const ticketServiceId = dataresource?.servicedetails && dataresource?.servicedetails[index]?.ticket_service_id
                ticketServiceId && await dispatch(removeServiceDetail({ id: Number(resourceid), ticket_service_id: Number(ticketServiceId) }))
                setIsSubmitting(false)
            }
        }

        return (
            <Box w="full" ref={ref}>
                {isSubmitting && <OverlayLoader />}
                <Container mb={0}>
                    <PanelWrapper title="Additional Billable Items">
                        <AdditionalItemsForm services={ticketServices} onSave={handleServiceSave} onRemove={handleServiceRemove} />
                    </PanelWrapper>
                </Container>
            </Box >
        )
    })
