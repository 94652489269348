import { customAlphabet } from 'nanoid';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../app/hooks';
import { useLocation, useParams } from 'react-router-dom';
import { selectTicketById } from '../features/tickets/ticketsSlice';
import { TicketResource } from '../pages/user/TicketDetailPage';
import { bindDraftNumber, Draft, selectDraft } from '../features/draft/draftSlice';
import { Children, ReactElement, ReactNode, cloneElement, isValidElement, useEffect, useState } from 'react';

export interface TicketDetailWrapperProps {
    children?: ReactNode | ReactNode[]
}

export interface WrapperProps {
    dataresource?: TicketResource | Draft | null;
    isdraft?: boolean
    resourceid?: string | number
    draftuniqueid?: number | null
}


export const TicketDetailWrapper = ({ children }: TicketDetailWrapperProps) => {
    const dispatch = useDispatch()
    const { id } = useParams<{ id: string }>();
    const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0

    //check whether it is draft page or not
    const pathname = useLocation().pathname
    const isdraft = pathname.includes("user/draft");

    //get resource data based on draft or ticket
    const resourceid = isdraft ? draftIndex : id;
    const dataresource = resourceid !== null && resourceid !== undefined ? (isdraft
        ? useAppSelector(selectDraft(Number(resourceid)))
        : useAppSelector((state) => selectTicketById(state, Number(resourceid))))
        : null;

    //below code is for draft only 
    const { hasContent = false, number = null } = dataresource as Draft || {}
    const nanoid = customAlphabet("1234567890", 6)

    const [draftuniqueid, setdraftuniqueid] = useState<number | null>(number ? Number(number) : null)
    /*adding unique id to draft , first check for draft number in draft state , 
     * if there is content and there is no unique number then dispatch bindDraftNumber action
     */
    useEffect(() => {
        if (isdraft && !number) {
            let uniqueId;
            do {
                uniqueId = parseInt(nanoid(), 10);
                if (uniqueId > 250000) {
                    setdraftuniqueid(uniqueId)
                }
            } while (uniqueId >= 250000);
        }
    }, [number, isdraft]);

    useEffect(() => {
        if (hasContent && isdraft) dispatch(bindDraftNumber({ id: draftIndex, draftuniqueid }));
    }, [hasContent, draftIndex, dispatch, draftuniqueid, isdraft])

    return (
        <>
            {Children.map(children, (child) => {
                if (isValidElement(child)) {
                    return cloneElement(child as ReactElement<WrapperProps>, { resourceid, dataresource, isdraft, draftuniqueid });
                }
                return child;
            })}
        </>
    );
};
