import { TerminalAutocomplete } from "./TerminalAutocomplete"
import { LocationAutocomplete } from "./LocationAutocomplete"
import { LsdField } from "../../components/forms/fields/LsdField"
import { InputStreak } from "../../components/forms/InputStreak"
import { LsdPrefixField } from "../../components/forms/fields/LsdPrefixField"
import { useFormContext } from "react-hook-form"
import { Heading, Text, VStack } from "@chakra-ui/react"
import { useLocation } from "react-router-dom"
import { LsdInputStreak } from "../../components/forms/LsdInputStreak"

interface ConsigneeFormProps {
  title?: string
}

export function ConsigneeForm({ title }: ConsigneeFormProps) {
  const { watch } = useFormContext()
  const terminalId = watch("consignee.terminal")

  return (
    <>
      <VStack w="full" gap={4} p={[0, 2, 4]}>
        <InputStreak label={title}>
          <TerminalAutocomplete label="Company Name" name={"consignee.terminal"} placeholder="Pick the consignee who is receiving the shipment" />
        </InputStreak>
        <LsdInputStreak label="LSD/Well/Battery">
          <LsdPrefixField label="LSD Prefix" name={"consignee.lsdprefix"} />
          <LsdField label="Well or Battery LSD location" name={"consignee.lsd"} />
        </LsdInputStreak>
        <LocationAutocomplete
          label="Destination Point"
          name={"consignee.location"}
          terminalId={terminalId}
          placeholder="Dropp off location"
        />
      </VStack>
    </>
  )
}
