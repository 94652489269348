import { RootState } from "../../app/store";
import { timeEntriesApi } from "./timeEntriesApi";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

export interface LocalEntry {
  activity: number
  date: string
  start: string
  finish: string
  ticketDraft?: number
}

interface LocalEntriesState {
  entries: LocalEntry[]
}

const initialState: LocalEntriesState = {
  entries: [],
}

const localEntriesSlice = createSlice({
  name: "localEntries",
  initialState,
  reducers: {
    addLocalEntry(state, action: PayloadAction<LocalEntry>) {
      state.entries.push(action.payload)
    },
    removeLocalEntry(state, action: PayloadAction<number>) {
      state.entries.splice(action.payload, 1)
    },
    cleanLocalEntries(state, action) {
      const { draftNumber } = action.payload
      const finalEntries = state.entries.filter(
        (item) => item.ticketDraft !== draftNumber,
      )
      return {
        ...state,
        entries: finalEntries,
      }
    },
    clearAllLocalEntries(state) {
      return {
        ...state,
        entries: [],
      }
    },
    removeLocalEntriesbyActivity(state, action) {
      return {
        ...state,
        entries: action.payload,
      }
    }
  },
})

export const selectLocalEntries = (state: RootState) =>
  state.localEntries.entries

interface BindLocalEntrieProps {
  ticketId: number
  draftNumber: number | null
}

export const bindLocalEntries = createAsyncThunk(
  "localEntries/bindLocalEntries",
  async (
    { ticketId, draftNumber }: BindLocalEntrieProps,
    { dispatch, getState },
  ) => {
    const localEntriesState = (getState() as RootState).localEntries

    //filter local entries bind to this draft number
    const finalEntries = localEntriesState.entries.filter(
      (item) => item.ticketDraft === draftNumber,
    )

    finalEntries.forEach(async (entry) => {
      const entryPayload = {
        ticket: ticketId,
        activity: entry.activity,
        start: entry.start,
        finish: entry.finish,
      }
      console.log("Creating final local entry", entry, entryPayload)
      const newEntry = await dispatch(
        timeEntriesApi.endpoints.createTimeEntry.initiate(entryPayload),
      ).unwrap()
      console.log("Created TimeEntry", newEntry)
    })
  },
)

export const { addLocalEntry, removeLocalEntry, cleanLocalEntries, clearAllLocalEntries, removeLocalEntriesbyActivity } =
  localEntriesSlice.actions

export default localEntriesSlice.reducer
