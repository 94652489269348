import { InputField } from "../../components/forms/fields/InputField"
import { NumberField } from "../../components/forms/fields/NumberField"

interface ChargesFormProps {
  isSurCharge?: boolean
}

export function ChargesForm({ isSurCharge }: ChargesFormProps) {
  return (
    <>
      <InputField label="Description" name="description" readOnly={isSurCharge ? true : false} placeholder="Write Name" />
      {/*make unit and quentity field read only if model is open from charges panel in charges page */}
      <NumberField label="Quantity" name="quantity" placeholder="Write Quantity " />
      <InputField label="Unit" name="unit" readOnly={isSurCharge ? true : false} placeholder="Write Unit " />
      <NumberField label="Rate" name="rate" readOnly={isSurCharge ? true : false} placeholder="Write rate " />
    </>
  )
}
