import { KnownIcon } from "./widgets/KnownIcon";
import { DetailHeading } from "./DetailHeading";
import { CustomButton } from "./forms/CustomButton";
import { components } from "../../src/app/api/schema";
import { Link as RouterLink } from "react-router-dom";
import { useAppSelector, useConfigCheck } from "../app/hooks";
import { selectTicketById } from "../features/tickets/ticketsSlice";
import { TicketResource } from "../pages/user/TicketDetailPage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { usePartialUpdateTicketMutation } from "../features/tickets/ticketsApi";
import { Divider, Flex, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, useMediaQuery, VStack } from "@chakra-ui/react";

export type ticketStatusEnum = components["schemas"]["StatusEnum"]

interface ChargePageHeaderProps {
    title: string
}

export function ChargePageHeader({
    title,
}: ChargePageHeaderProps) {
    const { id } = useParams();
    const navigate = useNavigate()
    const { isOnline, userProfile } = useConfigCheck()
    const draftIndex: number = id !== undefined ? parseInt(id) - 1 : 0
    const pathname = useLocation().pathname
    const isdraft = pathname.includes("user/draft");
    const resourceId = isdraft ? draftIndex : id;
    const [isLargerDevice] = useMediaQuery("(min-width: 992px)")
    const [partialUpdateTicket, { isLoading: isUpdating }] = usePartialUpdateTicketMutation()

    const ticketData = useAppSelector((state) => selectTicketById(state, Number(resourceId))) as TicketResource

    //print href based on draft or ticket
    const printWithChargehref = isdraft ? `/print/draft/${(Number(resourceId) || 0) + 1}?withCharge=${true}` : `/print/tickets/${resourceId}?withCharge=${true}`
    const printWithOutChargehref = isdraft ? `/print/draft/${(Number(resourceId) || 0) + 1}?withCharge=${false}` : `/print/tickets/${resourceId}?withCharge=${false}`


    //save handler 
    const handleSaveAndApprove = async () => {
        try {
            const body = {
                id: Number(id),
                status: ticketData?.digital_signed ? "stamped" : "approved",
            }
            await partialUpdateTicket(body)
            navigate(`/user/tickets/${id}`)
        } catch (error) {
            console.error("failed to approve ticket :", error);
        }
    }
    return (
        <>
            <HStack w={"full"} alignItems={"center"} justifyContent={"space-between"} borderRadius={"4px"} mt={[0, 2, 5]} p={[2]} >
                <VStack gap={[1, 2, 3]} alignItems={"flex-start"}>
                    <DetailHeading title={title} fontSize={{ base: "1.5rem", lg: "2rem" }} />
                    {isdraft && <Text fontWeight={400} color={"#737791"} fontSize={[10, 12, 16]}>This ticket has not yet been saved to the cloud</Text>}
                </VStack>
                {
                    isLargerDevice ?
                        <Flex gap={3}>
                            <CustomButton title="Print with" rightIcon={<KnownIcon name="dollar" size={5} />} href={printWithChargehref} isDisabled={(isOnline || isdraft) ? false : true} target={isdraft ? "_self" : "_blank"} />
                            <CustomButton title="Print without" rightIcon={<KnownIcon name="dollar" size={5} />} href={printWithChargehref} isDisabled={(isOnline || isdraft) ? false : true} target={isdraft ? "_self" : "_blank"} />
                            {userProfile?.role === "driver-lead" && (ticketData?.status === "submitted" || ticketData?.status === "unapproved") &&
                                <CustomButton title="Save and Approve" onClick={handleSaveAndApprove} isLoading={isUpdating} />
                            }
                        </Flex> :
                        <Menu>
                            <MenuButton
                                as={IconButton}
                                icon={<KnownIcon name='options' boxSize="24px" />}
                                aria-label='Options'
                                background="transparent"
                                isDisabled={(isOnline || isdraft) ? false : true}
                            />
                            <MenuList color={"#455360"}>
                                <MenuItem as={RouterLink} to={printWithChargehref} isDisabled={(isOnline || isdraft) ? false : true} target={isdraft ? "_self" : "_blank"}  >
                                    <HStack alignItems={'center'}>
                                        <Text>Print With</Text>
                                        <KnownIcon name="darkDollar" size={5} />
                                    </HStack>
                                </MenuItem>
                                <Divider bgColor={"#F0F0F0"} />
                                <MenuItem as={RouterLink} to={printWithOutChargehref} isDisabled={(isOnline || isdraft) ? false : true} target={isdraft ? "_self" : "_blank"} >
                                    <HStack alignItems={'center'}>
                                        <Text>Print Without</Text>
                                        <KnownIcon name="darkDollar" size={5} />
                                    </HStack>
                                </MenuItem>
                                {
                                    userProfile?.role === "driver-lead" && (ticketData?.status === "submitted" || ticketData?.status === "unapproved")
                                    &&
                                    <>
                                        <Divider bgColor={"#F0F0F0"} />
                                        <MenuItem onClick={handleSaveAndApprove} isDisabled={(isOnline || isdraft) ? false : true}>
                                            Save and Approve
                                        </MenuItem>
                                    </>
                                }
                            </MenuList>
                        </Menu>
                }
            </HStack >
        </>
    )
}
