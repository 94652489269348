import TruckLogo from "/images/Truck_Angled.png"
import { Box, Flex, Text, VStack } from '@chakra-ui/react'

interface PageBannerProps {
    title: string
    helper?: string
}

export function PageBanner({ title, helper }: PageBannerProps) {
    return (
        <Flex w="full" h={{ base: "100px", md: "200px" }} bgColor={"#EBEEFF"} bgImage={TruckLogo} bgSize={"contain"} bgRepeat={"no-repeat"} bgPosition={"right"} alignItems={"center"}>
            <VStack w={"90%"} maxW={"1440px"} mx={"auto"} p={2} gap={[0, 1, 3]} alignItems={"flex-start"} >
                <Text fontSize={{ base: "1.5rem", md: "2rem" }} lineHeight={"38.73px"} fontWeight={600}>
                    {title}
                </Text>
                <Text color="#737791" fontWeight={400} >
                    {helper}
                </Text>
            </VStack>
        </Flex>
    )
}
