import Select from 'react-select';
import { FieldProps } from '../../../forms';
import { useFormContext } from "react-hook-form";
import { SearchSuggestions } from "../../../features/customers/CustomerAutocomplete";
import { VStack, FormLabel, FormControl, FormHelperText, FormErrorMessage } from "@chakra-ui/react";

interface SearchDropdownFieldProps extends FieldProps {
    suggestions: SearchSuggestions[]
    onMatch?: (selectedSuggestion: SearchSuggestions) => void
}

export function SearchDropdownField({
    label,
    name,
    suggestions,
    helper,
    onMatch,
    registerOptions
}: SearchDropdownFieldProps) {
    const { formState: { errors }, setValue, register, trigger, watch } = useFormContext()
    const selectedValue = watch(name); // Track current value

    // Format selected value for react-select
    const currentOption = suggestions.find(
        (item) => item.value === selectedValue
    );

    const handleSelect = (selectedOption: SearchSuggestions | null) => {
        setValue(name, selectedOption ? selectedOption.value : null);
        trigger(name)
    };

    return (
        <FormControl isInvalid={errors[name] !== undefined} isRequired>
            <VStack w="full" gap="10px" alignItems="flex-start">
                <FormLabel color="#455360">{label}</FormLabel>
                <Select
                    className={errors[name]?.message ? "errorStyle" : ""}
                    {...register(name, registerOptions)}
                    placeholder="Select customer"
                    value={currentOption}
                    options={suggestions}
                    onChange={handleSelect}
                    isClearable
                    styles={{
                        placeholder: (baseStyles) => ({
                            ...baseStyles,
                            color: "#7B8389"
                        }),
                        container: (base, state) => ({
                            ...base,
                            width: "100%",
                            borderRadius: "4px"
                        }),
                        control: (base, state) => ({
                            ...base,
                            height: "48px",
                            border: "none",
                            backgroundColor: "#ECF2F7",

                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor:
                                state.isSelected
                                    ? "#EBEEFF" // Selected option background color
                                    : state.isFocused
                                        ? "#EBEEFF" // Hovered option background color
                                        : provided.backgroundColor,
                            "&:active": {
                                backgroundColor: "#EBEEFF", // Active background color should also match
                            },
                            color: "#455360"
                        }),
                        singleValue: (baseStyles) => ({
                            ...baseStyles,
                            fontSize: "14px",
                            color: "#0E1628"
                        }),
                    }}
                />
                {helper && <FormHelperText>{helper}</FormHelperText>}
                <FormErrorMessage>{errors[name]?.message?.toString()}</FormErrorMessage>
            </VStack>

        </FormControl >
    )
}
