import { KnownIcon } from '../../widgets/KnownIcon';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, InputGroup, InputLeftAddon, VStack } from '@chakra-ui/react';


interface InputFieldProps {
    label?: string
    name: string
    registerOptions?: RegisterOptions
    helper?: string
    readOnly?: boolean
}

export function EmailField({
    label,
    name,
    registerOptions,
    helper,
    readOnly,
}: InputFieldProps) {
    const { register, formState } = useFormContext()
    const validateEmail = (value: string | undefined) => {
        if (value) {
            const isValiEmail = value && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
            if (isValiEmail) {
                return undefined
            } else {
                return "Invalid Email format."
            }
        }
    }

    // Default registration options
    const defaultRegisterOptions = {
        validate: {
            validateEmail: validateEmail,
        },
    }

    // Merge provided registerOptions with default options
    const mergedRegisterOptions = {
        ...defaultRegisterOptions,
        ...registerOptions,
    }

    return (
        <FormControl isInvalid={!!formState.errors?.[name]}>
            <VStack w="full" gap="10px" alignItems="flex-start">
                <FormLabel htmlFor={name} color="#455360">{label}</FormLabel>
                <InputGroup>
                    <InputLeftAddon h={"48px"} border="none">
                        <KnownIcon name='at' />
                    </InputLeftAddon>
                    <Input
                        id={name} // Ensure the ID matches the label's htmlFor
                        h={"48px"}
                        variant="filled"
                        {...register(name, mergedRegisterOptions)}
                        placeholder="Write it here..."
                        readOnly={readOnly}
                        type='email'
                        color={"#0E1628"}
                    />
                </InputGroup>
                {helper && <FormHelperText>{helper}</FormHelperText>}
                <FormErrorMessage>{formState.errors?.[name]?.message?.toString()}</FormErrorMessage>
            </VStack>
        </FormControl>
    )
}

