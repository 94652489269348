import { useConfigCheck } from "../app/hooks";
import { TicketHeaderBox } from "./TicketHeaderBox";
import { CustomButton } from "./forms/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonGroup, useMediaQuery } from "@chakra-ui/react";
import { BreadCrumbs, BreadcrumbItemType } from "./BreadCrumbs";

type StampTicketHeaderProps = {
    isLoading: boolean
    ticketStatus: string;
    isDigitalSigned: boolean;
    breadCrumbItems: BreadcrumbItemType[];
    onOpen: () => void;
};

export function StampTicketHeader({ isLoading, breadCrumbItems, ticketStatus, isDigitalSigned, onOpen }: StampTicketHeaderProps) {
    const { id } = useParams()
    const navigate = useNavigate()
    const { isOnline } = useConfigCheck()
    const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
    return (
        !isLoading &&
        <TicketHeaderBox>
            <BreadCrumbs items={breadCrumbItems} />
            {
                isLargerThanMobile &&
                <ButtonGroup>
                    {ticketStatus === "approved" && !isDigitalSigned && (
                        <CustomButton title="Stamp" isDisabled={isOnline ? false : true} onClick={() => onOpen()} />
                    )}
                    <CustomButton title="Print" href={isOnline ? `/print/tickets/${id}?withCharge=${true}` : undefined} isDisabled={isOnline ? false : true} />
                </ButtonGroup>
            }
        </TicketHeaderBox >
    )
}




