import { Form } from "./Form";
import { CustomButton } from "./CustomButton";
import { useConfigCheck } from "../../app/hooks";
import { ButtonGroup, Modal, ModalProps, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack, Divider, Box } from "@chakra-ui/react";

interface ModalFormProps extends ModalProps {
  title: string
  defaultValues?: any
  onCancel(): void
  onSave?(data: any): void
  isdraft?: boolean
}

export function ModalForm({
  title,
  onSave,
  onClose,
  onCancel,
  children,
  defaultValues,
  isdraft = false,
  ...modalProps
}: ModalFormProps) {
  const { isOnline } = useConfigCheck()

  //modal close handler
  const handleCancel = () => {
    console.log("Cancelled modal")
    onClose()
    onCancel()
  }

  //modal save handler
  const handleSave = (data: any) => {
    console.log("Saved modal", data)
    onSave && onSave(data)
    onClose()
  }

  return (
    <Modal blockScrollOnMount={true} closeOnOverlayClick={false} onClose={handleCancel} {...modalProps}>
      <ModalOverlay />
      <ModalContent >
        <Form defaultValues={defaultValues} onSave={handleSave}>
          <ModalHeader py={4} fontSize={"20px"} color={"#05004E"}>{title}</ModalHeader>
          <Box px={6} mb={2}>
            <Divider />
          </Box>
          <ModalCloseButton />
          <ModalBody>
            <VStack w="full" gap={4}>
              {children}
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent={"center"} mb={2}>
            <ButtonGroup gap={"20px"}>
              <CustomButton w={{ base: '120px', md: '160px' }} title={'Save'} type="submit" isDisabled={(isOnline || isdraft) ? false : true} />
              <CustomButton w={{ base: '120px', md: '160px' }} title="Cancel" colorScheme="grey" onClick={handleCancel} />
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  )
}
