import { ProductAutocomplete } from "../products/ProductAutocomplete";
import { InputField } from "../../components/forms/fields/InputField";
import { NumberField } from "../../components/forms/fields/NumberField";
import { BooleanField } from "../../components/forms/fields/BooleanField";

interface ProductsFormProps {
}

export function ProductsForm({ }: ProductsFormProps) {
  return (
    <>
      <ProductAutocomplete
        label="Shipping Name"
        name="product"
        registerOptions={{ required: "Plese Enter Product Name" }}
        placeholder="Select the name of the product being shipped"
      />
      <InputField label="Subsidiary Class" name="subsidiary_class" placeholder="Enter subsidiary class" />
      <BooleanField label="Toxic by Inhalation" name="toxic_by_inhalation" />
      <NumberField
        label="Quantity"
        name="quantity"
        placeholder="The quantity"
        registerOptions={{ required: "Plese Enter Quantity" }}
      />
      <NumberField
        label="Packages"
        name="packages"
        placeholder="Number of packages"
      />
    </>
  )
}
