// import moment from "moment"
import DatePicker from 'react-datepicker';
import { useLocation } from "react-router-dom";
import { DetailHeading } from "../../components/DetailHeading";
import { FilterValues } from "../../features/tickets/tickets";
import { CustomButton } from "../../components/forms/CustomButton";
import { useAppSelector, useConfigCheck } from "../../app/hooks";
import { KnownIcon } from "../../components/widgets/KnownIcon";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import CustomSelect from "../../components/forms/fields/CustomeSelectField";
import { selectAllCustomers } from "../../features/customers/customersSlice";
import { TICKET_STATUS_OPTIONS } from "../../features/tickets/ticket_choices";
import { selectAllActivities } from "../../features/activities/activitySlice";
import { Box, InputGroup, InputLeftAddon, HStack, Card, CardHeader, Divider, CardBody, Text, Stack, StackDivider, useMediaQuery, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter, ButtonGroup, VStack, Switch, Flex, Button, Input } from "@chakra-ui/react";


interface ListViewProps {
    filters: FilterValues
    filterApplied: boolean
    setFilters?: Dispatch<SetStateAction<FilterValues>>;
    handleApplyFilter?(): void
    handleResetFilter?(): void
    isOpen: boolean;
    onClose: () => void;
}
type OptionType = { value: string | number; label: string; };
type EntryType = "ticketTime" | "nonTicketTime" | "all";

const ticketEntryTypeOption: { label: string; value: EntryType }[] = [
    { label: "All", value: "all" },
    { label: "Ticket ", value: "ticketTime" },
    { label: "Non-Ticket", value: "nonTicketTime" }
]

export function ListView({
    filters,
    setFilters = () => { }, // default value
    handleApplyFilter,
    handleResetFilter,
    filterApplied,
    isOpen,
    onClose
}: ListViewProps) {
    const { isOnline } = useConfigCheck()
    const { userProfile: { role } = {} } = useConfigCheck() //get role of current user
    const pathName = useLocation().pathname
    const customers = useAppSelector(selectAllCustomers) // get all customers
    const activities = useAppSelector(selectAllActivities) // get all activities
    const { activity, dateRange, is_approved, customer, status, entryType } = filters
    const [startDate, endDate] = dateRange;
    const [isLargerThanMobile] = useMediaQuery('(min-width: 768px)')
    // Memoize customer options
    const customerSelectOptions: OptionType[] = useMemo(() =>
        customers.filter(customer => customer?.profile)
            .map(customer => ({ value: customer.id, label: customer.name })), [customers]);

    const customerRoleSelectOptions = useMemo(() => {
        return TICKET_STATUS_OPTIONS.filter(option =>
            option.value !== "submitted" && option.value !== "unapproved"
        )
    }, [])

    const getActivityOptions = useCallback(() => {
        return activities
            .filter((item) => entryType === "all" || item.description_field === (entryType === "nonTicketTime"))
            .map((item) => ({ value: item.name, label: item.name }));
    }, [activities, entryType]);

    const isDriverLeadWithApproveTime = role === "driver-lead" && pathName.includes("/user/approve-time-entries");
    const isSubmittedTimePage = pathName.includes("user/tracking")
    const selectValue = isDriverLeadWithApproveTime ? activity : status;
    const selectPlaceholder = isDriverLeadWithApproveTime ? "Select Activity" : "Select Ticket Status";
    const selectOptions = isDriverLeadWithApproveTime ? getActivityOptions() : TICKET_STATUS_OPTIONS;

    const handleSelectChange = (value: OptionType) =>
        setFilters(prev => ({ ...prev, [isDriverLeadWithApproveTime ? 'activity' : 'status']: value || prev[isDriverLeadWithApproveTime ? 'activity' : 'status'] }));

    const handleCustomerChange = (value: OptionType | null) =>
        setFilters(prev => ({ ...prev, customer: value }));

    const handleTicketType = (value: "ticketTime" | "nonTicketTime" | "all") =>
        setFilters((prev) => { return { ...prev, entryType: value, activity: [], customer: null } })


    const handleReset = () => {
        handleResetFilter?.()
    };
    const handleFilter = () => {
        handleApplyFilter?.() && onClose()

    };

    const filterContent = (
        <VStack alignItems={'stretch'} gap={5}>
            {
                isDriverLeadWithApproveTime &&
                <Box w="full"  >
                    <ButtonGroup isAttached w="full" >
                        {ticketEntryTypeOption.map((item, index) => (
                            <CustomButton key={index} fontSize={"12px"} variant={entryType === item.value ? "solid" : "outline"} w={"full"} title={item.label} onClick={(e) => handleTicketType(item.value)} />
                        ))}
                    </ButtonGroup>
                </Box>
            }
            <Stack color="#0E1628" divider={<StackDivider />} spacing='4'>

                {
                    isDriverLeadWithApproveTime && (entryType === "ticketTime" || entryType === "all") &&
                    <VStack w="full" alignItems={'stretch'}>
                        <Text fontWeight={500} mb={1}>
                            Select Customer
                        </Text>
                        <CustomSelect
                            value={customer ?? null}
                            placeholder="Select Customer"
                            options={customerSelectOptions}
                            onChange={handleCustomerChange}
                            isSearchable={true}
                            isMulti={false}
                        />
                    </VStack>
                }
                {
                    !isSubmittedTimePage &&
                    <VStack w="full" alignItems={'stretch'}>
                        <Text fontWeight={500} mb={1}>
                            {isDriverLeadWithApproveTime ? "Select Activity" : "Ticket Status"}
                        </Text>
                        <CustomSelect
                            value={selectValue ?? null}
                            placeholder={selectPlaceholder}
                            options={role !== 'customer' ? selectOptions : customerRoleSelectOptions}
                            onChange={handleSelectChange}
                            isSearchable={false}
                            isMulti
                        />
                    </VStack>
                }
                <VStack w="full" alignItems={'stretch'}>
                    <Text fontWeight={500} mb={1}>
                        Date Range
                    </Text>
                    <Box id="root-portal">
                        <InputGroup >
                            <InputLeftAddon p={2} bgColor="#fff"   >
                                <KnownIcon name="calendar" boxSize={"24px"} />
                            </InputLeftAddon>
                            <DatePicker
                                placeholderText="Select Date Range"
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    setFilters(prev => ({ ...prev, dateRange: update }))
                                }}
                                isClearable={true}
                                maxDate={new Date()}
                                portalId="root-portal"
                                customInput={
                                    <Input
                                        borderTopLeftRadius={0}
                                        borderBottomLeftRadius={0}
                                        _focus={{ borderColor: "#3182ce", boxShadow: "0 0 0 1px #3182ce", outline: "none" }}
                                        _hover={{ borderColor: 'hsl(0, 0%, 70%);' }}
                                        backgroundColor={"#fff"}
                                        inputMode='none'
                                    />
                                }
                            />
                        </InputGroup>
                    </Box>
                </VStack>
            </Stack>
            {
                isDriverLeadWithApproveTime && (
                    <Flex gap={4} alignItems="center">
                        <Box>Approved Time:</Box>
                        <Switch
                            sx={{
                                '.chakra-switch__track': {
                                    bg: 'gray.300', // background when off
                                },
                                '.chakra-switch__track[data-checked]': {
                                    bg: '#3455FF', // custom background color when on
                                },
                            }}
                            isChecked={is_approved} size="md"
                            onChange={(e) => {
                                const { checked } = e.target
                                setFilters((prev) => { return { ...prev, is_approved: checked } })
                                if (checked) setFilters(prev => { return { ...prev, ticket: null } })
                            }} />
                    </Flex>)
            }
        </VStack>
    )

    return (
        <>
            {
                isLargerThanMobile ?
                    (
                        <Card w={{ base: "min-content", lg: "full" }} minW={"270px"} maxW={"320px"} position={"sticky"} top={"40px"}>
                            <CardHeader p={{ base: 3, lg: 5 }}>
                                <HStack justifyContent={"space-between"}>
                                    <DetailHeading title="Filter" />
                                    {filterApplied && <Text color="#E02020" fontSize="12px" cursor="pointer" onClick={handleReset}> Clear all </Text>}
                                </HStack>
                            </CardHeader>
                            <Divider color="#F0F0F0" />
                            <CardBody p={{ base: 3, lg: 5 }}>
                                {filterContent}
                                <CustomButton
                                    mt={4}
                                    w={"80px"}
                                    h={"36px"}
                                    title="Apply"
                                    variant="outline"
                                    onClick={handleFilter}
                                    isDisabled={!isOnline}
                                />
                            </CardBody>
                        </Card>)
                    : (
                        <Drawer
                            isOpen={isOpen}
                            placement='right'
                            onClose={onClose}
                            size="xs"
                            allowPinchZoom={true}
                            closeOnOverlayClick={false}
                            closeOnEsc={false}
                        >
                            <DrawerOverlay />
                            <DrawerContent bgColor={"#F8F8FB"}>
                                <DrawerCloseButton />
                                <DrawerHeader bgColor={"#EBEEFF"}>
                                    <DetailHeading title='Filter' />
                                </DrawerHeader>
                                <DrawerBody>
                                    {filterContent}
                                </DrawerBody>
                                <DrawerFooter bgColor={"#fff"}>
                                    <ButtonGroup gap={["20px", "25px"]}>
                                        {
                                            filterApplied &&
                                            <CustomButton
                                                title="Clear all"
                                                variant="outline"
                                                colorScheme="red"
                                                onClick={handleReset}
                                            />
                                        }
                                        <CustomButton title="Apply" onClick={handleFilter} isDisabled={!isOnline} />
                                    </ButtonGroup>
                                </DrawerFooter>
                            </DrawerContent>
                        </Drawer>
                    )

            }
        </>
    )
}
