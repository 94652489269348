import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Input,
  useMediaQuery,
} from "@chakra-ui/react"
import { useFormContext, RegisterOptions } from "react-hook-form"

interface LsdPrefixProps {
  label?: string
  name: string
  registerOptions?: RegisterOptions
  helper?: string
}

export function LsdPrefixField({
  label,
  name,
  registerOptions,
  helper,
}: LsdPrefixProps) {
  const { register, formState } = useFormContext()
  const [isSmallScreen] = useMediaQuery("(max-width: 570px)")

  const validateLsdPrefix = (value: string | undefined) => {
    if (value) {
      const isValiLsdPrefix = value && /^\d{3}$/.test(value)
      if (isValiLsdPrefix) {
        return undefined // Valid 3-digit value
      } else {
        return "Invalid format. Please enter 3 digits"
      }
    }

  }

  // Default registration options
  const defaultRegisterOptions = {
    // required: true,
    validate: {
      validLsdPrefix: validateLsdPrefix,
    },
  }

  // Merge provided registerOptions with default options
  const mergedRegisterOptions = {
    ...defaultRegisterOptions,
    ...registerOptions,
  }

  return (
    <FormControl isInvalid={!!formState.errors?.[name]} w={isSmallScreen ? "full" : "35%"}>
      <FormLabel htmlFor={name} color="#455360">{label}</FormLabel>
      <Input {...register(name, mergedRegisterOptions)} id={name} h="48px" borderRadius="4px" variant="filled" inputMode="numeric" placeholder="###" maxLength={3} color={"#0E1628"} />
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>
        {formState.errors?.[name]?.message?.toString()}
      </FormErrorMessage>
    </FormControl>
  )
}
