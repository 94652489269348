import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CardView } from "../../components/CardView";
import { DraftCard } from "../../components/DraftCard";
import { Container } from "../../components/Container";
import { Draft } from "../../features/draft/draftSlice";
import { PageBanner } from "../../components/PageBanner";
import { Box, HStack, SimpleGrid } from "@chakra-ui/react";
import { DetailHeading } from "../../components/DetailHeading";
import { useConfigCheck, useAppSelector } from "../../app/hooks";
import { LatestTickets } from "../../features/tickets/LatestTickets";
import { useListProductsQuery } from "../../features/products/productsApi";
import { useListServicesQuery } from "../../features/services/servicesApi";
import { selectCustomerById } from "../../features/customers/customersSlice";
import { selectTerminalById } from "../../features/terminals/terminalsSlice";
import { useListCustomersQuery } from "../../features/customers/customersApi";
import { useListLocationsQuery } from "../../features/terminals/locationsApi";
import { useListTerminalsQuery } from "../../features/terminals/terminalsApi";
import { useListEquipmentsQuery } from "../../features/equipments/equipmentsApi";
import { useListActivitiesQuery } from "../../features/activities/activitiesApi";

interface type {
    data: Draft
    index: number
}
function CurrentDraftTapTile({ data, index }: type) {
    const { hasContent, basic, locations } = data
    const fallbackCustomerName = useAppSelector(
        (state) => selectCustomerById(state, basic?.customer || 0)?.name,
    )
    const customerName = basic?.customer
        ? typeof basic?.customer == "number"
            ? `Customer "${fallbackCustomerName}"`
            : `New Customer "${basic?.customer}"`
        : "No Customer Selected"

    const fallbackTerminalName = useAppSelector(
        (state) => selectTerminalById(state, locations?.consignee?.terminal || 0)?.name,
    )
    const terminalName = locations?.consignee?.terminal
        ? typeof locations?.consignee?.terminal == "number"
            ? `Terminal "${fallbackTerminalName}"`
            : `New Terminal "${locations?.consignee?.terminal}"`
        : "No Terminal Selected"

    return (
        hasContent && (
            <DraftCard
                title={customerName}
                subtitle={terminalName}
                draftNumber={data?.number ?? null}
                href={`/user/draft/${++index}`}
            />
        )
    )
}

export function DriverHomePage() {
    const navigate = useNavigate();
    useListProductsQuery();
    useListServicesQuery();
    useListCustomersQuery();
    useListTerminalsQuery();
    useListLocationsQuery();
    useListActivitiesQuery();
    useListEquipmentsQuery();
    const draftsArray = useAppSelector((state) => state.drafts.drafts);
    const { userProfile: { role } = {} } = useConfigCheck(); //get role of current user

    useEffect(() => {
        if (role && role === "customer") {
            navigate("/user/tickets");
        }
    }, [role, navigate]);
    return (
        <>
            <PageBanner title={'Home'} helper="Summary" />
            <Container direction="column">
                <SimpleGrid
                    w="full"
                    gap={{ base: "1rem", md: "2.5rem" }}
                    columns={{ base: 1, md: 2 }}
                >
                    <CardView
                        title="Time Tracking"
                        helper="Add and view your hours here"
                        onClick={() => navigate("/user/tracking")}
                    />
                    <CardView
                        title="Create New ticket"
                        helper="Create your ticket"
                        onClick={() =>
                            draftsArray.length !== 0
                                ? navigate(`/user/draft/${draftsArray.length + 1}`)
                                : navigate(`/user/draft`)
                        }
                    />
                </SimpleGrid>
                {
                    draftsArray.length > 0 &&
                    < Box w="full" gap={5}>
                        <DetailHeading title="Drafts" />
                        <Box maxW={"90vw"} overflowX={"auto"} className="custom-scrollbar">
                            <HStack my={4} gap={4} >
                                {draftsArray &&
                                    draftsArray.map((draft, index) => {
                                        return (
                                            <CurrentDraftTapTile data={draft} index={index} key={index} />
                                        )
                                    })}
                            </HStack>
                        </Box>
                    </Box>
                }
                <LatestTickets limit={5} />
            </Container >
        </>
    )
}
