import { components } from "../../app/api/schema";
import { DetailTile } from "../../components/DetailTile";
import { PanelWrapper } from "../../components/PanelWrapper";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { Box, Stack, StackDivider, useMediaQuery, VStack } from "@chakra-ui/react";

type ChargeDetailsResource = components["schemas"]["Charge"];

interface ChargeDetailPanelProps extends WrapperProps {
    dataresource?: TicketResource
}

export function ChargeDetailPanel({
    dataresource: ticket
}: ChargeDetailPanelProps) {
    const [isBreakPoint] = useMediaQuery("(max-width: 700px)")
    return (
        <>
            <PanelWrapper title="Charges">
                <VStack align="stretch" gap={{ base: 0, lg: 2 }} p={2} divider={<StackDivider />}>
                    {ticket?.chargedetails?.length !== 0 ?
                        ticket?.chargedetails.map((charge: ChargeDetailsResource, index: number) => (
                            <VStack key={index} w="full" p={2} align="stretch" gap={{ base: 0, lg: 5 }} divider={<StackDivider />}>
                                <DetailTile flexBasis="full" title="Service Name" detail={charge?.description} />
                                <Stack direction={isBreakPoint ? "column" : "row"} w={"full"} alignItems="flex-start" gap={{ base: 3, lg: 5 }} divider={<StackDivider />}>
                                    <Stack direction="row" w="full" gap={5} divider={<StackDivider />}>
                                        <DetailTile flexBasis={'50%'} title="Qty" detail={charge?.quantity} />
                                        <DetailTile flexBasis={'50%'} title="Unit" detail={charge?.unit} />
                                    </Stack>
                                    <Stack direction="row" w="full" gap={5} divider={<StackDivider />}>
                                        <DetailTile flexBasis={'50%'} title="Rates" detail={charge?.rate} />
                                        <DetailTile flexBasis={'50%'} title="Amount" detail={charge?.amount} />
                                    </Stack>
                                </Stack>
                            </VStack>
                        )
                        ) :
                        <Box w="full" fontSize="14px" color="#455360" bgColor={"#fff"} borderRadius={"8px"} >
                            No additional products have been added
                        </Box>
                    }
                </VStack>
            </PanelWrapper>
        </>
    )
}

