import { FieldInfoProps } from "../../forms";
import { Stack, Text } from "@chakra-ui/react";

interface InputStreakProps extends FieldInfoProps {
  children: React.ReactElement[] | React.ReactElement
}

export function InputStreak({ label, children }: InputStreakProps) {

  return (
    <Stack w="full" gap={"10px"}  >
      <Text fontWeight={500} fontSize={"18px"} color={"#05004E"}>
        {label}
      </Text>
      <Stack align="left" gap={{ base: 4, md: 8 }} w="full" direction={{ base: "column", md: "row" }}>
        {children}
      </Stack>
    </Stack>
  )
}
