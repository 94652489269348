import { Navigate } from "react-router-dom";
import { useConfigCheck } from "./app/hooks";

interface ProtectedProps {
    Component: React.FC;
    roles?: string[];
}

export const ProtectedRoute = ({ Component, roles = [] }: ProtectedProps) => {

    const { userProfile: { role } = {} } = useConfigCheck()
    // Check if the user's role is in the list of authorized roles
    const isAuthorized = roles.length === 0 || (role && roles.includes(role));
    // Check if the user role is authorized to access the ChargesPage
    if (!isAuthorized) {
        if (role === "customer") return <Navigate to="/user/tickets" />
        return <Navigate to="/user/home" />; // Redirect to home if not authorized
    }
    // Render the component if authorized
    return <Component />;
};