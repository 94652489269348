import { Box } from "@chakra-ui/react";
import { DraftExtraValues } from "./tickets";
import { PickUpPanelForm } from "./PickUpPanelForm";
import { forwardRef, useEffect, useRef } from "react";
import { Container } from "../../components/Container";
import { PanelWrapper } from "../../components/PanelWrapper";
import { EditForm } from "../../components/forms/EditForm";
import { OverlayLoader } from "../../components/OverlayLoader";
import { useAppDispatch, useAppSelector, } from "../../app/hooks";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { CustomButton } from "../../components/forms/CustomButton";
import { saveDraftPickUp, selectPickUp } from "../draft/draftSlice";
import { WrapperProps } from "../../components/TicketDetailWrapper";
import { removeTicketTankGaugeRecord, selectTicketPickUpDetail, updateTicketPickUpDetail } from "./ticketsSlice";

interface ProductPanelProps extends WrapperProps {
    dataresource?: TicketResource,
    scrollTo?(data: string): void
}

export const PickUpPanel = forwardRef<HTMLDivElement, ProductPanelProps>(
    function PickUpPanel_NEW({ dataresource, isdraft, resourceid, scrollTo }: ProductPanelProps, ref) {
        const dispatch = useAppDispatch()
        const isManualReset = useRef(false);

        //Selector based on draft or ticket
        const selectResource = isdraft ?
            useAppSelector(selectPickUp(Number(resourceid)))
            : useAppSelector(selectTicketPickUpDetail(Number(resourceid))) || {}

        const methods = useForm({ defaultValues: selectResource })
        const { control, handleSubmit, formState: { isSubmitting }, reset } = methods
        // Watch form values to detect changes
        const watchedValues = useWatch({
            control,
            defaultValue: selectResource,
        })
        const hasChanged = JSON.stringify(watchedValues) !== JSON.stringify(selectResource)

        /* Save handler based on draft or ticket
         * Save to local storage whenever watched values change for draft (AutoSave)
         */
        const handleSaveResource = async (data: any) => {
            if (!isdraft) await dispatch(updateTicketPickUpDetail({ id: resourceid, ...data }))
            isManualReset.current = true
            scrollTo?.("dropOffForm")
        }
        useEffect(() => {
            if (isdraft) dispatch(saveDraftPickUp({ id: Number(resourceid), ...watchedValues } as DraftExtraValues));
        }, [watchedValues, resourceid, dispatch, isdraft]);

        // Tank gauge remove handler
        const handleRemoveTankGaugeRecord = async (index: number) => {
            const tank_gauge_id = dataresource?.tank_gauge_details && dataresource?.tank_gauge_details[index]?.id
            await dispatch(removeTicketTankGaugeRecord({ id: Number(resourceid), tank_gauge_id: Number(tank_gauge_id) }))
        }

        // reset form after save event to track any new change in form
        useEffect(() => {
            if (isManualReset.current) {
                reset(selectResource);
            }
            isManualReset.current = false;
        }, [selectResource, reset, methods]);

        return (
            <Box w="full" ref={ref}>
                {isSubmitting && <OverlayLoader />}
                <Container mb={0}>
                    <FormProvider {...methods}>
                        <EditForm onSave={handleSubmit(handleSaveResource)}>
                            <PanelWrapper title="Load">
                                {hasChanged && <CustomButton title="Save" type="submit" variant="outline" w="80px" isLoading={isSubmitting} />}
                                <PickUpPanelForm onRemove={handleRemoveTankGaugeRecord} />
                            </PanelWrapper>
                        </EditForm>
                    </FormProvider>
                </Container >
            </Box>
        )
    })
