import { FieldProps } from "../../forms";
import { useAppSelector } from "../../app/hooks";
import { selectAllProducts, selectProductById } from "./productsSlice";
import { AutocompleteField } from "../../components/forms/fields/AutocompleteField";

interface ProductAutocompleteProps extends FieldProps { }

export function ProductAutocomplete({
  label,
  name,
  registerOptions,
  helper,
  placeholder
}: ProductAutocompleteProps) {
  const products = useAppSelector(selectAllProducts)
  const suggestions = products.map((product) => ({
    value: product.id,
    text: product.name,
  }))

  return (
    <AutocompleteField
      label={label}
      name={name}
      traitGetter={selectProductById}  //add this to get trait behaviour
      suggestions={suggestions}
      registerOptions={registerOptions}
      helper={helper}
      placeholder={placeholder}
    />
  )
}
