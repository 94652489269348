import { useState } from "react";
import { useAppSelector, useConfigCheck } from "../../app/hooks";
import { ChargesForm } from "../charges/ChargesForm";
import { selectTicketServices } from "./ticketsSlice";
import { PanelWrapper } from "../../components/PanelWrapper";
import { ModalForm } from "../../components/forms/ModalForm";
import { selectAllServices } from "../services/servicesSlice";
import { useListServicesQuery } from "../services/servicesApi";
import { Draft, selectDraftServices } from "../draft/draftSlice";
import { TicketResource } from "../../pages/user/TicketDetailPage";
import { CustomButton } from "../../components/forms/CustomButton";
import { ChargeWrapperProps } from "../../components/ChargeDetailWrapper";
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useMediaQuery } from "@chakra-ui/react";
export interface TicketServicesOverviewProps extends ChargeWrapperProps {
  dataresource?: TicketResource | Draft | null
}

export function TicketServicesOverview({
  isdraft,
  resourceId,
  dataresource,
  handlesavecharge
}: TicketServicesOverviewProps) {
  useListServicesQuery()
  const { isOnline } = useConfigCheck()
  const services = useAppSelector(selectAllServices)
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)")
  const modalSize = isLargerThanMobile ? "lg" : "full"
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure()

  const [defaultValues, setDefaultValues] = useState(null) //set default values for modal

  //get service label
  const getService = (id: number) =>
    services.find((service: { id: number }) => service.id === id)

  //check whether entry is in charge already or not
  const isInCharges = (id: number) => {
    if (isdraft && dataresource && 'charges' in dataresource) {
      const name = getService(id)?.name
      return dataresource.charges?.some((item) => item.description === name)
    } else if (dataresource && 'chargedetails' in dataresource) {
      return dataresource.chargedetails.some((item) => item.record_id === id);
    }
    return false;
  };

  //select resource based on draft or ticket
  const selectResource = isdraft ? useAppSelector(selectDraftServices(Number(resourceId))) :
    useAppSelector(selectTicketServices(Number(resourceId)))

  return (
    <PanelWrapper title="Additional Billable Items">
      {
        selectResource && selectResource?.length > 0 ?
          <Box
            className="custom-scrollbar"
            maxWidth="calc(100vw - 90px)"
            width="100%"
            overflowX="auto"
          >
            <Table>
              <Thead color={"#455360"} >
                <Tr sx={{ th: { fontSize: "1rem", fontWeight: 600, borderBottom: "none" } }}>
                  <Th>Name</Th>
                  <Th>Amount</Th>
                  <Th>Unit</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody color={"#7B8389"}>
                {selectResource?.map((item, index) => (
                  <Tr key={index} h="72px" _last={{ td: { borderBottom: "none" } }} >
                    <Td>{getService(item.service)?.name}</Td>
                    <Td>{item.quantity}</Td>
                    <Td>{getService(item.service)?.unit}</Td>
                    <Td textAlign="right">
                      {
                        !isInCharges(isdraft ? Number(item?.service) : Number(item?.record_id)) &&
                        <CustomButton title="$" w={"40px"}
                          isDisabled={(isOnline || isdraft) ? false : true}
                          onClick={() => {
                            openModal()
                            const { service, description, record_id, ...rest } = item
                            isInCharges(service)
                            const data = {
                              description: getService(item.service)?.name,
                              unit: getService(item.service)?.unit,
                              allow_surcharge: true,
                              section: "items",
                              record_id: item.record_id,
                              ...rest,
                            }
                            setDefaultValues(data as any)
                          }}
                        />
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          :
          <Text>{"There are no additional items"}</Text>
      }
      {/* Dialog for adding new charge entry */}
      <ModalForm
        isdraft={isdraft}
        title="Add New Entry"
        isOpen={isModalOpen}
        size={modalSize}
        onClose={closeModal}
        onCancel={closeModal}
        onSave={handlesavecharge}
        defaultValues={defaultValues}
      >
        <ChargesForm />
      </ModalForm>
    </PanelWrapper>
  )
}
