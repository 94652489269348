import { FieldProps } from "../../../forms";
import { useFormContext } from "react-hook-form";
import { FormControl, FormLabel, FormHelperText, FormErrorMessage, InputGroup, Input, VStack } from "@chakra-ui/react";

interface NumberFieldProps extends FieldProps {
}

export function NumberField({
  label,
  name,
  registerOptions,
  helper,
  readOnly,
  placeholder
}: NumberFieldProps) {
  const { register, formState: { errors } } = useFormContext()

  const updatedRegisterOptions = {
    ...registerOptions,
    setValueAs: (value: string | number | undefined) => {
      if (value === "" || value == null) {
        return null;
      }
      return Number(value);
    }, // Convert empty string to null and others to numbers
  };
  return (
    <FormControl isInvalid={errors[name] !== undefined} >
      <VStack w="full" gap="10px" alignItems="flex-start">
        <FormLabel htmlFor={name} fontWeight={500} color={"#455360"}>{label} {registerOptions?.required && <span style={{ color: 'red' }}>*</span>}</FormLabel>
        <InputGroup>
          <Input {...register(name, updatedRegisterOptions)} id={name} h={"48px"} borderRadius={"4px"} color={"#0E1628"} backgroundColor={"#ECF2F7"} type="number" inputMode="decimal" placeholder={placeholder} readOnly={readOnly} step="any" />
        </InputGroup>
        {helper && <FormHelperText>{helper}</FormHelperText>}
        <FormErrorMessage>{errors[name]?.message?.toString()}</FormErrorMessage>
      </VStack>
    </FormControl>
  )
}
