import { Box, keyframes } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

interface PlaceholderProps {
    placeholders: string[]
}

function AnimatedPlaceholders({ placeholders = [] }: PlaceholderProps) {
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % placeholders?.length);
        }, 2000); // Change every 2 seconds (quick transition)

        return () => clearInterval(interval); // Clean up on unmount
    }, [placeholders?.length]);


    // Keyframe animation for vertical sliding of the placeholders
    const slideFromBottom = keyframes`
            0% {
              transform: translateY(100%); /* Start below the container */
            }
            25% {
              transform: translateY(0); /* Slide up to its position */
            }
            100% {
              transform: translateY(-100%); /* Slide out above the container */
            }
        `;


    return (
        <Box
            position="absolute"
            left="12px" // Adjust for alignment with input padding
            top="50%"
            transform="translateY(-50%)"
            pointerEvents="none"
            color="gray.500"
            whiteSpace="nowrap"
            overflow="hidden"
            fontSize="1rem"
            lineHeight="1.5"
            height="1.5em"
            zIndex="2" // Ensure it overlays above the input field
        >
            {/* Loop through placeholders and display the current one */}
            {placeholders.map((placeholder: string, index: number) => (
                <Box
                    key={index}
                    display={index === currentIndex ? "block" : "none"} // Only show the current placeholder
                    animation={placeholders.length > 1 ? `${slideFromBottom} 2s ease-in-out` : "none"} // Only animate if more than one placeholder
                >
                    {placeholder}
                </Box>
            ))}
        </Box>
    )
}

export default AnimatedPlaceholders
