import { ServicesForm } from './ServicesForm';
import { DraftServicesValues } from './draftSlice';
import { ModalForm } from '../../components/forms/ModalForm';
import { selectServiceById } from '../services/servicesSlice';
import { KnownIcon } from '../../components/widgets/KnownIcon';
import { InputStreak } from '../../components/forms/InputStreak';
import { CustomButton } from '../../components/forms/CustomButton';
import { ReadonlyField } from '../../components/forms/fields/ReadonlyField';
import { Box, IconButton, StackDivider, useDisclosure, VStack } from '@chakra-ui/react';
import { ReadonlyTextareaField } from '../../components/forms/fields/ReadonlyTextareaField';


interface AdditionalItemsForm {
    services: DraftServicesValues[]
    onRemove(index: number): void
    onSave(data: any): void
}

function AdditionalItemsForm({
    services,
    onRemove,
    onSave
}: AdditionalItemsForm) {
    const { isOpen, onOpen, onClose, } = useDisclosure()

    return (
        <>
            <VStack w="full" alignItems="flex-start" gap={4} p={[0, 2, 4]} divider={<StackDivider />}>
                {
                    services?.length > 0 ?
                        (services?.map((entry: DraftServicesValues, index: number) => (
                            <VStack w="full" alignItems="flex-start" key={index} >
                                <InputStreak>
                                    <ReadonlyField label="Name" traitId={entry.service} traitGetter={selectServiceById} />
                                    <ReadonlyField label="Quantity" value={entry?.quantity || '--'} />
                                </InputStreak>
                                <InputStreak>
                                    <ReadonlyTextareaField label="Description" value={entry?.description || '--'} />
                                </InputStreak >
                                <Box w="full" textAlign="right">
                                    <IconButton
                                        aria-label="Remove"
                                        icon={<KnownIcon name="delete" boxSize="24px" cursor="pointer" />}
                                        onClick={() => onRemove(index)}
                                        backgroundColor="transparent"
                                        _hover={{ backgroundColor: "transparent" }}
                                    />
                                </Box>
                            </VStack>)

                        )) : (
                            <Box w="full" fontSize="14px" color="#455360" p={3}>
                                Add Additional Services
                            </Box>
                        )
                }
                <CustomButton title="Add New Entry" variant='outline' onClick={onOpen} />
            </VStack>
            <ModalForm
                title={`Add New Entry `}
                isOpen={isOpen}
                size={["full", "lg"]}
                onClose={onClose}
                onCancel={onClose}
                onSave={onSave}
            >
                <ServicesForm />
            </ModalForm>
        </>
    )
}

export default AdditionalItemsForm
